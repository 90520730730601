import { gql } from '@apollo/client/core';

export const editPlcAlarmCode = gql`
  mutation editPlcAlarmCode($id: String, $input: CreateOrEditPlcAlarmCode) {
    editPlcAlarmCode(id: $id, input: $input) {
      id
      alarmCode
      alarmComment
      alarmExplanation
      alarmFix
      createdBy
      createdAt
      updater
      updatedAt
      deletedAt
    }
  }
`;

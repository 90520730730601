<p-dialog [modal]="true" [(visible)]="isVisible" (onHide)="onDialogHide()" [dismissableMask]="true"
  header="View Audit Info">
  <div class="container">
    @if (!isLoading) {
      <ul>
        @for (history of histories; track history; let i = $index) {
          <li>
            User <strong>{{ history.updater }}</strong> changed Value to <strong>{{history.value}}</strong>,
            Effective at <strong>{{ history.effectiveDateTime | date: "MM/dd/yyyy HH:mm:ss" }}</strong>,
            Reason for Update: <strong>{{ history.updateReason }}</strong>.
          </li>
        }
      </ul>
    }
    @if (isLoading) {
      <p-progressSpinner></p-progressSpinner>
    }
  </div>
</p-dialog>
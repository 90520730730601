import { Injectable, inject } from '@angular/core';
import _ from 'lodash';
import { v4 as uuid } from 'uuid';
import { AuthService } from './auth.service';
import { getCorrectedDate } from '../utils/utils';
import { OpAlertLogSearchResult } from '../model/opAlertLogs/op-alert-log-search-result';
import { OpAlertLog } from '../model/opAlertLogs/op-alert-log';
import { OpAlertLogNotification } from '../model/opAlertLogs/op-alert-log-notification';
import { dynamicSort } from '../core/functions/common-functions';
import { OpAlertLogAlarm } from '../model/opAlertLogs/op-alert-log-alarm';
import { OpAlertLogEntry } from '../model/opAlertLogs/op-alert-log-entry';
import { DirtyStatus } from '../model/common/dirty-status';
import { isNullOrUndefined } from 'util';
import { PIValue } from '../model/operational-monitoring/pi-value';

@Injectable({
  providedIn: 'root',
})
export class OpAlertLogsBuilderService {
  private _auth = inject(AuthService);


  buildOpLogQuery(filters) {
    const query = {
      opLogId: null,
      state: null,
      county: null,
      township: null,
      createdAt: null,
      opLogStatus: null,
      createdBy: null,
      businessUnitId: null,
      businessUnits: null,
      facilityUid: null,
      facilityName: null,
      facilityTypeName: null,
    };

    if (filters.state) {
      query.state = filters.state.value.trim();
    }
    if (filters.county) {
      query.county = filters.county.value.trim();
    }
    if (filters.township) {
      query.township = filters.township.value.trim();
    }
    if (filters.createdAt) {
      query.createdAt = filters.createdAt.value;
    }
    if (filters.createdBy) {
      query.createdBy = filters.createdBy.value.trim();
    }
    if (filters.opLogId) {
      query.opLogId = filters.opLogId.value.trim();
    }
    if (filters.opLogStatus) {
      query.opLogStatus = filters.opLogStatus.value.trim();
    }
    if (filters.businessUnitId) {
      query.businessUnitId = filters.businessUnitId.value.trim();
    }
    if (filters.facilityUid) {
      query.facilityUid = filters.facilityUid.value.trim();
    }
    if (filters.facilityName) {
      query.facilityName = filters.facilityName.value.trim();
    }
    if (filters.facilityTypeName) {
      query.facilityTypeName = filters.facilityTypeName.value.trim();
    }
    if (filters.businessUnits) {
      query.businessUnits = filters.businessUnits;
    }

    return query;
  }

  buildOpAlertLogSearch(item: any): OpAlertLogSearchResult {
    return {
      id: item.id,
      opLogId: item.opLogId,
      opLogStatus: this.getNameForStatus(item.opLogStatus),
      state: item.state,
      facilityId: item.facilityId,
      facilityName: item.facilityName,
      facilityUid: item.facilityUid,
      facilityTypeName: item.facilityTypeName,
      businessUnitId: item.businessUnitId,
      buName: item.buName,
      county: item.county,
      township: item.township,
      createdAt: getCorrectedDate(item.createdAt),
      createdBy: item.createdBy,
      updatedAt: getCorrectedDate(item.updatedAt),
      updatedBy: item.updatedBy,
      areaId: item.areaId,
      aor: item.aor,
      aorName: item.aorName,
    };
  }

  buildOpAlertLog(item: any): OpAlertLog {
    const log: OpAlertLog = {
      id: item.id,
      opLogId: item.opLogId,
      BusinessUnit: item.BusinessUnit,
      businessUnitId: item.BusinessUnit.id,
      facilityId: item.Facility.id,
      Facility: item.Facility,
      Contact: item.Contact,
      state: item.state,
      county: item.county,
      township: item.township,
      opLogStatus: item.opLogStatus,
      piPoint: item.piPoint,
      OpAlertLogAlarms: this.buildOpAlertLogAlarms(item.OpAlertLogAlarms),
      OpAlertLogEntries: this.buildOpAlertLogEntries(item.OpAlertLogEntries),
      OpAlertLogNotifications: this.buildOpAlertLogNotifications(
        item.OpAlertLogNotifications
      ),
      createdAt: getCorrectedDate(item.createdAt),
      updatedAt: getCorrectedDate(item.updatedAt),
      createdBy: item.createdBy,
    };

    return log;
  }

  buildOpAlertLogEntries(item: any[]): OpAlertLogEntry[] {
    const retVal = [];

    if (!item) {
      return retVal;
    }

    item.forEach((e) => {
      const entry: OpAlertLogEntry = {
        id: e.id,
        entry: e.entry,
        time: getCorrectedDate(e.time),
        user: e.user,
        createdBy: e.createdBy,
        createdAt: e.createdAt,
        updatedAt: e.updatedAt,
        deletedAt: e.deletedAt,
      };
      retVal.push(entry);
    });

    return retVal.sort(dynamicSort('time', 1));
  }

  buildOpAlertLogAlarms(item: any[]): OpAlertLogAlarm[] {
    const retVal = [];

    if (!item) {
      return retVal;
    }

    item.forEach((e) => {
      const entry: OpAlertLogAlarm = {
        id: e.id,
        alarm: e.alarm,
        time: getCorrectedDate(e.time),
        user: e.user,
        createdBy: e.createdBy,
        createdAt: e.createdAt,
        updatedAt: e.updatedAt,
        deletedAt: e.deletedAt,
      };
      retVal.push(entry);
    });

    return retVal.sort(dynamicSort('time', 1));
  }

  buildOpAlertLogNotifications(item: any[]): OpAlertLogNotification[] {
    const retVal = [];

    if (!item) {
      return retVal;
    }

    item.forEach((e) => {
      const entry: OpAlertLogNotification = {
        id: e.id,
        opAlertLogId: e.opAlertLogId,
        note: e.note,
        time: getCorrectedDate(e.time),
        user: e.user,
      };
      retVal.push(entry);
    });

    return retVal.sort(dynamicSort('time', -1));
  }

  buildUpdateStatement(
    opAlertLog: OpAlertLog,
    changes: any,
    markAsDeleted: Boolean
  ) {
    const retVal = {
      id: opAlertLog.id,
      dirtyStatus: DirtyStatus.UPDATED,
      opLogStatus: this.getField('opLogStatus', opAlertLog, changes),
      opLogId: opAlertLog.opLogId,
      state: opAlertLog.state,
      county: opAlertLog.county,
      township: opAlertLog.township,
      updatedAt: opAlertLog.updatedAt,
      updatedBy: opAlertLog.updatedBy,
      createdAt: opAlertLog.createdAt,
      createdBy: opAlertLog.createdBy,
      markAsDeleted: markAsDeleted,
      businessUnitId: opAlertLog.BusinessUnit.id,
      facilityId: opAlertLog.Facility.id,
    };

    if (changes.OpAlertLog) {
      if (changes.OpAlertLog.OpAlertLogLocation) {
        if (
          changes.OpAlertLog.OpAlertLogLocation.county ||
          changes.OpAlertLog.OpAlertLogLocation.county === ''
        ) {
          retVal.county = changes.OpAlertLog.OpAlertLogLocation.county.county;
        }

        if (
          changes.OpAlertLog.OpAlertLogLocation.state ||
          changes.OpAlertLog.OpAlertLogLocation.state === ''
        ) {
          retVal.state = changes.OpAlertLog.OpAlertLogLocation.state;
        }

        if (
          changes.OpAlertLog.OpAlertLogLocation.township ||
          changes.OpAlertLog.OpAlertLogLocation.township === ''
        ) {
          retVal.township =
            changes.OpAlertLog.OpAlertLogLocation.township.township;
        }
      }

      if (changes.OpAlertLog.OpAlertLogFacility) {
        if (changes.OpAlertLog.OpAlertLogFacility.facilityId) {
          retVal.facilityId = changes.OpAlertLog.OpAlertLogFacility.facilityId;
        }
      }
    }

    if (changes.businessUnit) {
      retVal['businessUnit'] = changes.businessUnit.id;
    }

    if (changes.facility) {
      retVal['facility'] = changes.facility.id;
    }

    if (opAlertLog.OpAlertLogEntries.some((e) => e.dirtyStatus > 0)) {
      retVal['OpAlertLogEntry'] = opAlertLog.OpAlertLogEntries.find(
        (e) => e.dirtyStatus > 0
      );
    }

    return retVal;
  }

  buildNewOpAlertLogAlarm(raw: any): OpAlertLogAlarm {
    const retVal: OpAlertLogAlarm = {
      id: uuid(),
      alarm: raw.statusUpdate,
      dirtyStatus: DirtyStatus.NEW,
      time: new Date(),
      user: this._auth.getUserName(),
    };

    return retVal;
  }

  buildNewOpAlertLogEntry(raw: any, opLog: OpAlertLog): OpAlertLogEntry {
    const retVal: OpAlertLogEntry = {
      id: uuid(),
      opAlertLogId: opLog.id,
      dirtyStatus: DirtyStatus.NEW,
      time: raw.entryTime ? raw.entryTime : new Date(),
      createdBy: this._auth.getUserName(),
      createdAt: raw.entryTime ? raw.entryTime : new Date(),
      updatedAt: raw.entryTime ? raw.entryTime : new Date(),
      user: this._auth.getUserName(),
      markAsDeleted: false,
      entry: raw.statusUpdate,
    };

    return retVal;
  }

  getNameForStatus(status) {
    if (!status) {
      return 'N/A';
    } else if (status === 'N') {
      return 'NEW';
    } else if (status === 'A') {
      return 'ACKNOWLEDGED';
    } else {
      return status;
    }
  }

  buildOpAlertLogValidationMessages() {
    return {
      messages: {
        statusSummary: {
          required: 'COMMON.VALIDATION.REQ_FIELD.REQ_FIELD',
        },
        calloutRequired: {
          required: 'COMMON.VALIDATION.REQ_FIELD.REQ_FIELD',
        },
        typeOfCalls: {
          required: 'COMMON.VALIDATION.REQ_FIELD.REQ_FIELD',
        },
        gasReleaseType: {
          required: 'COMMON.VALIDATION.REQ_FIELD.REQ_FIELD',
        },
        commFailSeverity: {
          required: 'COMMON.VALIDATION.REQ_FIELD.REQ_FIELD',
        },
      },
      params: {
        statusSummary: {
          required: {
            field: 'Status Summary',
          },
        },
        calloutRequired: {
          required: { field: 'Callout Required' },
        },
        typeOfCalls: {
          required: { field: 'Type of Call' },
        },
        gasReleaseType: {
          required: { field: 'Gas Release Type' },
        },
        commFailSeverity: {
          required: { field: 'Comm Fail Severity' },
        },
      },
    };
  }

  getField(field: string, uiObj: any, input: any) {
    if (_.has(input, field) && input[field] !== undefined) {
      return input[field];
    } else if (_.has(uiObj, field) && uiObj[field] !== undefined) {
      return uiObj[field];
    } else {
      return null;
    }
  }

  buildPIValue(item: any): PIValue {
    return {
      timestamp: new Date(item.timestamp),
      unitsAbbreviation: item.unitsAbbreviation,
      value: parseFloat(item.value),
      good: item.good,
      questionable: item.questionable,
      substituted: item.substituted,
      annotated: item.annotated,
    };
  }
}

@if (!formWorking()) {
  <form [formGroup] = "form">
    @if (actions.length > 0) {
      <p-dropdown
        #dd
        [options]="actions"
        (onChange)="actionEvent($event, dd)"
        styleclass="action-drop-down"
        placeholder="Actions"
      ></p-dropdown>
    }

    <div class="form-item">
      <label for="alarmCode">{{ "OPMONITORING.LABEL.ALARM_CODE" | translate }}:</label>
      <input type="text" pInputText formControlName="alarmCode"/>
    </div>
    <div class="form-item">
      <label for="alarmComment">{{ "OPMONITORING.LABEL.ALARM_COMMENT" | translate }}:</label>
      <input type="text" pInputText formControlName="alarmComment" />
    </div>
    <div class="form-item">
      <label for="alarmExplanation">{{ "OPMONITORING.LABEL.ALARM_EXPLANATION" | translate }}:</label>
      <input type="text" pInputText formControlName="alarmExplanation" />
    </div>
    <div class="form-item">
      <label for="alarmFix">{{ "OPMONITORING.LABEL.ALARM_FIX" | translate }}:</label>
      <input type="text" pInputText formControlName="alarmFix" />
    </div>

    @if ((canCreate() && formType === 'Create') || (canEdit() && formType === 'Edit')) {
    <button pButton
    label="{{ 'COMMON.LABEL.BUTTONS.SAVE' | translate }}"
    icon="fa fa-fw fa-save"
    (click)="save()"
    [disabled]="!form.valid || !form.dirty"
    ></button>
    }
  </form>
}
<app-progress-spinner [enabled]="formWorking()"></app-progress-spinner>

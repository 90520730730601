import { Injectable, inject } from '@angular/core';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AlarmLimitsBuilderService {
  private _auth = inject(AuthService);


  buildAlarmLimitQuery(filters) {
    const query = {
      facilityName: null,
      assetIdentifier: null,
      alarmAttributeName: null,
      value: null,
      effectiveDateTime: null,
      businessUnits: null,
    };

    if(filters.facilityName) { query.facilityName = filters.facilityName.value.trim(); }
    if(filters.assetIdentifier) { query.assetIdentifier = filters.assetIdentifier.value.trim(); }
    if(filters.alarmAttributeName) { query.alarmAttributeName = filters.alarmAttributeName.value.trim(); }
    if(filters.value) { query.value = filters.value.value.trim(); }
    if(filters.effectiveDateTime) { query.effectiveDateTime = filters.effectiveDateTime.value; }
    if (filters.businessUnits) { query.businessUnits = filters.businessUnits }

    return query; 
  }
}

import { Injectable, inject } from '@angular/core';
import {
  CreateOrEditStationLog,
  StationLog,
} from '../model/station-logs/station-log';
import { StationLogReason } from '../model/station-logs/station-log-reason';
import { FacilityBuilderService } from './facility-builder.service';
import { Facility } from '../model/locations/facility';
import { UntypedFormGroup } from '@angular/forms';
import { CreateOrEditPart, Part } from '../model/station-logs/part';
import { getCorrectedDate } from '../utils/utils';
import { CreateOrEditPlcAlarmCode, PlcAlarmCode } from '../model/station-logs/plc-alarm-code';

@Injectable({
  providedIn: 'root',
})
export class StationLogBuilderService {
  private _facilityBuilder = inject(FacilityBuilderService);

  buildStationLogQuery(filters) {
    const query = {
      date: null,
      reason: null,
      message: null,
      facility: null,
      fixSolution: null,
      unitHours: null,
      createdAt: null,
      createdBy: null,
    };

    if (filters.date) {
      query.date = filters.date.value;
    }
    if (filters.reason) {
      query.reason = filters.reason.value.trim();
    }
    if (filters.facility) {
      query.reason = filters.facility.value.trim();
    }
    if (filters.message) {
      query.message = filters.message.value.trim();
    }
    if (filters.fixSolution) {
      query.fixSolution = filters.fixSolution.value.trim();
    }
    if (filters.unitHours) {
      query.unitHours = filters.unitHours.value.trim();
    }
    if (filters.createdAt) {
      query.createdAt = filters.createdAt.value;
    }
    if (filters.createdBy) {
      query.createdBy = filters.createdBy.value.trim();
    }

    return query;
  }

  buildStationLog(item: any): StationLog {
    return {
      id: item.id,
      date: item.date,
      facilityId: item.facilityId,
      facilityName: item.Facility?.facilityName,
      reasonId: item.reasonId,
      message: item.message,
      fixSolution: item.fixSolution,
      unitHours: item.unitHours,
      createdAt: item.createdAt,
      createdBy: item.createdBy,
      updatedAt: item.updatedAt,
      updater: item.updater,
      deletedAt: item.deletedAt,
      reason: item.Reason ? this.buildStationLogReason(item.Reason) : null,
      facility: item.Facility
        ? this._facilityBuilder.buildFacility(item.Facility, [])
        : null,
    };
  }

  buildStationLogReason(item: any): StationLogReason {
    return {
      id: item.id,
      reason: item.reason,
    };
  }

  buildCreateOrEditStationLog(
    item: UntypedFormGroup,
    facility: Facility
  ): CreateOrEditStationLog {
    return {
      id: item.get('stationLog')?.get('logId')?.value,
      date: item.get('stationLog')?.get('createdAt')?.value,
      facilityId:
        facility?.id != null
          ? facility?.id
          : item.get('contactLocInfo')?.get('facilityId')?.value.id,
      reasonId: item.get('stationLog')?.get('reason')?.value.id,
      message: item.get('stationLog')?.get('message')?.value,
      fixSolution: item.get('stationLog')?.get('fixSolution')?.value,
      unitHours: item.get('stationLog')?.get('unitHours')?.value,
    };
  }

  buildPart(item: any): Part {
    return {
      id: item.id,
      sapNumber: item.sapNumber,
      pciNumber: item.pciNumber,
      description: item.description,
      vendorPartNumber: item.vendorPartNumber,
      manufacturer: item.manufacturer,
      storeroom: item.storeroom,
      binLocation: item.binLocation,
      createdBy: item.createdBy,
      createdAt: getCorrectedDate(item.createdAt),
      updater: item.updater,
      updatedAt: getCorrectedDate(item.updatedAt),
      deletedAt: getCorrectedDate(item.deletedAt),
    };
  }

  buildCreateOrEditPart(obj: any): CreateOrEditPart {
    return {
      sapNumber: obj?.sapNumber,
      pciNumber: obj?.pciNumber,
      description: obj?.description,
      vendorPartNumber: obj?.vendorPartNumber,
      manufacturer: obj?.manufacturer,
      storeroom: obj?.storeroom,
      binLocation: obj?.binLocation,
    };
  }

  buildPlcAlarmCode(item: any): PlcAlarmCode {
      return {
      id: item.id,
      alarmCode: item.alarmCode,
      alarmComment: item.alarmComment,
      alarmExplanation: item.alarmExplanation,
      alarmFix: item.alarmFix,
      createdBy: item.createdBy,
      createdAt: getCorrectedDate(item.createdAt),
      updater: item.updater,
      updatedAt: getCorrectedDate(item.updatedAt),
      deletedAt: getCorrectedDate(item.deletedAt),
    };
  }

    buildCreateOrEditPlcAlarmCode(obj: any): CreateOrEditPlcAlarmCode {
    return {
      alarmCode: obj?.alarmCode,
      alarmComment: obj?.alarmComment,
      alarmExplanation: obj?.alarmExplanation,
      alarmFix: obj?.alarmFix,
    };
  }
}

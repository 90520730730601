import gql from 'graphql-tag';

export const queryForParts = gql`
  query queryForParts(
    $limit: Int
    $page: Int
    $query: PartSortOrQuery
    $sort: PartSortOrQuery
  ) {
    queryForParts(limit: $limit, page: $page, query: $query, sort: $sort) {
      items {
        id
        sapNumber
        pciNumber
        description
        vendorPartNumber
        manufacturer
        storeroom
        binLocation
        createdBy
        createdAt
        updater
        updatedAt
        deletedAt
      }
      totalRecords
    }
  }
`;

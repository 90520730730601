import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '../core/core.module';
import { OplogsDetailContainerComponent } from './containers/oplogs-detail-container/oplogs-detail-container.component';
import { OplogsAllLogsSearchContainerComponent } from './containers/oplogs-all-logs-search-container/oplogs-all-logs-search-container.component';
import { OpAlertLogPrintContainerComponent } from './containers/op-alert-log-print-container/op-alert-log-print-container.component';
import { OpLogsEntryComponent } from './components/op-logs-entry/op-logs-entry.component';
import { OpLogMobileFilterComponent } from './components/op-log-mobile-filter/op-log-mobile-filter.component';
import { AdminModule } from '../admin/admin.module';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TranslateModule } from '@ngx-translate/core';
import { OplogsRoutingModule } from './operational-alert-routing.module';
import { AlarmLimitsSearchContainerComponent } from './containers/alarm-limits-search-container/alarm-limits-search-container.component';
import { AlarmLimitsDetailContainerComponent } from './containers/alarm-limits-detail-container/alarm-limits-detail-container.component';
import { RequestAlarmMonitoringComponent } from './components/request-alarm-monitoring/request-alarm-monitoring.component';
import { AlarmLimitAuditTrailComponent } from './components/alarm-limit-audit-trail/alarm-limit-audit-trail.component';
import { SyncAlarmLimitsContainerComponent } from './containers/sync-alarm-limits-container/sync-alarm-limits-container.component';
import { PiValueDisplayComponent } from './components/pi-value-display/pi-value-display.component';
import { StationLogsSearchContainerComponent } from './containers/station-logs/station-logs-search-container.component';
import { StationLogFormComponent } from './components/station-log-form/station-log-form.component';
import { ToastModule } from 'primeng/toast';
import { DividerModule } from 'primeng/divider';
import { InputNumberModule } from 'primeng/inputnumber';
import { PartsSearchContainerComponent } from './containers/parts-search-container/parts-search-container.component';
import { TroubleshootingSearchContainerComponent } from './containers/troubleshooting-search-container/troubleshooting-search-container/troubleshooting-search-container.component';

@NgModule({
  declarations: [
    OplogsDetailContainerComponent,
    OpLogsEntryComponent,
    OplogsAllLogsSearchContainerComponent,
    OpLogMobileFilterComponent,
    OpAlertLogPrintContainerComponent,
    AlarmLimitsSearchContainerComponent,
    AlarmLimitsDetailContainerComponent,
    RequestAlarmMonitoringComponent,
    AlarmLimitAuditTrailComponent,
    SyncAlarmLimitsContainerComponent,
    StationLogsSearchContainerComponent,
    StationLogFormComponent,
  ],
  imports: [
    CommonModule,
    CoreModule,
    OplogsRoutingModule,
    AdminModule,
    ProgressSpinnerModule,
    PiValueDisplayComponent,
    ToastModule,
    DividerModule,
    InputNumberModule,
    PartsSearchContainerComponent,
    TroubleshootingSearchContainerComponent,
    TranslateModule.forChild({}),
  ],
  exports: [
    OplogsDetailContainerComponent,
    OpLogsEntryComponent,
    OplogsAllLogsSearchContainerComponent,
    OpLogMobileFilterComponent,
    OpAlertLogPrintContainerComponent,
    AlarmLimitsSearchContainerComponent,
    AlarmLimitsDetailContainerComponent,
    SyncAlarmLimitsContainerComponent,
    StationLogsSearchContainerComponent,
    StationLogFormComponent,
    PartsSearchContainerComponent,
    TroubleshootingSearchContainerComponent,
  ],
})
export class OperationalMonitoringModule {}

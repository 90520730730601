import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  viewChild,
  inject,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SelectItem } from 'primeng/api';
import { forkJoin, interval, Subscription, take } from 'rxjs';
import { SearchContainerComponent } from 'src/app/core/containers/search-container/search-container.component';
import { AuthService } from 'src/app/services/auth.service';
import { DeviceService } from 'src/app/services/device.service';
import { LoadingService } from 'src/app/services/loading.service';
import { AuthApiService } from 'src/app/services/auth-api.service';
import { BreadCrumbBuilderService } from 'src/app/services/breadcrumb-builder.service';
import { getStationLogColumns } from '../../definitions/station-logs-table-definition';
import { StationLog } from 'src/app/model/station-logs/station-log';
import { StationLogApiService } from 'src/app/services/station-log-api.service';
import { StationLogBuilderService } from 'src/app/services/station-log-builder.service';
import { StationLogTableService } from 'src/app/services/station-log-table.service';
import { TabService } from 'src/app/core/services/tab.service';
import { StationLogReason } from 'src/app/model/station-logs/station-log-reason';
import { LogAndMessageService } from 'src/app/services/log-and-message.service';
import { StationLogFormComponent } from '../../components/station-log-form/station-log-form.component';
import { PART_SEARCH, TROUBLESHOOT } from 'src/app/constants/action-constants';
import { isNullOrUndefined } from 'src/app/utils/utils';
import { CLAIMS } from 'src/app/constants/auth-constants';

@Component({
  selector: 'app-station-logs-search-container',
  templateUrl: './station-logs-search-container.component.html',
  styleUrls: ['./station-logs-search-container.component.scss'],
  standalone: false,
})
export class StationLogsSearchContainerComponent
  extends SearchContainerComponent<StationLog>
  implements OnInit, OnDestroy, AfterViewInit
{
  private _translateService = inject(TranslateService);
  private _breadCrumbBuilderService = inject(BreadCrumbBuilderService);
  private _stationLogApi = inject(StationLogApiService);
  private _stationLogBuilder = inject(StationLogBuilderService);
  private _authApi = inject(AuthApiService);
  private _isRefreshing = false;
  protected _deviceService: DeviceService;
  protected _cdRef: ChangeDetectorRef;
  protected _loader: LoadingService;
  protected _tableService = inject(StationLogTableService);
  protected _auth: AuthService;
  protected _logAndMessage = inject(LogAndMessageService);

  readonly stationLogForm =
    viewChild.required<StationLogFormComponent>('stationLogForm');

  allLogs: StationLog[] = [];
  loading: boolean = true;
  refreshSubscription: Subscription;
  availableReasons: SelectItem[];
  formType: string = 'Edit';
  formLoading: boolean = false;
  formVisible: boolean = false;
  canCreate: boolean = false;
  canEdit: boolean = false;
  showSuccessMessage: boolean = true;
  selectedStationLog: StationLog;

  constructor() {
    const _deviceService = inject(DeviceService);
    const _cdRef = inject(ChangeDetectorRef);
    const _loader = inject(LoadingService);
    const _auth = inject(AuthService);

    super(_deviceService, _loader, _auth, _cdRef);
    this._deviceService = _deviceService;
    this._cdRef = _cdRef;
    this._loader = _loader;
    this._auth = _auth;
  }

  ngOnInit() {
    this.setTableService(this._tableService);
    if (this._loader.isLoaded()) {
      this.initialize();
    } else {
      this._loader.loadingFinishedEvent.pipe(take(1)).subscribe(() => {
        this.initialize();
      });
    }

    this.canCreate = this._authApi.doesUserHaveAllClaimsFromList([
      CLAIMS.FIELD_OPERATIONS.STATION_LOGS.CREATE_STATION_LOGS,
    ]);
    this.canEdit = this._authApi.doesUserHaveAllClaimsFromList([
      CLAIMS.FIELD_OPERATIONS.STATION_LOGS.EDIT_STATION_LOGS,
    ]);

    const refreshInterval = interval(60000);
    this.refreshSubscription = refreshInterval.subscribe(() => {
      this.refresh(false);
    });
  }

  initialize() {
    this.loadStationReasons();
    const breadCrumb$ = this._translateService.get(
      'OPMONITORING.SCREEN.STATION_LOGS'
    );
    const header$ = this._translateService.get(
      'OPMONITORING.SCREEN.STATION_LOGS'
    );
    forkJoin([header$, breadCrumb$])
      .pipe(take(1))
      .subscribe((mes) => {
        if (this._deviceService.isMobile()) {
          this._breadCrumbBuilderService.resetAndAddBreadCrumb({
            label: mes[1],
            routerLink: null,
          });
        } else {
          TabService.getInstance().updateActiveTabLabel(mes[0]);
        }
        this.screenName = mes[0];
      });
  }

  loadStationReasons() {
    this._stationLogApi
      .getStationLogReasons()
      .pipe(take(1))
      .subscribe({
        next: (data: StationLogReason[]) => {
          this.availableReasons = [
            { label: 'Choose', value: null },
            ...data.map((d) => ({ label: d.reason, value: d.id })),
          ];

          this.columns = getStationLogColumns(this.availableReasons);
          this.loading = false;
        },
        error: (error) => {
          this._logAndMessage.errorLogOnly(error);
          this._logAndMessage.translateToErrorMessage({
            bodyKey: 'OPMONITORING.MESSAGES.ERROR.STATION_LOG_LOAD',
            headerKey: 'COMMON.MESSAGES.HEADERS.ERROR',
          });

          this.loading = false;
          this._isRefreshing = false;
        },
      });
  }

  refresh(setLoading = true): void {
    this.lazyLoad({ lazy: this._tableService.getLastLazyLoad() }, setLoading);
  }

  handleLazyLoad(req, $event, filters) {
    const query = this._stationLogBuilder.buildStationLogQuery(filters);
    this.queryNetwork(req, $event, query);
  }

  private queryNetwork(req, $event, query) {
    req.page += 1;
    const sort = {};
    if ($event.lazy && $event.lazy.sortField) {
      let field = $event.lazy.sortField;

      sort[field] =
        $event.lazy.sortOrder && $event.lazy.sortOrder === 1 ? 'DESC' : 'ASC';
      this._tableService.setLastSortField($event.lazy.sortField);
      this._tableService.setLastSortDirection(
        $event.lazy._tableServicesortOrder
      );
    } else {
      sort['createdAt'] = 'DESC';
      this._tableService.setLastSortField('createdAt');
      this._tableService.setLastSortDirection(0);
    }

    if (!this._isRefreshing) {
      this._isRefreshing = true;
      this._stationLogApi
        .queryForStationLogs(req.pageSize, req.page, query, sort)
        .pipe(take(1))
        .subscribe({
          next: (response) => {
            this.totalRecords = response.totalRecords;
            this.allLogs = response.items;
            this.elements = [...this.allLogs];
            this._isRefreshing = false;
            this.loading = false;
            if (this.showSuccessMessage) {
              this.showSuccessMessage = false;
              this._logAndMessage.translateToSuccessMessage({
                bodyKey: 'OPMONITORING.MESSAGES.SUCCESS.QUERY_STATION_LOGS',
                headerKey: 'COMMON.MESSAGES.HEADERS.SUCCESS',
              });
            }
          },
          error: (error) => {
            this._isRefreshing = false;
            this.loading = false;
            this._logAndMessage.errorLogOnly(error);
            this._logAndMessage.translateToErrorMessage({
              bodyKey: 'OPMONITORING.MESSAGES.ERROR.STATION_LOG_LOAD',
              headerKey: 'COMMON.MESSAGES.HEADERS.ERROR',
            });
          },
        });
    }
  }

  clearScreen() {
    this.lazyLoad({ lazy: {} });
    this.grid().resetTable();
    this._tableService.clearResults();
    this.elements = [];
  }

  newStationLog() {
    this.formType = 'Create';
    this.stationLogForm().resetEffectiveDate();
    this.stationLogForm().enableForm();
    this.formVisible = true;
  }

  changeViewEvent($event) {
    this.formType = 'Edit';
    this.selectedStationLog = $event.data;

    setTimeout(() => {
      this.stationLogForm().updateFields();
      this.formVisible = true;
    }, 5);
  }

  onFormHide() {
    this.formVisible = false;
  }

  onFormReady() {
    this.formLoading = false;
  }

  onFormSubmit($event: any) {
    this.refresh();
  }

  onStationLogDelete() {
    this.refresh();
  }

  ngAfterViewInit(): void {
    this.applyPreviousFilters();
    this.applyPreviousData();
  }

  ngOnDestroy(): void {
    this.refreshSubscription.unsubscribe();
  }
}

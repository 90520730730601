<p-blockUI [blocked]="pageLoading()"></p-blockUI>
<app-progress-spinner [enabled]="pageLoading()"></app-progress-spinner>

<div class="header">
  @if (canCreate()) {
  <button
    pButton
    class="width-eighty"
    label="{{ 'COMMON.LABEL.BUTTONS.NEW' | translate }}"
    icon="fa fa-fw fa-plus-circle"
    (click)="newAlarmCode()"
  ></button>
  }
  <button pButton class="width-eighty" label="{{'COMMON.LABEL.BUTTONS.CLEAR' | translate}}" icon="fa fa-fw fa-eraser"
    (click)="clearScreen()"></button>
  <button
    pButton
    class="refresh-button width-twenty"
    icon="fa fa-fw fa-refresh"
    (click)="refresh()"
  ></button>
</div>

<app-general-grid
  [values]="elements"
  [columns]="columns"
  [deviceSize]="getDeviceSize()"
  [totalRecords]="totalRecords"
  [filterable]="true"
  [screenName]="screenName"
  [rows]="rows"
  [lazy]="true"
  (lazyLoad)="lazyLoad($event)"
  [includeExportCSV]="false"
  (changeViewEvent)="changeViewEvent($event)"
  scrollHeight="calc(100vh - 325px)"
  [usePaginator]="true"
  [rowsPerPageOptions]="[50, 100, 200]"
  #grid
>
</app-general-grid>

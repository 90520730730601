import { gql } from '@apollo/client/core';

export const createPlcAlarmCode = gql`
  mutation createPlcAlarmCode($input: CreateOrEditPlcAlarmCode) {
    createPlcAlarmCode(input: $input) {
      id
      alarmCode
      alarmComment
      alarmExplanation
      alarmFix
      createdBy
      createdAt
      updater
      updatedAt
      deletedAt
    }
  }
`;

import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, viewChild, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LazyLoadEvent, SelectItem } from 'primeng/api';
import { forkJoin, interval, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { OPLOG_DETAILS_CONTAINER } from 'src/app/constants/common.constants';
import { SearchContainerComponent } from 'src/app/core/containers/search-container/search-container.component';
import { TabService } from 'src/app/core/services/tab.service';
import { AuthService } from 'src/app/services/auth.service';
import { DeviceService } from 'src/app/services/device.service';
import { LoadingService } from 'src/app/services/loading.service';
import { AuthApiService } from 'src/app/services/auth-api.service';
import { OpAlertLogTableService } from 'src/app/services/op-alert-log-table.service';
import { BreadCrumbBuilderService } from 'src/app/services/breadcrumb-builder.service';
import { BusinessUnitFilterComponent } from 'src/app/core/components/business-unit-filter/business-unit-filter.component';
import { OperationalMonitoringApiService } from 'src/app/services/operational-monitoring-api.service';
import { OpAlertLog } from 'src/app/model/opAlertLogs/op-alert-log';
import { OpAlertLogsBuilderService } from 'src/app/services/op-alert-logs-builder.service';
import { getOpLogColumns } from '../../definitions/all-op-log-table-definition';

@Component({
    selector: 'app-oplogs-all-logs-search-container',
    templateUrl: './oplogs-all-logs-search-container.component.html',
    styleUrls: ['./oplogs-all-logs-search-container.component.scss'],
    standalone: false
})
export class OplogsAllLogsSearchContainerComponent
  extends SearchContainerComponent<OpAlertLog>
  implements OnInit, OnDestroy, AfterViewInit
{
  private _translateService = inject(TranslateService);
  protected _deviceService: DeviceService;
  private _breadCrumbBuilderService = inject(BreadCrumbBuilderService);
  private _opMonitoringApi = inject(OperationalMonitoringApiService);
  private _opAlertLogBuilder = inject(OpAlertLogsBuilderService);
  private _authApi = inject(AuthApiService);
  protected _cdRef: ChangeDetectorRef;
  protected _loader: LoadingService;
  protected _tableService = inject(OpAlertLogTableService);
  protected _auth: AuthService;

  readonly businessUnitFilter = viewChild<BusinessUnitFilterComponent>('buFilter');
  allLogs: any[] = [];
  activeLogTypes: any[] = [];
  loading: boolean;
  logsLoading: boolean;
  availableCompanies: any[];
  logsLoadingEvent = new EventEmitter<any>();
  lastToken: string = null;
  additionalFilters = [];
  selectedAction: any;
  statusOptions: SelectItem[];
  mobileFilterCollapsed = true;
  exportFilename: string;
  selectMessage: string = '';
  private _isRefreshing = false;
  refreshSubscription: Subscription;

  constructor() {
    const _deviceService = inject(DeviceService);
    const _cdRef = inject(ChangeDetectorRef);
    const _loader = inject(LoadingService);
    const _auth = inject(AuthService);

    super(_deviceService, _loader, _auth, _cdRef);
    this._deviceService = _deviceService;
    this._cdRef = _cdRef;
    this._loader = _loader;
    this._auth = _auth;

  }

  ngOnInit() {
    this.setTableService(this._tableService);
    if (this._loader.isLoaded()) {
      this.initialize();
    } else {
      this._loader.loadingFinishedEvent.pipe(take(1)).subscribe(() => {
        this.initialize();
      });
    }

    const refreshInterval = interval(60000);
    this.refreshSubscription = refreshInterval.subscribe(() => {
      this.refresh(false);
    });
  }

  ngAfterViewInit(): void {
    this.applyPreviousFilters();
    this.applyPreviousData();
  }

  initialize() {
    this.statusOptions = this._tableService.getStatusOptions();
    this.columns = getOpLogColumns(
      this.getDeviceSize(),
      this.statusOptions,
      this.isMobileDevice()
    );

    const breadCrumb$ = this._translateService.get(
      'OPMONITORING.SCREEN.ALL_OPERATIONAL_LOGS'
    );
    const header$ = this._translateService.get(
      'OPMONITORING.SCREEN.ALL_OPERATIONAL_LOGS'
    );
    const exportFilename$ = this._translateService.get(
      'MONLOG.EXPORT_FILE_NAME.ALL_LOGS'
    );
    forkJoin([header$, exportFilename$, breadCrumb$])
      .pipe(take(1))
      .subscribe((mes) => {
        if (this._deviceService.isMobile()) {
          this._breadCrumbBuilderService.resetAndAddBreadCrumb({
            label: mes[2],
            routerLink: null,
          });
        } else {
          TabService.getInstance().updateActiveTabLabel(mes[0]);
        }
        this.screenName = mes[0];
        this.exportFilename = mes[1];
      });
  }

  ngOnDestroy(): void {
    this.refreshSubscription.unsubscribe();
  }

  /**
   * Method called when the user hits the clear button.
   */
  clearScreen() {
    this.lazyLoad({ lazy: {} });
    this.grid().resetTable();
    this._tableService.clearResults();
    this.elements = [];
  }

  /**
   * Method called when a row double click event is fired.  This navigates to the details page.
   * @param $event - Event from the double click event.
   */
  changeViewEvent($event) {
    this._tableService.setSelected($event.data);
    this._tableService.setLastScreen('all');

    const tab = TabService.getInstance().buildNewTab(
      OPLOG_DETAILS_CONTAINER,
      true,
      null,
      $event.data.id
    );
    if (this._deviceService.isMobile()) {
      TabService.getInstance().setMobileTab(tab);
    } else {
      TabService.getInstance().openTab(tab);
    }
  }

  handleLazyLoad(req, $event, filters) {
    let selectedBusinessUnits = [];
    const businessUnitFilter = this.businessUnitFilter();
    if (businessUnitFilter?.selectedBusinessUnits) {
      // Handle further refreshes & changes to business unit filter component
      selectedBusinessUnits = businessUnitFilter.selectedBusinessUnits;
    } else {
      // Handle initial page load (before the business unit filter subcomponent exists)
      selectedBusinessUnits = this._authApi
        .getUserBusinessUnits()
        .map((bu) => bu.id);
    }
    filters['businessUnits'] = selectedBusinessUnits;

    const query = this._opAlertLogBuilder.buildOpLogQuery(filters);
    this.queryNetwork(req, $event, query);
  }

  private queryNetwork(req, $event, query) {
    req.page += 1;
    const sort = {};
    if ($event.lazy && $event.lazy.sortField) {
      // sorting
      if ($event.lazy.sortField === 'statusName') {
        sort['logStatus'] =
          $event.lazy.sortOrder && $event.lazy.sortOrder === 1 ? 'ASC' : 'DESC';
      } else {
        sort[$event.lazy.sortField] =
          $event.lazy.sortOrder && $event.lazy.sortOrder === 1 ? 'ASC' : 'DESC';
      }

      this._tableService.setLastSortField($event.lazy.sortField);
      this._tableService.setLastSortDirection($event.lazy.sortOrder);
    } else {
      sort['createdAt'] = 'DESC';
      this._tableService.setLastSortField('createdAt');
      this._tableService.setLastSortDirection(0);
    }

    if (!this._isRefreshing) {
      this._isRefreshing = true;
      this._opMonitoringApi
        .getOpAlertLogs(req.pageSize, req.page, query, sort)
        .pipe(take(1))
        .subscribe(
          ({ data }) => {
            const clone = Object.assign({}, data);
            this.totalRecords = clone.getOpAlertLogs.totalRecords;
            this.allLogs = [
              ...clone.getOpAlertLogs.items.map((i) =>
                this._opAlertLogBuilder.buildOpAlertLogSearch(i)
              ),
            ];
            this.elements = [...this.allLogs];
            this.loading = false;
            this._cdRef.markForCheck();
            this._isRefreshing = false;
          },
          (error) => {
            console.log(error);
            this.loading = false;
            this._isRefreshing = false;
          }
        );
    }
  }

  handleBusinessUnitChange($event) {
    this.lazyLoad({ lazy: this._tableService.getLastLazyLoad() });
  }

  mobileFilter($event) {
    const newLazy: LazyLoadEvent = {
      filters: {
        ...this.grid().table().filters,
        logId: $event.logId
          ? {
              value: $event.logId,
              matchMode: 'contains',
            }
          : null,
        facilityName: $event.facilityName
          ? {
              value: $event.facilityName,
              matchMode: 'contains',
            }
          : null,
        logStatus: $event.statusName
          ? {
              value: $event.statusName,
              matchMode: 'contains',
            }
          : null,
      },
    };
    this.mobileFilterCollapsed = true;
    this._tableService.setLastLazyLoad(newLazy);
    this.lazyLoad({ lazy: this._tableService.getLastLazyLoad() });
  }

  refresh(setLoading = true): void {
    this.lazyLoad({ lazy: this._tableService.getLastLazyLoad() }, setLoading);
  }
}

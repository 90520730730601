import { Component, OnInit, input, output } from '@angular/core';
import { OpAlertLogAlarm } from 'src/app/model/opAlertLogs/op-alert-log-alarm';
import { OpAlertLogEntry } from 'src/app/model/opAlertLogs/op-alert-log-entry';

@Component({
    selector: 'app-op-logs-entry',
    templateUrl: './op-logs-entry.component.html',
    styleUrls: ['./op-logs-entry.component.scss'],
    standalone: false
})
export class OpLogsEntryComponent implements OnInit {
  readonly entry = input<OpAlertLogAlarm | OpAlertLogEntry>(undefined);
  readonly writeAccess = input(false);
  readonly editEvent = output<any>();
  readonly deleteEvent = output<any>();

  constructor() {}

  ngOnInit() {}

  innerHTML() {
    const entry = this.entry();
    if ('entry' in entry) {
      return entry.entry;
    } else if ('alarm' in entry) {
      return entry.alarm;
    }
  }
}

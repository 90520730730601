import {
  Component,
  inject,
  OnDestroy,
  OnInit,
  signal,
  WritableSignal,
} from '@angular/core';
import { CoreModule } from '../../../core/core.module';
import { Part, PartSortOrQuery } from 'src/app/model/station-logs/part';
import { SearchContainerComponent } from 'src/app/core/containers/search-container/search-container.component';
import { AdminTableService } from 'src/app/services/admin-table.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { firstValueFrom, forkJoin, Subscription } from 'rxjs';
import { TabService } from 'src/app/core/services/tab.service';
import { AuthApiService } from 'src/app/services/auth-api.service';
import { isNullOrUndefined } from 'src/app/utils/utils';
import { StationLogApiService } from 'src/app/services/station-log-api.service';
import { LogAndMessageService } from 'src/app/services/log-and-message.service';
import {
  DialogService,
  DynamicDialogModule,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { PartsDialogComponent } from '../../components/parts-dialog/parts-dialog.component';
import { PARTS_SEARCH_TABLE_DEFINITION } from '../../definitions/parts-search-table-definition';
import { CLAIMS } from 'src/app/constants/auth-constants';

@Component({
  selector: 'app-parts-search-container',
  imports: [CoreModule, TranslateModule, DynamicDialogModule],
  providers: [DialogService],
  templateUrl: './parts-search-container.component.html',
  styleUrl: './parts-search-container.component.scss',
})
export class PartsSearchContainerComponent
  extends SearchContainerComponent<Part>
  implements OnInit, OnDestroy
{
  protected _adminTableService = inject(AdminTableService);
  protected _translateService = inject(TranslateService);
  protected _authApi = inject(AuthApiService);
  protected _stationLogApi = inject(StationLogApiService);
  protected _logAndMessage = inject(LogAndMessageService);
  protected dialogService = inject(DialogService);

  ref: DynamicDialogRef | undefined;

  pageLoading = signal(true);
  parts: WritableSignal<Part[]> = signal([]);
  canCreate = signal(false);

  activeTabChangedSub: Subscription;

  ngOnInit(): void {
    this._adminTableService.setLastLazyLoad(undefined);
    this.setTableService(this._adminTableService);
    this.columns = PARTS_SEARCH_TABLE_DEFINITION;

    const screenName$ = this._translateService.get(
      'OPMONITORING.SCREEN.PARTS_SEARCH'
    );
    forkJoin([screenName$]).subscribe((label) => {
      TabService.getInstance().updateActiveTabLabel(label[0]);
      this.screenName = label[0];
    });

    this.canCreate.set(this._authApi.doesUserHaveClaim(CLAIMS.FIELD_OPERATIONS.PARTS_SEARCH.CREATE_PARTS));

    this.applyPreviousData();
    this.applyPreviousFilters();
  }

  ngOnDestroy(): void {
    if (this.activeTabChangedSub) {
      this.activeTabChangedSub.unsubscribe();
    }
  }

  handleLazyLoad(req, $event, filters) {
    this._adminTableService.setLastAdminLazyLoad(this.screenName, $event.lazy);
    const query: PartSortOrQuery = {
      sapNumber: null,
      pciNumber: null,
      description: null,
      vendorPartNumber: null,
      manufacturer: null,
      storeroom: null,
      binLocation: null,
    };

    if (filters.sapNumber) {
      query.sapNumber = filters.sapNumber.value;
    }
    if (filters.pciNumber) {
      query.pciNumber = filters.pciNumber.value;
    }
    if (filters.description) {
      query.description = filters.description.value;
    }
    if (filters.vendorPartNumber) {
      query.vendorPartNumber = filters.vendorPartNumber.value;
    }
    if (filters.manufacturer) {
      query.manufacturer = filters.manufacturer.value;
    }
    if (filters.storeroom) {
      query.storeroom = filters.storeroom.value;
    }
    if (filters.binLocation) {
      query.binLocation = filters.binLocation.value;
    }

    this.queryNetwork(req, $event, query);
  }

  queryNetwork(req, $event, query): void {
    req.page += 1;
    const sort = this.buildNetworkSortQuery($event, 'sapNumber');
    this.pageLoading.set(true);
    this._stationLogApi
      .queryForParts(req.page, req.pageSize, query, sort)
      .subscribe({
        next: (response) => {
          this.parts.set(response.items);
          this.totalRecords = response.totalRecords;
          this.elements = [...this.parts()];
          this.pageLoading.set(false);
          this._logAndMessage.translateToSuccessMessage({
            bodyKey: 'OPMONITORING.MESSAGES.SUCCESS.QUERY_PARTS',
            headerKey: 'COMMON.MESSAGES.HEADERS.SUCCESS',
          });
        },
        error: (error) => {
          this.pageLoading.set(false);
          this._logAndMessage.errorLogOnly(error);
          this._logAndMessage.translateToErrorMessage({
            bodyKey: 'OPMONITORING.MESSAGES.ERROR.QUERY_PARTS',
            headerKey: 'COMMON.MESSAGES.HEADERS.ERROR',
          });
        },
      });
  }

  async changeViewEvent($event: { data: Part }): Promise<void> {
    const dialogEditHeader = await firstValueFrom(
      this._translateService.get('OPMONITORING.MESSAGES.HEADERS.EDIT_PART')
    );
    this.ref = this.dialogService.open(PartsDialogComponent, {
      header: dialogEditHeader,
      dismissableMask: true,
      data: {
        part: $event.data,
        formType: 'Edit',
      },
    });
    this.ref.onClose.subscribe((data) => {
      if (!data) {
        // If there's no data provided in the onclose, we didn't actually do anything to the part
        // so we don't need to refresh the page
        return;
      }
      this.refresh();
    });
  }

  refresh(): void {
    this.lazyLoad({ lazy: this._adminTableService.getLastLazyLoad() });
  }

  async newPart(): Promise<void> {
    const dialogCreateHeader = await firstValueFrom(
      this._translateService.get('OPMONITORING.MESSAGES.HEADERS.CREATE_PART')
    );
    this.ref = this.dialogService.open(PartsDialogComponent, {
      header: dialogCreateHeader,
      dismissableMask: true,
      data: {
        part: null,
        formType: 'Create',
      },
    });
    this.ref.onClose.subscribe((data) => {
      if (!data) {
        // If there's no data provided in the onclose, we didn't actually do anything to the part
        // so we don't need to refresh the page
        return;
      }
      this.refresh();
    });
  }

  setupActiveTabChangedSubscription(): void {
    this.activeTabChangedSub =
      TabService.getInstance().activeTabChanged.subscribe((tab) => {
        if (!isNullOrUndefined(tab) && tab.header === this.screenName) {
          this._adminTableService.setLastLazyLoad(
            this._adminTableService.getLastAdminLazyLoad(this.screenName)
          );
          this.refresh();
        }
      });
  }
}

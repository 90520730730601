import { Column } from 'src/app/model/common/column';

export const TROUBLESHOOTING_SEARCH_TABLE_DEFINITION: Column[] = [
  {
    field: 'alarmCode',
    header: 'OPMONITORING.LABEL.ALARM_CODE',
    mobileHeader: 'OPMONITORING.LABEL.ALARM_CODE',
    formatPattern: 'text',
    type: 'input',
    width: '5%',
    sortable: true,
    filterable: true,
    matchMode: 'contains',
  },
  {
    field: 'alarmComment',
    header: 'OPMONITORING.LABEL.ALARM_COMMENT',
    mobileHeader: 'OPMONITORING.LABEL.ALARM_COMMENT',
    formatPattern: 'text',
    type: 'input',
    width: '10%',
    sortable: true,
    filterable: true,
    matchMode: 'contains',
  },
  {
    field: 'alarmExplanation',
    header: 'OPMONITORING.LABEL.ALARM_EXPLANATION',
    mobileHeader: 'OPMONITORING.LABEL.ALARM_EXPLANATION',
    formatPattern: 'text',
    type: 'input',
    width: '17%',
    sortable: true,
    filterable: true,
    matchMode: 'contains',
  },
  {
    field: 'alarmFix',
    header: 'OPMONITORING.LABEL.ALARM_FIX',
    mobileHeader: 'OPMONITORING.LABEL.ALARM_FIX',
    formatPattern: 'text',
    type: 'input',
    width: '17%',
    sortable: true,
    filterable: true,
    matchMode: 'contains',
  },
];

import { Injectable, inject } from '@angular/core';
import { WatchQueryOptions } from 'apollo-client';
import { from, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AppsyncService } from './appsync.service';
import { AuthApiService } from './auth-api.service';
import { BaseAppSyncService } from './base-app-sync.service';
import { TC_CLAIMS_HEADER } from '../constants/auth-constants';
import { getOpAlertLogs } from '../graphql/field-operations/get-op-alert-logs';
import { createOpAlertLog } from '../graphql/field-operations/create-op-alert-log';
import { editOpAlertLog } from '../graphql/field-operations/edit-op-alert-log';
import { getOpAlertLog } from '../graphql/field-operations/get-op-alert-log';
import { createOpAlertLogEntry } from '../graphql/field-operations/create-op-alert-log-entry';
import { editOpAlertLogEntry } from '../graphql/field-operations/edit-op-alert-log-entry';
import { createOpAlertLogAlarm } from '../graphql/field-operations/create-op-alert-log-alarm';
import { editOpAlertLogAlarm } from '../graphql/field-operations/edit-op-alert-log-alarm';
import { createOpAlertLogNotification } from '../graphql/field-operations/create-op-alert-log-notification';
import { queryForAlarmLimits } from '../graphql/field-operations/query-for-alarm-limits';
import { addAlarmLimit } from '../graphql/field-operations/add-alarm-limit';
import { deleteAlarmLimit } from '../graphql/field-operations/delete-alarm-limit';
import { editAlarmLimit } from '../graphql/field-operations/edit-alarm-limit';
import { getAlarmLimitHistories } from '../graphql/field-operations/get-alarm-limit-histories';
import { sendAlarmLimitRequestEmail } from '../graphql/field-operations/send-alarm-limit-request-email';
import { getAlarmLimits } from '../graphql/field-operations/get-alarm-limits';
import { getPIConfigurableElements } from '../graphql/field-operations/get-pi-configurable-elements';
import { getLatestPIValue } from '../graphql/field-operations/get-latest-pi-value';
import { PIValue } from '../model/operational-monitoring/pi-value';
import { OpAlertLogsBuilderService } from './op-alert-logs-builder.service';
import { getPIValuePlot } from '../graphql/field-operations/get-pi-value-plot';

@Injectable({
  providedIn: 'root',
})
export class OperationalMonitoringApiService extends BaseAppSyncService {
  protected _api: AppsyncService;
  protected _authApi = inject(AuthApiService);
  private _opBuilder = inject(OpAlertLogsBuilderService);

  constructor() {
    const _api = inject(AppsyncService);

    super(_api);
    this._api = _api;

  }

  getOpAlertLogs(
    limit: number,
    page: number,
    query: any,
    sort: any
  ): Observable<any> {
    return from(this._api.hc2()).pipe(
      switchMap((client: any) => {
        const options: WatchQueryOptions = {
          query: getOpAlertLogs,
          fetchPolicy: 'no-cache',
          variables: {
            limit,
            page,
            query,
            sort,
          },
          context: {
            headers: {
              [TC_CLAIMS_HEADER]: this._authApi.getUserClaimsHeader(),
            },
          },
        };
        return client.query(options);
      })
    );
  }

  getOpAlertLog(id): Observable<any> {
    return from(this._api.hc2()).pipe(
      switchMap((client: any) => {
        const options: WatchQueryOptions = {
          query: getOpAlertLog,
          fetchPolicy: 'no-cache',
          variables: {
            id,
          },
          context: {
            headers: {
              [TC_CLAIMS_HEADER]: this._authApi.getUserClaimsHeader(),
            },
          },
        };
        return client.query(options);
      })
    );
  }

  createOpAlertLog(logInput): Observable<any> {
    return from(this._api.hc2()).pipe(
      switchMap((client: any) => {
        return client.mutate({
          mutation: createOpAlertLog,
          variables: { input: logInput },
          context: {
            headers: {
              [TC_CLAIMS_HEADER]: this._authApi.getUserClaimsHeader(),
            },
          },
        });
      })
    );
  }

  editOpAlertLog(log, logInput): Observable<any> {
    return from(this._api.hc2()).pipe(
      switchMap((client: any) => {
        return client.mutate({
          mutation: editOpAlertLog,
          variables: { id: log.id, input: logInput },
          context: {
            headers: {
              [TC_CLAIMS_HEADER]: this._authApi.getUserClaimsHeader(),
            },
          },
        });
      })
    );
  }

  createOpAlertLogEntry(logInput): Observable<any> {
    return from(this._api.hc2()).pipe(
      switchMap((client: any) => {
        return client.mutate({
          mutation: createOpAlertLogEntry,
          variables: { input: logInput },
          context: {
            headers: {
              [TC_CLAIMS_HEADER]: this._authApi.getUserClaimsHeader(),
            },
          },
        });
      })
    );
  }

  editOpAlertLogEntry(log, logInput): Observable<any> {
    return from(this._api.hc2()).pipe(
      switchMap((client: any) => {
        return client.mutate({
          mutation: editOpAlertLogEntry,
          variables: { id: log.id, input: logInput },
          context: {
            headers: {
              [TC_CLAIMS_HEADER]: this._authApi.getUserClaimsHeader(),
            },
          },
        });
      })
    );
  }

  createOpAlertLogAlarm(logInput): Observable<any> {
    return from(this._api.hc2()).pipe(
      switchMap((client: any) => {
        return client.mutate({
          mutation: createOpAlertLogAlarm,
          variables: { input: logInput },
          context: {
            headers: {
              [TC_CLAIMS_HEADER]: this._authApi.getUserClaimsHeader(),
            },
          },
        });
      })
    );
  }

  editOpAlertLogAlarm(log, logInput): Observable<any> {
    return from(this._api.hc2()).pipe(
      switchMap((client: any) => {
        return client.mutate({
          mutation: editOpAlertLogAlarm,
          variables: { id: log.id, input: logInput },
          context: {
            headers: {
              [TC_CLAIMS_HEADER]: this._authApi.getUserClaimsHeader(),
            },
          },
        });
      })
    );
  }

  createOpAlertLogNotification(logInput): Observable<any> {
    return from(this._api.hc2()).pipe(
      switchMap((client: any) => {
        return client.mutate({
          mutation: createOpAlertLogNotification,
          variables: { input: logInput },
          context: {
            headers: {
              [TC_CLAIMS_HEADER]: this._authApi.getUserClaimsHeader(),
            },
          },
        });
      })
    );
  }

  queryForAlarmLimits(
    limit: number,
    page: number,
    query: any,
    sort: any
  ): Observable<any> {
    return from(this._api.hc2()).pipe(
      switchMap((client: any) => {
        const options: WatchQueryOptions = {
          query: queryForAlarmLimits,
          context: {
            headers: {
              [TC_CLAIMS_HEADER]: this._authApi.getUserClaimsHeader(),
            },
          },
          fetchPolicy: 'no-cache',
          variables: {
            limit,
            page,
            query,
            sort,
          },
        };
        return client.query(options);
      })
    );
  }

  addAlarmLimit(alarmLimitInput): Observable<any> {
    return from(this._api.hc2()).pipe(
      switchMap((client: any) => {
        return client.mutate({
          mutation: addAlarmLimit,
          variables: { input: alarmLimitInput },
          context: {
            headers: {
              [TC_CLAIMS_HEADER]: this._authApi.getUserClaimsHeader(),
            },
          },
        });
      })
    );
  }

  deleteAlarmLimit(id): Observable<any> {
    return from(this._api.hc2()).pipe(
      switchMap((client: any) => {
        return client.mutate({
          mutation: deleteAlarmLimit,
          variables: {
            id,
          },
          context: {
            headers: {
              [TC_CLAIMS_HEADER]: this._authApi.getUserClaimsHeader(),
            },
          },
        });
      })
    );
  }

  editAlarmLimit(id, alarmLimitInput): Observable<any> {
    return from(this._api.hc2()).pipe(
      switchMap((client: any) => {
        return client.mutate({
          mutation: editAlarmLimit,
          variables: {
            id,
            input: alarmLimitInput,
          },
          context: {
            headers: {
              [TC_CLAIMS_HEADER]: this._authApi.getUserClaimsHeader(),
            },
          },
        });
      })
    );
  }

  getAlarmLimitHistories(parentId): Observable<any> {
    return from(this._api.hc2()).pipe(
      switchMap((client: any) => {
        const options: WatchQueryOptions = {
          query: getAlarmLimitHistories,
          fetchPolicy: 'no-cache',
          variables: {
            parentId,
          },
          context: {
            headers: {
              [TC_CLAIMS_HEADER]: this._authApi.getUserClaimsHeader(),
            },
          },
        };
        return client.query(options);
      })
    );
  }

  sendAlarmLimitRequestEmail(alarmLimitRequest): Observable<any> {
    return from(this._api.hc2()).pipe(
      switchMap((client: any) => {
        return client.mutate({
          mutation: sendAlarmLimitRequestEmail,
          variables: {
            input: alarmLimitRequest,
          },
          context: {
            headers: {
              [TC_CLAIMS_HEADER]: this._authApi.getUserClaimsHeader(),
            },
          },
        });
      })
    );
  }

  getAlarmLimits(): Observable<any> {
    return from(this._api.hc2()).pipe(
      switchMap((client: any) => {
        const options: WatchQueryOptions = {
          query: getAlarmLimits,
          fetchPolicy: 'no-cache',
          variables: {},
          context: {
            headers: {
              [TC_CLAIMS_HEADER]: this._authApi.getUserClaimsHeader(),
            },
          },
        };
        return client.query(options);
      })
    );
  }

  getPIConfigurableElements(): Observable<any> {
    return from(this._api.hc2()).pipe(
      switchMap((client: any) => {
        const options: WatchQueryOptions = {
          query: getPIConfigurableElements,
          fetchPolicy: 'no-cache',
          variables: {},
          context: {
            headers: {
              [TC_CLAIMS_HEADER]: this._authApi.getUserClaimsHeader(),
            },
          },
        };
        return client.query(options);
      })
    );
  }

  getLatestPIValue(piPoint: string): Observable<PIValue> {
    return from(this._api.hc2())
      .pipe(
        switchMap((client: any) => {
          const options: WatchQueryOptions = {
            query: getLatestPIValue,
            fetchPolicy: 'no-cache',
            variables: {
              piPoint,
            },
            context: {
              headers: {
                [TC_CLAIMS_HEADER]: this._authApi.getUserClaimsHeader(),
              },
            },
          };
          return client.query(options);
        })
      )
      .pipe(
        map((data: any) => {
          return this._opBuilder.buildPIValue(data.data.getLatestPIValue);
        })
      );
  }

  getPIValuePlot(piPoint: string, hours: number = 3): Observable<PIValue[]> {
    return from(this._api.hc2())
      .pipe(
        switchMap((client: any) => {
          const options: WatchQueryOptions = {
            query: getPIValuePlot,
            fetchPolicy: 'no-cache',
            variables: {
              piPoint,
              hours,
            },
            context: {
              headers: {
                [TC_CLAIMS_HEADER]: this._authApi.getUserClaimsHeader(),
              },
            },
          };
          return client.query(options);
        })
      )
      .pipe(
        map((data: any) => {
          return data.data.getPIValuePlot.map((item) =>
            this._opBuilder.buildPIValue(item)
          );
        })
      );
  }
}

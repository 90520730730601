<div id="oplogs-all-container">
  <p-blockUI [blocked]="loading === true"></p-blockUI>
  @if (ifDeviceMatches([DeviceSize.LG, DeviceSize.XL])) {
    <div class="ui-g ui-fluid" id="contact-action-buttons">
      <div class="ui-g-12" id="oplogs-all-table-header-items">
        <div id="business-units-wrapper">
          <app-business-unit-filter (selectedBusinessUnitsChanged)="handleBusinessUnitChange($event)" #buFilter></app-business-unit-filter>
        </div>
        <div class="ui-g-1 ui-xl-1 ui-lg-3 button-wrapper ui-input-group">
          <button pButton class="width-eighty" label="{{'COMMON.LABEL.BUTTONS.CLEAR' | translate}}"
          icon="fa fa-fw fa-eraser" (click)="clearScreen()"></button>
          <button pButton class="refresh-button width-twenty" icon="fa fa-fw fa-refresh" (click)="refresh()"></button>
        </div>
      </div>
    </div>
  }
  @if (ifDeviceMatches([DeviceSize.XS, DeviceSize.SM])) {
    <p-panel [showHeader]="true" header="Filters" [toggleable]="true" [(collapsed)]="mobileFilterCollapsed"
      >
      <div class="ui-g ui-fluid">
        <div class="ui-g-12">
          <app-op-log-mobile-filter (mobileFilterEvent)="mobileFilter($event)"></app-op-log-mobile-filter>
        </div>
      </div>
    </p-panel>
  }

  <app-progress-spinner [enabled]="!elements"></app-progress-spinner>
  @if (!isMobileDevice()) {
    <div [style.display]="!elements ? 'none' : ''">
      <app-general-grid [values]="elements" [columns]="columns" [loading]="loading" [deviceSize]="getDeviceSize()"
        [rows]="rows" [totalRecords]="totalRecords" [filterable]="true" [screenName]="screenName"
        [lazy]="true" (lazyLoad)="lazyLoad($event)" [includeExportCSV]="true" [exportFilename]="exportFilename"
        (changeViewEvent)="changeViewEvent($event)" scrollHeight="calc(100vh - 256px)" [usePaginator]="true"
        [rowsPerPageOptions]="[50, 100, 200]" #grid>
      </app-general-grid>
    </div>
  }

  @if (isMobileDevice()) {
    <div [style.display]="!elements ? 'none' : ''">
      <app-general-grid [values]="elements" [columns]="columns" [loading]="loading" [deviceSize]="getDeviceSize()"
        [rows]="rows" [totalRecords]="totalRecords" [filterable]="true" [screenName]="screenName"
        [lazy]="true" (lazyLoad)="lazyLoad($event)" [includeExportCSV]="false" (changeViewEvent)="changeViewEvent($event)"
        scrollHeight="calc(100vh - 175px)" [usePaginator]="true" [rowsPerPageOptions]="[50, 100, 200]" #grid>
      </app-general-grid>
    </div>
  }
</div>

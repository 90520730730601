import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  viewChild,
  inject,
  input,
  output,
} from '@angular/core';
import { take } from 'rxjs/operators';
import { AlarmLimit } from 'src/app/model/operational-monitoring/alarm-limit';
import { AlarmLimitsTableService } from 'src/app/services/alarm-limits-table.service';
import { AuthService } from 'src/app/services/auth.service';
import { DeviceService } from 'src/app/services/device.service';
import { LoadingService } from 'src/app/services/loading.service';
import { Subscription, forkJoin, interval } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { BreadCrumbBuilderService } from 'src/app/services/breadcrumb-builder.service';
import { AdminTableService } from 'src/app/services/admin-table.service';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { AuthApiService } from 'src/app/services/auth-api.service';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { FacilityApiService } from 'src/app/services/facility-api.service';
import { LocationApiService } from 'src/app/services/location-api.service';
import { sortByProperty } from 'src/app/utils/utils';
import { OperationalMonitoringApiService } from 'src/app/services/operational-monitoring-api.service';
import { LogAndMessageService } from 'src/app/services/log-and-message.service';
import { Facility } from 'src/app/model/locations/facility';
import { isNullOrUndefined } from 'util';
import { AlarmLimitAuditTrailComponent } from '../../components/alarm-limit-audit-trail/alarm-limit-audit-trail.component';
import moment from 'moment-mini';
import { CLAIMS } from 'src/app/constants/auth-constants';

@Component({
  selector: 'app-alarm-limits-detail-container',
  templateUrl: './alarm-limits-detail-container.component.html',
  styleUrls: ['./alarm-limits-detail-container.component.scss'],
  standalone: false,
})
export class AlarmLimitsDetailContainerComponent {
  protected _deviceService = inject(DeviceService);
  protected _loader = inject(LoadingService);
  protected _auth = inject(AuthService);
  protected _cdRef = inject(ChangeDetectorRef);
  protected _tableService = inject(AlarmLimitsTableService);
  private _translateService = inject(TranslateService);
  private _breadCrumbBuilderService = inject(BreadCrumbBuilderService);
  protected _adminTableService = inject(AdminTableService);
  private _authApi = inject(AuthApiService);
  private _facilityApi = inject(FacilityApiService);
  protected _locationApi = inject(LocationApiService);
  protected _opMonitoringApi = inject(OperationalMonitoringApiService);
  protected _logAndMessage = inject(LogAndMessageService);
  private _confirmationService = inject(ConfirmationService);

  // TODO: Skipped for migration because:
  //  Your application code writes to the input. This prevents migration.
  @Input() isVisible: boolean = false;
  readonly formType = input<string>('Create');
  readonly selectedAlarmLimit = input<AlarmLimit>(undefined);

  readonly hideForm = output();
  readonly formSubmitted = output();
  readonly alarmLimitDeleted = output<string>();
  readonly formIsReady = output();

  readonly auditTrail = viewChild.required<AlarmLimitAuditTrailComponent>(
    'alarmLimitAuditTrail'
  );

  dialogHeader: string;
  refreshSubscription: Subscription;
  availableActions: SelectItem[] = [];
  selectedAction: SelectItem;
  showSuccessMessage = true;

  deleteMsg: string;
  okBtnLabel: string;
  cancelBtnLabel: string;
  deleteHeader: string;

  canCreate: boolean = false;
  canEditAll: boolean = false;
  canEditOwn: boolean = false;
  canEditDirectReports: boolean = false;
  enableCanEdit: boolean = false;
  loading: boolean = true;
  saving: boolean = false;

  locations: any[];
  facilities: Facility[] = [];
  facilityNames: string[];
  limitedAccessFacilities: Facility[] = [];

  minDateTime = new Date();
  auditTrailVisible: boolean = false;

  datatypes: string[] = [];

  alarmLimitForm = new UntypedFormGroup({
    facility: new UntypedFormControl('', Validators.required),
    alarmAttributeName: new UntypedFormControl('', Validators.required),
    assetIdentifier: new UntypedFormControl('', Validators.required),
    limitValue: new UntypedFormControl('', Validators.required),
    effectiveDateTime: new UntypedFormControl(new Date(), Validators.required),
    updater: new UntypedFormControl({ value: '', disabled: true }),
    updatedAt: new UntypedFormControl({ value: '', disabled: true }),
    updateReason: new UntypedFormControl('', Validators.required),
    datatype: new UntypedFormControl(null, Validators.required),
  });

  ngOnDestroy(): void {}

  ngOnInit() {
    this.canEditAll = this._authApi.doesUserHaveAllClaimsFromList([
      CLAIMS.FIELD_OPERATIONS.ALARM_LIMITS.CHANGE_ALL_ALARM_LIMITS,
    ]);
    this.canEditOwn = this._authApi.doesUserHaveAtLeastOneClaimFromList([
      CLAIMS.FIELD_OPERATIONS.ALARM_LIMITS.CHANGE_OWN_ALARM_LIMITS,
    ]);
    this.canEditDirectReports =
      this._authApi.doesUserHaveAtLeastOneClaimFromList([
        CLAIMS.FIELD_OPERATIONS.ALARM_LIMITS.CHANGE_DIRECT_REPORTS_ALARM_LIMITS,
      ]);
    this.prepareForm();
    this.setupLabels();
  }

  prepareForm() {
    this.loadFacilities();
    this.alarmLimitForm.disable();
    this.datatypes = ['Boolean', 'DateTime', 'Double', 'String'];
  }

  onDialogHide($event: any) {
    this.hideForm.emit();

    setTimeout(() => {
      this.alarmLimitForm.disable();
      this.alarmLimitForm.markAsPending();
      this.selectedAction = null;
      this.enableCanEdit = false;

      this.facilities = this.facilities.filter((fac) => {
        return this.limitedAccessFacilities.every(
          (limitedFac) => limitedFac.id !== fac.id
        );
      });

      this.limitedAccessFacilities = [];
      this.alarmLimitForm.patchValue({
        facility: null,
        assetIdentifier: null,
        alarmAttributeName: null,
        limitValue: null,
        effectiveDateTime: new Date(),
        updater: null,
        updatedAt: null,
        updateReason: null,
        datatype: null,
      });
    });
  }

  fillActionsDropdown() {
    this.availableActions = [];

    // always show placeholder and  audit trail,
    // because if they can make it to this page, they have perms
    this.availableActions.push({
      label: 'Select Action',
      value: 'select-action',
    });
    this.availableActions.push({
      label: 'View Audit Info',
      value: 'view-audit-info',
    });

    if (this.canEditAll || this.enableCanEdit) {
      this.availableActions.push({
        label: 'Delete Configuration',
        value: 'delete-alarm-limit',
      });
      this.enableForm();
    }
  }

  loadFacilities() {
    if (this.canEditAll) {
      this._facilityApi
        .getFacilities()
        .pipe(take(1))
        .subscribe(
          (data) => {
            if (data.length === 0) {
              setTimeout(() => {
                this.loadFacilities();
              }, 1000);
              return;
            }
            this.facilities = data;
            this.sortFacilitiesDropdown();
          },
          (error) => console.error(error)
        );
    } else {
      this._facilityApi
        .getUserFacilities(this._auth.getEmail(), this.canEditDirectReports)
        .pipe(take(1))
        .subscribe(
          ({ data }) => {
            this.facilities = data.getUserFacilities;
            this.sortFacilitiesDropdown();
          },
          (error) => console.error(error)
        );
    }
  }

  sortFacilitiesDropdown() {
    this.facilities.sort((a, b) => sortByProperty(a, b, 'facilityName'));
    this.loading = false;
    this.formIsReady.emit();
  }

  onFacilityChanged($event: any) {
    this.alarmLimitForm.patchValue({
      facility: $event.value,
    });
  }

  updateFields() {
    const selectedAlarmLimit = this.selectedAlarmLimit();
    if ((this.canEditOwn || this.canEditDirectReports) && !this.canEditAll) {
      if (
        !isNullOrUndefined(
          this.facilities.find(
            (fac) => fac.id === this.selectedAlarmLimit().facilityId
          )
        )
      ) {
        this.enableCanEdit = true;
      } else {
        this.facilities.push(selectedAlarmLimit.Facility);
        this.limitedAccessFacilities.push(selectedAlarmLimit.Facility);
      }
    }

    this.alarmLimitForm.patchValue({
      facility: this.facilities.find(
        (fac) => fac.id === this.selectedAlarmLimit().facilityId
      ),
      assetIdentifier: selectedAlarmLimit.assetIdentifier,
      alarmAttributeName: selectedAlarmLimit.alarmAttributeName,
      limitValue: selectedAlarmLimit.value,
      updater: selectedAlarmLimit.updater,
      updatedAt: selectedAlarmLimit.updatedAt.toLocaleString(),
      effectiveDateTime: selectedAlarmLimit.effectiveDateTime,
      datatype: selectedAlarmLimit.datatype,
    });

    this.fillActionsDropdown();
  }

  onActionSelected($event) {
    let action = $event.value;
    if (action === 'delete-alarm-limit') {
      this.deleteAlarmLimit();
    }

    if (action === 'view-audit-info') {
      this.auditTrail().getHistories();
      this.auditTrailVisible = true;
    }
  }

  onSubmit($event) {
    if (!this.alarmLimitForm.valid) {
      console.log('Form was somehow submitted without being valid!');
      return;
    }

    if (!this.validateDatatypeAndValue()) {
      this._logAndMessage.translateToErrorMessage({
        bodyKey: 'OPMONITORING.MESSAGES.ERROR.DATATYPE_VALUE_MISMATCH',
        headerKey: 'COMMON.MESSAGES.HEADERS.ERROR',
      });
      return;
    }

    const formType = this.formType();
    if (formType === 'Create') {
      this.addAlarmLimit();
    }

    if (formType === 'Edit') {
      this.editAlarmLimit();
    }
  }

  setupLabels(): void {
    const deleteMsg$ = this._translateService.get(
      'OPMONITORING.MESSAGES.CONFIRMATION.DELETE_ALARM_LIMIT'
    );
    const ok$ = this._translateService.get('COMMON.LABEL.BUTTONS.YES');
    const cancel$ = this._translateService.get('COMMON.LABEL.BUTTONS.NO');
    const header$ = this._translateService.get(
      'OPMONITORING.MESSAGES.HEADERS.DELETE_ALARM_LIMIT'
    );
    forkJoin([deleteMsg$, ok$, cancel$, header$]).subscribe((messages) => {
      this.deleteMsg = messages[0];
      this.okBtnLabel = messages[1];
      this.cancelBtnLabel = messages[2];
      this.deleteHeader = messages[3];
    });
  }

  addAlarmLimit() {
    this.saving = true;
    let addSubmittedAlarm = this.alarmLimitForm.value;

    let newAlarmLimit = {
      facilityId: addSubmittedAlarm.facility.id,
      assetIdentifier: addSubmittedAlarm.assetIdentifier,
      alarmAttributeName: addSubmittedAlarm.alarmAttributeName,
      value: addSubmittedAlarm.limitValue,
      effectiveDateTime: addSubmittedAlarm.effectiveDateTime,
      updatedAt: new Date(),
      updateReason: addSubmittedAlarm.updateReason,
      datatype: addSubmittedAlarm.datatype,
    };

    this._opMonitoringApi
      .addAlarmLimit(newAlarmLimit)
      .pipe(take(1))
      .subscribe(
        ({ data }) => {
          if (data.addAlarmLimit.id) {
            this.saving = true;
            this.isVisible = false;
            let createdAlarmLimit = data.addAlarmLimit;
            this.formSubmitted.emit({
              ...addSubmittedAlarm,
              id: createdAlarmLimit.id,
            });
          } else {
            console.warn('Succeeded without getting an id back?');
            this.isVisible = false;
          }
        },
        (error) => {
          console.error(error);
          this.saving = false;
          this._logAndMessage.errorLogOnly(error);
          this._logAndMessage.translateToErrorMessage({
            bodyKey: 'OPMONITORING.MESSAGES.ERROR.CREATE_ALARM_LIMITS',
            headerKey: 'COMMON.MESSAGES.HEADERS.ERROR',
          });
        },
        () => {
          this.saving = false;
          if (this.showSuccessMessage) {
            this.showSuccessMessage = false;
            this._logAndMessage.translateToSuccessMessage({
              bodyKey: 'OPMONITORING.MESSAGES.SUCCESS.CREATE_ALARM_LIMITS',
              headerKey: 'COMMON.MESSAGES.HEADERS.SUCCESS',
            });
          }
        }
      );
  }

  deleteAlarmLimit() {
    let deletingSchedule = this.selectedAlarmLimit();
    this._confirmationService.confirm({
      message: this.deleteMsg,
      accept: () => {
        this.saving = true;
        this._opMonitoringApi
          .deleteAlarmLimit(deletingSchedule.id ?? '')
          .pipe(take(1))
          .subscribe(
            ({ data }) => {
              this.isVisible = false;
              this.alarmLimitDeleted.emit(deletingSchedule.id);
            },
            (error) => {
              console.error(error);
              this.saving = false;
              this._logAndMessage.errorLogOnly(error);
              this._logAndMessage.translateToErrorMessage({
                bodyKey: 'OPMONITORING.MESSAGES.ERROR.DELETE_ALARM_LIMIT',
                headerKey: 'COMMON.MESSAGES.HEADERS.ERROR',
              });
            },
            () => {
              this.saving = false;
              if (this.showSuccessMessage) {
                this.showSuccessMessage = false;
                this._logAndMessage.translateToSuccessMessage({
                  bodyKey: 'OPMONITORING.MESSAGES.SUCCESS.DELETE_ALARM_LIMIT',
                  headerKey: 'COMMON.MESSAGES.HEADERS.SUCCESS',
                });
              }
            }
          );
      },
    });
  }

  editAlarmLimit() {
    this.saving = true;
    let editingAlarm = this.alarmLimitForm.value;

    let updatedAlarmLimit = {
      facilityId: editingAlarm.facility.id,
      assetIdentifier: editingAlarm.assetIdentifier,
      alarmAttributeName: editingAlarm.alarmAttributeName,
      value: editingAlarm.limitValue,
      effectiveDateTime: editingAlarm.effectiveDateTime,
      updatedAt: new Date(),
      updateReason: editingAlarm.updateReason,
      datatype: editingAlarm.datatype,
    };

    this._opMonitoringApi
      .editAlarmLimit(this.selectedAlarmLimit().id, updatedAlarmLimit)
      .pipe(take(1))
      .subscribe(
        ({ data }) => {
          if (data.editAlarmLimit.id) {
            this.isVisible = false;
            let editedAlarmLimit = data.editAlarmLimit;
            this.formSubmitted.emit({
              ...editingAlarm,
              id: this.selectedAlarmLimit().id,
            });
            this.saving = false;
          }
        },
        (error) => {
          console.error(error);
          this.saving = false;
          this._logAndMessage.errorLogOnly(error);
          this._logAndMessage.translateToErrorMessage({
            bodyKey: 'OPMONITORING.MESSAGES.ERROR.EDIT_ALARM_LIMIT',
            headerKey: 'COMMON.MESSAGES.HEADERS.ERROR',
          });
        },
        () => {
          this.saving = false;
          if (this.showSuccessMessage) {
            this.showSuccessMessage = false;
            this._logAndMessage.translateToSuccessMessage({
              bodyKey: 'OPMONITORING.MESSAGES.SUCCESS.EDIT_ALARM_LIMIT',
              headerKey: 'COMMON.MESSAGES.HEADERS.SUCCESS',
            });
          }
        }
      );
  }

  onAuditTrailHidden() {
    this.auditTrailVisible = false;
  }

  resetEffectiveDate() {
    this.alarmLimitForm.patchValue({
      effectiveDateTime: new Date(),
    });
  }

  enableForm() {
    this.alarmLimitForm.enable();
    this.alarmLimitForm.get('updater').disable();
    this.alarmLimitForm.get('updatedAt').disable();
  }

  validateDatatypeAndValue(): boolean {
    let selectedDatatype: string = this.alarmLimitForm.get('datatype').value;
    let valueInput: string = this.alarmLimitForm.get('limitValue').value;

    switch (selectedDatatype) {
      case 'String':
        return true; // Nothing to validate for strings
      case 'Double':
        const regex = /^-*\d+(.{1}\d+)*$/;
        return regex.test(valueInput);
      case 'DateTime':
        return moment(valueInput).isValid();
      case 'Boolean':
        return (
          valueInput.toLowerCase() === 'false' ||
          valueInput.toLowerCase() === 'true'
        );
    }
    return false;
  }
}

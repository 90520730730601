import { Component, inject, OnDestroy, OnInit, signal, WritableSignal } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DialogService, DynamicDialogModule, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CoreModule } from 'src/app/core/core.module';
import { SearchContainerComponent } from 'src/app/core/containers/search-container/search-container.component';
import { firstValueFrom, forkJoin, Subscription } from 'rxjs';
import { PlcAlarmCode, PlcAlarmCodeSortOrQuery } from 'src/app/model/station-logs/plc-alarm-code';
import { AdminTableService } from 'src/app/services/admin-table.service';
import { AuthApiService } from 'src/app/services/auth-api.service';
import { StationLogApiService } from 'src/app/services/station-log-api.service';
import { LogAndMessageService } from 'src/app/services/log-and-message.service';
import { TabService } from 'src/app/core/services/tab.service';
import { TROUBLESHOOTING_SEARCH_TABLE_DEFINITION } from 'src/app/operational-monitoring/definitions/troubleshooting-search-table-definition';
import { isNullOrUndefined } from 'src/app/utils/utils';
import { StationLogTableService } from 'src/app/services/station-log-table.service';
import { TroubleshootingDialogComponent } from 'src/app/operational-monitoring/components/troubleshooting-dialog/troubleshooting-dialog/troubleshooting-dialog.component';
import { CLAIMS } from 'src/app/constants/auth-constants';


@Component({
  selector: 'app-troubleshooting-search-container',
  imports: [CoreModule, TranslateModule, DynamicDialogModule],
  providers: [DialogService],
  templateUrl: './troubleshooting-search-container.component.html',
  styleUrl: './troubleshooting-search-container.component.scss'
})
export class TroubleshootingSearchContainerComponent
  extends SearchContainerComponent<PlcAlarmCode>
  implements OnInit, OnDestroy
{
  protected _adminTableService = inject(AdminTableService);
  protected _translateService = inject(TranslateService);
  protected _authApi = inject(AuthApiService);
  protected _stationLogApi = inject(StationLogApiService);
  protected _logAndMessage = inject(LogAndMessageService);
  protected dialogService = inject(DialogService);
  protected _tableService = inject(StationLogTableService);

  ref: DynamicDialogRef | undefined;
  pageLoading = signal(true);
  alarmCodes: WritableSignal<PlcAlarmCode[]> = signal([])
  canCreate = signal(false);

  activeTabChangedSub: Subscription;

  ngOnInit(): void {
    this._adminTableService.setLastLazyLoad(undefined);
    this.setTableService(this._adminTableService);
    this.columns = TROUBLESHOOTING_SEARCH_TABLE_DEFINITION;

    const screenName$ = this._translateService.get(
      'OPMONITORING.SCREEN.TROUBLESHOOTING'
    );
    forkJoin([screenName$]).subscribe((label) => {
      TabService.getInstance().updateActiveTabLabel(label[0]);
      this.screenName = label[0];
    });

    this.canCreate.set(this._authApi.doesUserHaveClaim(CLAIMS.FIELD_OPERATIONS.PLC_ALARM_CODES.CREATE_PLC_ALARM_CODES));

    this.applyPreviousData();
    this.applyPreviousFilters();
  }

  ngOnDestroy(): void {
    if (this.activeTabChangedSub) {
      this.activeTabChangedSub.unsubscribe();
    }
  }

  handleLazyLoad(req: any, $event: any, filters: any): void {
    this._adminTableService.setLastAdminLazyLoad(this.screenName, $event.lazy);
    const query: PlcAlarmCodeSortOrQuery = {
      alarmCode: null,
      alarmComment: null,
      alarmExplanation: null,
      alarmFix: null,
    };

    if (filters.alarmCode) {
      query.alarmCode = filters.alarmCode.value;
    }
    if (filters.alarmComment) {
      query.alarmComment = filters.alarmComment.value;
    }
    if (filters.alarmExplanation) {
      query.alarmExplanation = filters.alarmExplanation.value;
    }
    if (filters.alarmFix) {
      query.alarmFix = filters.alarmFix.value;
    }

    this.queryNetwork(req, $event, query);
  }

  queryNetwork(req, $event, query): void {
    req.page += 1;
    const sort = this.buildNetworkSortQuery($event, 'alarmCode');
    this.pageLoading.set(true);
    this._stationLogApi
      .queryForPlcAlarmCodes(req.page, req.pageSize, query, sort)
      .subscribe({
        next: (response) => {
          this.alarmCodes.set(response.items);
          this.totalRecords = response.totalRecords;
          this.elements = [...this.alarmCodes()];
          this.pageLoading.set(false);
        },
        error: (error) => {
          this.pageLoading.set(false);
          this._logAndMessage.errorLogOnly(error);
          this._logAndMessage.translateToErrorMessage({
            bodyKey: 'OPMONITORING.MESSAGES.ERROR.QUERY_PLC_ALARM_CODES',
            headerKey: 'COMMON.MESSAGES.HEADERS.ERROR',
          });
        }
      });
  }

  async changeViewEvent($event: { data: PlcAlarmCode }): Promise<void> {
    const dialogEditHeader = await firstValueFrom(
      this._translateService.get('OPMONITORING.MESSAGES.HEADERS.EDIT_PLC_ALARM_CODE')
    );
    this.ref = this.dialogService.open(TroubleshootingDialogComponent, {
      header: dialogEditHeader,
      dismissableMask: true,
      data: {
        alarmCode: $event.data,
        formType: 'Edit',
      },
    });
    this.ref.onClose.subscribe((data) => {
      if (!data) {
        // If there's no data provided in the onclose, we didn't actually do anything to the part
        // so we don't need to refresh the page
        return;
      }
      this.refresh();
    });
  }

  refresh(): void {
    this.lazyLoad({ lazy: this._adminTableService.getLastLazyLoad() });
  }

  clearScreen() {
    this.lazyLoad({ lazy: {} });
    this.grid().resetTable();
    this._tableService.clearResults();
    this.elements = [];
  }

  async newAlarmCode(): Promise<void> {
    const dialogCreateHeader = await firstValueFrom(
      this._translateService.get('OPMONITORING.MESSAGES.HEADERS.CREATE_PLC_ALARM_CODE')
    );
    this.ref = this.dialogService.open(TroubleshootingDialogComponent, {
      header: dialogCreateHeader,
      dismissableMask: true,
      data: {
        alarmCode: null,
        formType: 'Create',
      },
    });
    this.ref.onClose.subscribe((data) => {
      if (!data) {
        // If there's no data provided in the onclose, we didn't actually do anything to the alarm code
        // so we don't need to refresh the page
        return;
      }
      this.refresh();
    });
  }

  setupActiveTabChangedSubscription(): void {
    this.activeTabChangedSub =
      TabService.getInstance().activeTabChanged.subscribe((tab) => {
        if (!isNullOrUndefined(tab) && tab.header === this.screenName) {
          this._adminTableService.setLastLazyLoad(
            this._adminTableService.getLastAdminLazyLoad(this.screenName)
          );
          this.refresh();
        }
      });
  }
}

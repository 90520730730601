import { gql } from '@apollo/client/core';

export const createPart = gql`
  mutation createPart($input: CreateOrEditPart) {
    createPart(input: $input) {
      id
      sapNumber
      pciNumber
      description
      vendorPartNumber
      manufacturer
      storeroom
      binLocation
      createdBy
      createdAt
      updater
      updatedAt
      deletedAt
    }
  }
`;

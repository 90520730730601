import { Injectable, inject } from '@angular/core';
import { BaseAppSyncService } from './base-app-sync.service';
import { AppsyncService } from './appsync.service';
import { Observable } from 'rxjs';
import { AuthApiService } from './auth-api.service';
import {
  CreateOrEditStationLog,
  StationLog,
  StationLogConnection,
} from '../model/station-logs/station-log';
import { StationLogReason } from '../model/station-logs/station-log-reason';
import { getStationLogReasons } from '../graphql/station-logs/get-station-log-reasons';
import { StationLogBuilderService } from './station-log-builder.service';
import { queryForStationLogs } from '../graphql/station-logs/query-for-station-logs';
import { getStationLog } from '../graphql/station-logs/get-station-log';
import { createStationLog } from '../graphql/station-logs/create-station-log';
import { editStationLog } from '../graphql/station-logs/edit-station-log';
import { deleteStationLog } from '../graphql/station-logs/delete-station-log';
import {
  CreateOrEditPart,
  Part,
  PartConnection,
  PartSortOrQuery,
} from '../model/station-logs/part';
import { createPart } from '../graphql/station-logs/create-part';
import { editPart } from '../graphql/station-logs/edit-part';
import { deletePart } from '../graphql/station-logs/delete-part';
import { queryForParts } from '../graphql/station-logs/query-for-parts';
import {
  CreateOrEditPlcAlarmCode, PlcAlarmCode, PlcAlarmCodeConnection,
  PlcAlarmCodeSortOrQuery,
} from '../model/station-logs/plc-alarm-code';
import { queryForPlcAlarmCodes } from '../graphql/station-logs/query-for-plc-alarm-codes';
import { Audit } from '../model/common/audit';
import { getStationLogHistory } from '../graphql/station-logs/get-station-log-history';
import { buildAuditHistory } from '../core/functions/common-functions';
import { createPlcAlarmCode } from '../graphql/station-logs/create-plc-alarm-code';
import { editPlcAlarmCode } from '../graphql/station-logs/edit-plc-alarm-code';
import { deletePlcAlarmCode } from '../graphql/station-logs/delete-plc-alarm-code';

@Injectable({
  providedIn: 'root',
})
export class StationLogApiService extends BaseAppSyncService {
  protected _api: AppsyncService;
  protected _authApi = inject(AuthApiService);
  private _stationLogBuilder = inject(StationLogBuilderService);

  constructor() {
    const _api = inject(AppsyncService);

    super(_api);
    this._api = _api;
  }

  getStationLogReasons(): Observable<StationLogReason[]> {
    return this.query(
      getStationLogReasons,
      {},
      (items: any[]) =>
        items.map((i) => this._stationLogBuilder.buildStationLogReason(i)),
      'getStationLogReasons'
    );
  }

  queryForStationLogs(
    limit: number,
    page: number,
    query: any,
    sort: any
  ): Observable<StationLogConnection> {
    return this.query(
      queryForStationLogs,
      { limit, page, query, sort },
      (response: any) => {
        return {
          items: response.items.map((i) =>
            this._stationLogBuilder.buildStationLog(i)
          ),
          totalRecords: response.totalRecords,
        };
      },
      'queryForStationLogs'
    );
  }

  getStationLog(logId: string): Observable<StationLog> {
    return this.query(
      getStationLog,
      { logId },
      (item: any) => this._stationLogBuilder.buildStationLog(item),
      'getStationLog'
    );
  }

  createStationLog(input: CreateOrEditStationLog): Observable<StationLog> {
    return this.mutate(
      createStationLog,
      { input },
      (i) => this._stationLogBuilder.buildStationLog(i),
      'createStationLog'
    );
  }

  editStationLog(input: CreateOrEditStationLog): Observable<StationLog> {
    return this.mutate(
      editStationLog,
      { input },
      (i) => this._stationLogBuilder.buildStationLog(i),
      'editStationLog'
    );
  }

  deleteStationLog(id: string): Observable<any> {
    return this.mutate(
      deleteStationLog,
      { id },
      (i) => i.value,
      'deleteStationLog'
    );
  }

  getStationLogHistory(id: string): Observable<Audit[]> {
    return this.query(
      getStationLogHistory,
      { id },
      (i) => buildAuditHistory(i),
      'getStationLogHistory'
    );
  }

  createPart(input: CreateOrEditPart): Observable<Part> {
    return this.mutate(
      createPart,
      { input },
      (i) => this._stationLogBuilder.buildPart(i),
      'createPart'
    );
  }

  editPart(id: string, input: CreateOrEditPart): Observable<Part> {
    return this.mutate(
      editPart,
      { id, input },
      (i) => this._stationLogBuilder.buildPart(i),
      'editPart'
    );
  }

  deletePart(id: String): Observable<any> {
    return this.mutate(deletePart, { id }, (i) => i, 'deletePart');
  }

  queryForParts(
    page: number,
    limit: number,
    query: PartSortOrQuery,
    sort: PartSortOrQuery
  ): Observable<PartConnection> {
    return this.query(
      queryForParts,
      { page, limit, query, sort },
      (response: any) => {
        return {
          items: response.items.map((value) =>
            this._stationLogBuilder.buildPart(value)
          ),
          totalRecords: response.totalRecords,
        };
      },
      'queryForParts'
    );
  }

  queryForPlcAlarmCodes(
    page: number,
    limit: number,
    query: PlcAlarmCodeSortOrQuery,
    sort: PlcAlarmCodeSortOrQuery
  ): Observable<PlcAlarmCodeConnection> {
    return this.query(
      queryForPlcAlarmCodes,
      { page, limit, query, sort },
      (response: any) => {
        return {
          items: response.items.map((value) =>
            this._stationLogBuilder.buildPlcAlarmCode(value)
          ),
          totalRecords: response.totalRecords,
        };
      },
      'queryForPlcAlarmCodes'
    );
  }

  createPlcAlarmCode(input: CreateOrEditPlcAlarmCode): Observable<PlcAlarmCode> {
    return this.mutate(
      createPlcAlarmCode,
      { input },
      (i) => this._stationLogBuilder.buildPlcAlarmCode(i),
      'createPlcAlarmCode'
    );
  }

  editPlcAlarmCode(id: string, input: CreateOrEditPlcAlarmCode): Observable<PlcAlarmCode> {
    return this.mutate(
      editPlcAlarmCode,
      { id, input },
      (i) => this._stationLogBuilder.buildPlcAlarmCode(i),
      'editPlcAlarmCode'
    );
  }

  deletePlcAlarmCode(id: String): Observable<any> {
    return this.mutate(deletePlcAlarmCode, { id }, (i) => i, 'deletePlcAlarmCode');
  }
}

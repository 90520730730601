<app-progress-spinner [enabled]="!opAlertLog"></app-progress-spinner>

@if (opAlertLog) {
  <div
    id="printMe"
    style="background-color: white; width: 800px">
    <div class="ui-g ui-fluid">
      <div class="ui-g-2">
        <button pButton
          label="Print"
          class="small-margin"
        (click)="print()"></button>
      </div>
      <div class="ui-g-12 no-padding-top-bottom ">
        @if (opAlertLog.id) {
          <div class="ui-g-12 no-padding-top-bottom "
            >
            <h3>{{opAlertLog?.opLogId}}</h3>
            <span class="ui-g-4 label ">{{'OPMONITORING.LABEL.CREATED_BY' | translate}}:</span>
            <span class="ui-g-8 free-text-value ">{{opAlertLog.createdBy}} on
            {{opAlertLog.createdAt | date:'MM/dd/yyyy hh:mm:ss a'}}</span>
            <span class="ui-g-4  label ">{{'OPMONITORING.LABEL.LAST_UPDATED' | translate}}:</span>
            <span class="ui-g-8 free-text-value ">{{opAlertLog.updatedAt | date:'MM/dd/yyyy hh:mm:ss a'}}</span>
          </div>
        }
        @if (opAlertLog.id) {
          <div class="ui-g-12 no-padding-top-bottom "
            >
            <span class="ui-g-4 label ">{{'OPMONITORING.LABEL.CURRENT_STATUS' | translate}}:</span>
            @if (opAlertLog.opLogStatus === 'N') {
              <span class="ui-g-8 free-text-value "
              >{{'OPMONITORING.LABEL.NEW' | translate}}</span>
            }
            @if (opAlertLog.opLogStatus === 'A') {
              <span class="ui-g-8 free-text-value "
              >{{'OPMONITORING.LABEL.ACKNOWLEDGED' | translate}}</span>
            }
          </div>
        }
        <br />
      </div>
      @if (opAlertLog.id) {
        <div class="ui-g-12 no-padding-top-bottom "
          >
          <h5>{{'OPMONITORING.LABEL.LOCATION_INFO' | translate}}</h5>
          <div class="ui-g-12 no-padding-top-bottom no-padding">
            <div class="ui-g-12 no-padding-top-bottom no-padding">
              <div class="ui-g-12 no-padding-top-bottom">
                <span class="ui-g-4 label">{{'LOCATION.LABEL.STATE' | translate}}:</span>
                <span class="ui-g-8">{{opAlertLog?.state}}</span>
              </div>
              <div class="ui-g-12 no-padding-top-bottom">
                <span class="ui-g-4 label">{{'LOCATION.LABEL.COUNTY' | translate}}:</span>
                <span class="ui-g-8">{{opAlertLog?.county}}</span>
              </div>
              <div class="ui-g-12 no-padding-top-bottom">
                <span class="ui-g-4 label">{{'LOCATION.LABEL.TOWNSHIP' | translate}}:</span>
                <span class="ui-g-8">{{opAlertLog?.township}}</span>
              </div>
            </div>
          </div>
        </div>
      }
      @if (opAlertLog.id) {
        <div class="ui-g-12 no-padding-top-bottom "
          >
          <h5>{{'OPMONITORING.LABEL.FACILITY_INFO' | translate}}</h5>
          <div class="ui-g-12 no-padding-top-bottom ">
            <span class="ui-g-4 label">{{'LOCATION.LABEL.FACILITY_TYPE' | translate}}:</span>
            <span class="ui-g-8">{{opAlertLog?.Facility?.FacilityType?.name}}</span>
          </div>
          <div class="ui-g-12 no-padding-top-bottom ">
            <span class="ui-g-4 label">{{'LOCATION.LABEL.FACILITY_NAME' | translate}}:</span>
            <span class="ui-g-8">{{opAlertLog?.Facility?.facilityName}}</span>
          </div>
          <div class="ui-g-12 no-padding-top-bottom ">
            <span class="ui-g-4 label">{{'LOCATION.LABEL.FACILITY_UID' | translate}}:</span>
            <span class="ui-g-8">{{opAlertLog?.Facility?.facilityUid}}</span>
          </div>
          <div class="ui-g-12 no-padding-top-bottom ">
            <span class="ui-g-4 label">{{'LOCATION.LABEL.AOR' | translate}}:</span>
            <span class="ui-g-8">{{opAlertLog?.Facility?.aor}}</span>
          </div>
        </div>
      }
      @if (opAlertLog.id) {
        <div class="ui-g-12 no-padding-top-bottom "
          >
          <h5>{{'OPMONITORING.LABEL.ENTRIES' | translate }}</h5>
          @for (entry of opAlertLog.OpAlertLogAlarms; track entry) {
            <div class="ui-g-12 no-padding-top-bottom"
              >
              <strong>{{ entry.time | date:'MM/dd/yyyy hh:mm:ss a'}} - {{entry.user}}:</strong> <span [innerHTML]="entry.alarm" class="printMeToo"></span>
            </div>
          }
        </div>
      }
      @if (opAlertLog.id) {
        <div class="ui-g-12 no-padding-top-bottom "
          >
          <h5>{{'OPMONITORING.LABEL.ACTIONS_TAKEN' | translate}}</h5>
          @for (entry of opAlertLog.OpAlertLogEntries; track entry) {
            <div class="ui-g-12 no-padding-top-bottom"
              >
              <strong>{{ entry.time | date:'MM/dd/yyyy hh:mm:ss a'}} - {{entry.user}}:</strong> <span [innerHTML]="entry.entry" class="printMeToo"></span>
            </div>
          }
        </div>
      }
      @if (opAlertLog.id) {
        <div class="ui-g-12 no-padding-top-bottom "
          >
          <h5>{{'OPMONITORING.LABEL.NOTIFICATIONS' | translate }}</h5>
          @for (a of opAlertLog.OpAlertLogNotifications; track a) {
            <div class="ui-g-12 no-padding-top-bottom"
              >
              <strong>{{a.time | date: 'yyyy-MM-dd HH:mm:ss'}} - {{a.user}}:</strong> {{a.note}}
            </div>
          }
          <br />
        </div>
      }
    </div>
  </div>
}

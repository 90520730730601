import {
  Component,
  inject,
  OnInit,
  signal,
  WritableSignal,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { Dropdown, DropdownChangeEvent } from 'primeng/dropdown';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { firstValueFrom, forkJoin } from 'rxjs';
import { CLAIMS } from 'src/app/constants/auth-constants';
import { CoreModule } from 'src/app/core/core.module';
import { Part } from 'src/app/model/station-logs/part';
import { AuthApiService } from 'src/app/services/auth-api.service';
import { LogAndMessageService } from 'src/app/services/log-and-message.service';
import { StationLogApiService } from 'src/app/services/station-log-api.service';
import { StationLogBuilderService } from 'src/app/services/station-log-builder.service';

@Component({
  selector: 'app-parts-dialog',
  imports: [ReactiveFormsModule, CoreModule, TranslateModule],
  providers: [DialogService],
  templateUrl: './parts-dialog.component.html',
  styleUrl: './parts-dialog.component.scss',
})
export class PartsDialogComponent implements OnInit {
  config = inject(DynamicDialogConfig);
  fb = inject(FormBuilder);
  authApi = inject(AuthApiService);
  stationLogBuilder = inject(StationLogBuilderService);
  stationLogApi = inject(StationLogApiService);
  ref = inject(DynamicDialogRef);
  logAndMessage = inject(LogAndMessageService);
  confirmationService = inject(ConfirmationService);
  translateService = inject(TranslateService);

  part: WritableSignal<Part> = signal(null);
  canCreate = signal(false);
  canEdit = signal(false);
  formType: 'Create' | 'Edit';
  form: FormGroup;
  formWorking = signal(false);
  actions: SelectItem[] = [];

  ngOnInit(): void {
    this.form = this.fb.group({
      sapNumber: [null, Validators.required],
      pciNumber: [null, Validators.required],
      description: [null],
      vendorPartNumber: [null],
      manufacturer: [null],
      storeroom: [null],
      binLocation: [null],
    });

    this.canCreate.set(this.authApi.doesUserHaveClaim(CLAIMS.FIELD_OPERATIONS.PARTS_SEARCH.CREATE_PARTS));
    this.canEdit.set(this.authApi.doesUserHaveClaim(CLAIMS.FIELD_OPERATIONS.PARTS_SEARCH.EDIT_PARTS));
    if (this.authApi.doesUserHaveClaim(CLAIMS.FIELD_OPERATIONS.PARTS_SEARCH.DELETE_PARTS)) {
      this.actions.push({ label: 'Delete', value: 'delete' });
    }

    this.part.set(this.config?.data?.part);
    this.formType = this.config.data.formType;

    this.updateForm();
  }

  updateForm(): void {
    const currentPart = this.part();
    this.form.patchValue({
      sapNumber: currentPart?.sapNumber,
      pciNumber: currentPart?.pciNumber,
      description: currentPart?.description,
      vendorPartNumber: currentPart?.vendorPartNumber,
      manufacturer: currentPart?.manufacturer,
      storeroom: currentPart?.storeroom,
      binLocation: currentPart?.binLocation,
    });
    this.form.markAsPristine();

    if (!this.canEdit()) {
      this.form.disable();
    }
  }

  save(): void {
    if (this.formType === 'Create') {
      this.createPart();
    } else if (this.formType === 'Edit') {
      this.editPart();
    } else {
      this.logAndMessage.translateToErrorAlert({
        headerKey: 'COMMON.MESSAGES.HEADERS.ERROR',
        bodyKey: 'OPMONITORING.MESSAGES.ERROR.INVALID_SAVE_ACTION',
      });
    }
  }

  createPart(): void {
    this.formWorking.set(true);
    const createInput = this.stationLogBuilder.buildCreateOrEditPart(
      this.form.value
    );
    this.stationLogApi.createPart(createInput).subscribe({
      next: (newPart) => {
        this.formWorking.set(false);
        this.logAndMessage.translateToSuccessMessage({
          headerKey: 'COMMON.MESSAGES.HEADERS.SUCCESS',
          bodyKey: 'OPMONITORING.MESSAGES.SUCCESS.CREATE_PART',
        });
        this.ref.close(newPart);
      },
      error: (error) => {
        this.formWorking.set(false);
        this.logAndMessage.errorLogOnly(error);
        this.logAndMessage.translateToErrorAlert({
          headerKey: 'COMMON.MESSAGES.HEADERS.ERROR',
          bodyKey: 'OPMONITORING.MESSAGES.ERROR.CREATE_PART',
        });
      },
    });
  }

  editPart(): void {
    this.formWorking.set(true);
    const editInput = this.stationLogBuilder.buildCreateOrEditPart(
      this.form.value
    );
    this.stationLogApi.editPart(this.part().id, editInput).subscribe({
      next: (editedPart) => {
        this.formWorking.set(false);
        this.logAndMessage.translateToSuccessMessage({
          headerKey: 'COMMON.MESSAGES.HEADERS.SUCCESS',
          bodyKey: 'OPMONITORING.MESSAGES.SUCCESS.CREATE_PART',
        });
        this.ref.close(editedPart);
      },
      error: (error) => {
        this.formWorking.set(false);
        this.logAndMessage.errorLogOnly(error);
        this.logAndMessage.translateToErrorAlert({
          headerKey: 'COMMON.MESSAGES.HEADERS.ERROR',
          bodyKey: 'OPMONITORING.MESSAGES.ERROR.EDIT_PART',
        });
      },
    });
  }

  async deletePart() {
    const deleteHeader$ = this.translateService.get(
      'OPMONITORING.MESSAGES.HEADERS.DELETE_PART'
    );
    const ok$ = this.translateService.get('COMMON.LABEL.BUTTONS.YES');
    const cancel$ = this.translateService.get('COMMON.LABEL.BUTTONS.NO');
    const deleteMsg$ = this.translateService.get(
      'OPMONITORING.MESSAGES.CONFIRMATION.DELETE_PART'
    );
    const labels = await firstValueFrom(
      forkJoin([deleteHeader$, ok$, cancel$, deleteMsg$])
    );
    this.confirmationService.confirm({
      header: labels[0],
      icon: 'fa fa-question-circle',
      acceptVisible: true,
      rejectVisible: true,
      acceptLabel: labels[1],
      rejectLabel: labels[2],
      message: labels[3],
      accept: () => {
        this.formWorking.set(true);
        this.stationLogApi.deletePart(this.part().id).subscribe({
          next: () => {
            this.formWorking.set(false);
            this.logAndMessage.translateToSuccessMessage({
              headerKey: 'COMMON.MESSAGES.HEADERS.SUCCESS',
              bodyKey: 'OPMONITORING.MESSAGES.SUCCESS.DELETE_PART',
            });
            // Need to send back some data with the close so the table refreshes
            this.ref.close(this.part());
          },
          error: (error) => {
            this.formWorking.set(false);
            this.logAndMessage.errorLogOnly(error);
            this.logAndMessage.translateToErrorAlert({
              headerKey: 'COMMON.MESSAGES.HEADERS.ERROR',
              bodyKey: 'OPMONITORING.MESSAGES.ERROR.DELETE_PART',
            });
          },
        });
      },
    });
  }

  actionEvent($event: DropdownChangeEvent, dd: Dropdown) {
    if ($event.value) {
      const selectedAction = $event.value;
      if (selectedAction === 'delete') {
        this.deletePart();
      }

      dd.clear();
    }
  }
}

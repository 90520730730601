import { Column } from 'src/app/model/common/column';

export const PARTS_SEARCH_TABLE_DEFINITION: Column[] = [
  {
    field: 'sapNumber',
    header: 'OPMONITORING.LABEL.SAP_NUMBER',
    mobileHeader: 'OPMONITORING.LABEL.SAP_NUMBER',
    formatPattern: 'text',
    type: 'input',
    width: '5%',
    sortable: true,
    filterable: true,
    matchMode: 'contains',
  },
  {
    field: 'pciNumber',
    header: 'OPMONITORING.LABEL.PCI_NUMBER',
    mobileHeader: 'OPMONITORING.LABEL.PCI_NUMBER',
    formatPattern: 'text',
    type: 'input',
    width: '5%',
    sortable: true,
    filterable: true,
    matchMode: 'contains',
  },
  {
    field: 'description',
    header: 'OPMONITORING.LABEL.DESCRIPTION',
    mobileHeader: 'OPMONITORING.LABEL.DESCRIPTION',
    formatPattern: 'text',
    type: 'input',
    width: '17%',
    sortable: true,
    filterable: true,
    matchMode: 'contains',
  },
  {
    field: 'vendorPartNumber',
    header: 'OPMONITORING.LABEL.VENDOR_PART_NUMBER',
    mobileHeader: 'OPMONITORING.LABEL.VENDOR_PART_NUMBER',
    formatPattern: 'text',
    type: 'input',
    width: '17%',
    sortable: true,
    filterable: true,
    matchMode: 'contains',
  },
  {
    field: 'manufacturer',
    header: 'OPMONITORING.LABEL.MANUFACTURER',
    mobileHeader: 'OPMONITORING.LABEL.MANUFACTURER',
    formatPattern: 'text',
    type: 'input',
    width: '17%',
    sortable: true,
    filterable: true,
    matchMode: 'contains',
  },
  {
    field: 'storeroom',
    header: 'OPMONITORING.LABEL.STOREROOM',
    mobileHeader: 'OPMONITORING.LABEL.STOREROOM',
    formatPattern: 'text',
    type: 'input',
    width: '17%',
    sortable: true,
    filterable: true,
    matchMode: 'contains',
  },
  {
    field: 'binLocation',
    header: 'OPMONITORING.LABEL.BIN_LOCATION',
    mobileHeader: 'OPMONITORING.LABEL.BIN_LOCATION',
    formatPattern: 'text',
    type: 'input',
    width: '5%',
    sortable: true,
    filterable: true,
    matchMode: 'contains',
  },
];

import { Component, OnInit, output } from '@angular/core';

@Component({
    selector: 'app-op-log-mobile-filter',
    templateUrl: './op-log-mobile-filter.component.html',
    styleUrls: ['./op-log-mobile-filter.component.scss'],
    standalone: false
})
export class OpLogMobileFilterComponent implements OnInit {

  readonly mobileFilterEvent = output<any>();

  logId: string;
  facilityName: string;
  statusName: string;

  constructor() { }

  ngOnInit() {
  }

  mobileFilter() {
    this.mobileFilterEvent.emit({facilityUID: this.logId, facilityName: this.facilityName, statusName: this.statusName});
  }

  clear() {
    this.logId = null;
    this.facilityName = null;
    this.statusName = null;
    this.mobileFilter();
  }

}

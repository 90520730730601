<div id="oplogs-details-container">
  <app-progress-spinner [enabled]="!form"></app-progress-spinner>
  <p-blockUI [blocked]="saving === true || loading === true"></p-blockUI>
  @if (form) {
    <form [formGroup]="form" id="oplog-create-form">
      @if (ifDeviceMatches([DeviceSize.MD, DeviceSize.LG, DeviceSize.XL])) {
        <div
          class="xtra-small-top-padding"
          >
          <div id="contact-action-buttons">
            <div class="no-padding-top-bottom">
              @if (!opLog || !opLog.id) {
                <div class="action-items">
                  <div class="item-long">
                    <div>
                      <app-progress-spinner [enabled]="saving"></app-progress-spinner>
                    </div>
                    <div class="button-wrapper">
                      @if (opLog.id !== null) {
                        <button
                          pButton
                          class="refresh-button"
                          icon="fa fa-fw fa-refresh"
                          (click)="refresh()"
                        ></button>
                      }
                    </div>
                  </div>
                </div>
              }
              <div class="columns">
                @if (opLog.id) {
                  <div class="no-padding-top-bottom column">
                    <div class="column-item">
                      <span class="label no-padding-top-bottom"
                        >{{ "OPMONITORING.LABEL.CREATED_BY" | translate }}:</span
                        >
                        <span class="free-text-value no-padding-top-bottom"
                          >{{ opLog.createdBy }}
                          on
                          {{ opLog.createdAt | date : "MM/dd/yyyy hh:mm:ss a" }}</span
                          >
                        </div>
                        <div class="column-item">
                          <span class="label no-padding-top-bottom"
                            >{{ "OPMONITORING.LABEL.LAST_UPDATED" | translate }}:</span
                            >
                            <span class="free-text-value no-padding-top-bottom">{{
                              opLog.updatedAt | date : "MM/dd/yyyy hh:mm:ss a"
                            }}</span>
                          </div>
                          <div class="column-item">
                            <span class="label no-padding-top-bottom; margin-right: 8px"
                              >{{ "OPMONITORING.LABEL.CURRENT_STATUS" | translate }}:</span
                              >
                              @if (opLog.opLogStatus === 'N') {
                                <span
                                  class="free-text-value no-padding-top-bottom"
                                  >{{ "OPMONITORING.LABEL.NEW" | translate }}</span
                                  >
                                }
                                @if (opLog.opLogStatus === 'A') {
                                  <span
                                    class="free-text-value no-padding-top-bottom"
                                    >{{ "OPMONITORING.LABEL.ACKNOWLEDGED" | translate }}</span
                                    >
                                  }
                                </div>
                                @if (showAcknowledged) {
                                  <div class="column-item">
                                    <span class="label no-padding-top-bottom; margin-right: 8px"
                                      >{{ "OPMONITORING.LABEL.ACKNOWLEDGED_BY" | translate }}:</span
                                      >
                                      <span class="free-text-value no-padding-top-bottom"
                                        >{{ opLog.Contact.firstName }}
                                        {{ opLog.Contact.lastName }}</span
                                        >
                                      </div>
                                    }
                                  </div>
                                }
                                @if (opLog.piPoint) {
                                  <div class="column">
                                    <app-pi-value-display
                                      [piPoint]="opLog.piPoint"
                                    ></app-pi-value-display>
                                  </div>
                                }
                                @if (opLog.id) {
                                  <div class="no-padding-top-bottom column">
                                    @if (opLog?.id) {
                                      <div
                                        class="button-wrapper no-padding-top-bottom row-item"
                                        >
                                        <span
                  style="
                    font-weight: 700;
                    text-align: right;
                    padding-right: 0.25em;
                  "
                                          >{{ "COMMON.LABEL.ACTIONS" | translate }}:</span
                                          >
                                          <span
                                            class="no-padding-top-bottom"
                                            style="text-align: left !important"
                                            >
                                            <p-dropdown
                                              #dd
                                              [options]="actions"
                                              [(ngModel)]="selectedAction"
                                              [ngModelOptions]="{ standalone: true }"
                                              (onChange)="actionEvent($event, dd)"
                                              styleclass="action-drop-down"
                                            ></p-dropdown>
                                          </span>
                                          <div
                                            class="no-padding-top-bottom display-flex"
                                            style="text-align: right; margin-left: 8px"
                                            >
                                            <div>
                                              <app-progress-spinner
                                                [enabled]="saving"
                                              ></app-progress-spinner>
                                            </div>
                                            @if (isEditting === true && canEdit === true) {
                                              <div
                                                class="button-wrapper no-padding-top-bottom"
                                                >
                                                <button
                                                  pButton
                                                  label="{{ 'COMMON.LABEL.BUTTONS.SAVE' | translate }}"
                                                  icon="fa fa-fw fa-save"
                                                  [disabled]="!this.form.valid || this.saving"
                                                  (click)="saveOperatorLog(false)"
                                                ></button>
                                              </div>
                                            }
                                            <div class="button-wrapper">
                                              @if (opLog.id !== null) {
                                                <button
                                                  pButton
                                                  class="refresh-button"
                                                  icon="fa fa-fw fa-refresh"
                                                  (click)="refresh()"
                                                ></button>
                                              }
                                            </div>
                                          </div>
                                        </div>
                                      }
                                    </div>
                                  }
                                </div>
                              </div>
                            </div>
                            <p-tabView [activeIndex]="tabIndex" (onChange)="indexChanged($event)">
                              <p-tabPanel
                                header="{{ 'OPMONITORING.LABEL.ALERT_INFORMATION' | translate }}"
                                formGroupName="OpAlertLog"
                                >
                                <p-panel
                                  header="{{ 'OPMONITORING.LABEL.SECTION_ONE' | translate }}"
                                  styleclass="op-log-panel"
                                  >
                                  <p-panel
                                    header="{{ 'OPMONITORING.LABEL.LOCATION_INFO' | translate }}"
                                    styleclass="op-log-panel"
                                    [toggleable]="true"
                                    >
                                    <div class="form-section" formGroupName="OpAlertLogLocation">
                                      <div class="section-element">
                                        <span class="label"
                                          >{{ "LOCATION.LABEL.STATE" | translate }}:</span
                                          >
                                          <span class="row-input">
                                            <p-autoComplete
                                              class="input"
                                              [suggestions]="filteredStates"
                                              (completeMethod)="stateSearch($event)"
                      (onSelect)="
                        selectState(
                          $event,
                          this.form.get('OpAlertLog').get('OpAlertLogLocation')
                        )
                      "
                                              formControlName="state"
                                              [dropdown]="true"
                                              appendTo="body"
                                              [virtualScroll]="true"
                                              [virtualScrollItemSize]="34"
                                              >
                                            </p-autoComplete>
                                          </span>
                                        </div>
                                        <div class="section-element">
                                          <span class="label"
                                            >{{ "LOCATION.LABEL.COUNTY" | translate }}:</span
                                            >
                                            <span class="row-input">
                                              <p-autoComplete
                                                class="input"
                                                [suggestions]="filteredCounties"
                      (completeMethod)="
                        countySearch(
                          $event,
                          this.form.get('OpAlertLog').get('OpAlertLogLocation')
                        )
                      "
                                                formControlName="county"
                                                [dropdown]="true"
                                                field="county"
                      (onSelect)="
                        selectCounty(
                          $event,
                          this.form.get('OpAlertLog').get('OpAlertLogLocation')
                        )
                      "
                                                appendTo="body"
                                                [virtualScroll]="true"
                                                [virtualScrollItemSize]="34"
                                                >
                                              </p-autoComplete>
                                            </span>
                                          </div>
                                          <div class="section-element">
                                            <span class="label"
                                              >{{ "LOCATION.LABEL.TOWNSHIP" | translate }}:</span
                                              >
                                              <span class="row-input">
                                                <p-autoComplete
                                                  class="input"
                                                  [suggestions]="filteredTownships"
                      (completeMethod)="
                        townshipSearch(
                          $event,
                          this.form.get('OpAlertLog').get('OpAlertLogLocation')
                        )
                      "
                                                  formControlName="township"
                                                  [dropdown]="true"
                                                  field="township"
                      (onSelect)="
                        selectTownship(
                          $event,
                          this.form.get('OpAlertLog').get('OpAlertLogLocation')
                        )
                      "
                                                  appendTo="body"
                                                  [virtualScroll]="true"
                                                  [virtualScrollItemSize]="34"
                                                  >
                                                </p-autoComplete>
                                              </span>
                                            </div>
                                          </div>
                                        </p-panel>
                                        <p-panel
                                          header="{{ 'OPMONITORING.LABEL.FACILITY_INFO' | translate }}"
                                          styleclass="op-log-panel"
                                          [toggleable]="true"
                                          >
                                          <div
                                            class="form-section"
                                            style="--columns: 8"
                                            formGroupName="OpAlertLogFacility"
                                            >
                                            <div class="section-element">
                                              <span class="label" [ngClass]="{ 'long-label': isEditting }">
                                                <span style="margin-right: 8px"
                                                  >@if (isEditting === true) {
                                                  <button
                                                    pButton
                                                    icon="fa fa-fw fa-eraser"
                                                    pTooltip="Clear Facility and Location"
                                                    (click)="resetFacility()"
                                                  ></button>
                                                  } </span
                                                  >{{ "LOCATION.LABEL.FACILITY_TYPE" | translate }}:
                                                </span>
                                                <span class="row-input">
                                                  @if (!facilitiesLoading) {
                                                    <p-autoComplete
                                                      class="input"
                                                      [suggestions]="filteredFacilityTypes"
                                                      (completeMethod)="facilityTypeSearch($event)"
                                                      formControlName="facilityType"
                                                      [dropdown]="true"
                                                      appendTo="body"
                                                      [virtualScroll]="true"
                                                      [virtualScrollItemSize]="34"
                                                      >
                                                    </p-autoComplete>
                                                  }
                                                  @if (facilitiesLoading) {
                                                    <p-progressSpinner
                                                      [style]="{ width: '50px', height: '50px' }"
                                                    ></p-progressSpinner>
                                                  }
                                                </span>
                                              </div>
                                              <div class="section-element">
                                                <span class="label"
                                                  >{{ "LOCATION.LABEL.FACILITY_NAME" | translate }}:</span
                                                  >
                                                  <span class="row-input">
                                                    @if (!facilitiesLoading) {
                                                      <p-autoComplete
                                                        class="input"
                                                        [suggestions]="filteredFacilityNames"
                                                        (completeMethod)="facilityNameSearch($event)"
                                                        formControlName="facilityName"
                                                        [dropdown]="true"
                                                        field="facilityName"
                                                        (onSelect)="facilityNameSelected($event)"
                                                        appendTo="body"
                                                        [virtualScroll]="true"
                                                        [virtualScrollItemSize]="34"
                                                        >
                                                      </p-autoComplete>
                                                    }
                                                    @if (facilitiesLoading) {
                                                      <p-progressSpinner
                                                        [style]="{ width: '50px', height: '50px' }"
                                                      ></p-progressSpinner>
                                                    }
                                                  </span>
                                                </div>
                                                <div class="section-element">
                                                  <span class="label"
                                                    >{{ "LOCATION.LABEL.FACILITY_UID" | translate }}:</span
                                                    >
                                                    <span class="row-input">
                                                      @if (!facilitiesLoading) {
                                                        <p-autoComplete
                                                          class="input"
                                                          [suggestions]="filteredFacilityIds"
                                                          (completeMethod)="facilityIdSearch($event)"
                                                          formControlName="facilityUid"
                                                          [dropdown]="true"
                                                          field="facilityUid"
                                                          (onSelect)="facilityIdSelected($event)"
                                                          appendTo="body"
                                                          [virtualScroll]="true"
                                                          [virtualScrollItemSize]="34"
                                                          >
                                                        </p-autoComplete>
                                                      }
                                                      @if (facilitiesLoading) {
                                                        <p-progressSpinner
                                                          [style]="{ width: '50px', height: '50px' }"
                                                        ></p-progressSpinner>
                                                      }
                                                    </span>
                                                  </div>
                                                  <div class="section-element">
                                                    <span class="label"
                                                      >{{ "LOCATION.LABEL.AOR" | translate }}:</span
                                                      >
                                                      <span class="row-input">
                                                        <input class="input" pInputText formControlName="aor" />
                                                      </span>
                                                    </div>
                                                    <div class="section-element">
                                                      <span class="label"
                                                        ><span style="color: red">*</span> Business Unit:</span
                                                        >
                                                        <span class="row-input">
                                                          @if (!facilitiesLoading) {
                                                            <p-autoComplete
                                                              class="input"
                                                              [suggestions]="filteredBusinessUnits"
                                                              (completeMethod)="businessUnitSearch($event)"
                                                              formControlName="businessUnit"
                                                              [dropdown]="true"
                                                              field="name"
                                                              appendTo="body"
                                                              [virtualScroll]="true"
                                                              [virtualScrollItemSize]="34"
                                                              >
                                                            </p-autoComplete>
                                                          }
                                                        </span>
                                                      </div>
                                                    </div>
                                                  </p-panel>
                                                  <br />
                                                </p-panel>
                                              </p-tabPanel>
                                              @if (opLog.id && isAlarm === true) {
                                                <p-tabPanel
                                                  header="{{ 'OPMONITORING.LABEL.ALARMS' | translate }}"
                                                  >
                                                  <p-panel
                                                    header="{{ 'OPMONITORING.LABEL.SECTION_TWO' | translate }}"
                                                    styleclass="op-log-panel"
                                                    >
                                                    <div>
                                                      @for (entry of opLog.OpAlertLogAlarms; track entry) {
                                                        <div>
                                                          <app-op-logs-entry
                                                            [entry]="entry"
                                                            [writeAccess]="false"
                                                            (editEvent)="editEntry($event)"
                                                            (deleteEvent)="deleteEntry($event)"
                                                          ></app-op-logs-entry>
                                                        </div>
                                                      }
                                                    </div>
                                                  </p-panel>
                                                </p-tabPanel>
                                              }
                                              @if (opLog.id) {
                                                <p-tabPanel
                                                  header="{{ 'OPMONITORING.LABEL.ENTRIES' | translate }}"
                                                  >
                                                  <p-panel
                                                    header="{{ 'OPMONITORING.LABEL.SECTION_THREE' | translate }}"
                                                    styleclass="op-log-panel"
                                                    >
                                                    <div>
                                                      @if (canEdit) {
                                                        <div style="text-align: right; height: 40px">
                                                          <div class="button-wrapper no-padding-top-bottom">
                                                            <button
                                                              pButton
                                                              label="{{ 'COMMON.LABEL.BUTTONS.ADD' | translate }}"
                                                              icon="fa fa-fw fa-plus"
                                                              (click)="showEntryForm()"
                                                            ></button>
                                                          </div>
                                                        </div>
                                                      }
                                                      @for (entry of opLog.OpAlertLogEntries; track entry) {
                                                        <div
                                                          style="word-wrap: break-word"
                                                          >
                                                          <app-op-logs-entry
                                                            [entry]="entry"
                                                            [writeAccess]="canEdit"
                                                            (editEvent)="editEntry($event, true)"
                                                            (deleteEvent)="deleteEntry($event, true)"
                                                            >
                                                          </app-op-logs-entry>
                                                        </div>
                                                      }
                                                    </div>
                                                  </p-panel>
                                                </p-tabPanel>
                                              }
                                              <p-tabPanel
                                                header="{{ 'OPMONITORING.LABEL.NOTIFICATIONS' | translate }}"
                                                >
                                                <div>
                                                  <p-scrollPanel
                                                    [style]="{ width: '100%' }"
                                                    styleclass="notify-scroll"
                                                    >
                                                    <div>
                                                      <br />
                                                      <div class="no-padding">
                                                        <p-panel
                    header="{{
                      'OPMONITORING.LABEL.NOTIFICATIONS' | translate
                    }}"
                                                          styleclass="op-log-panel"
                                                          >
                                                          @for (a of opLog.OpAlertLogNotifications; track a) {
                                                            <div
                                                              style="word-wrap: break-word; width: 600px"
                                                              >
                                                              <strong
                                                                >{{ a.time | date : "yyyy-MM-dd HH:mm:ss" }} -
                                                                {{ a.user }}:</strong
                                                                >
                                                                {{ a.note }}
                                                              </div>
                                                            }
                                                          </p-panel>
                                                        </div>
                                                        <br />
                                                      </div>
                                                    </p-scrollPanel>
                                                  </div>
                                                </p-tabPanel>
                                              </p-tabView>
                                            </div>
                                          }
                                          @if (ifDeviceMatches([DeviceSize.XS, DeviceSize.SM])) {
                                            <div
                                              id="op-log-small"
                                              >
                                              <br />
                                              <p-panel
                                                [showHeader]="true"
                                                header="General"
                                                [toggleable]="true"
                                                styleclass="op-log-panel"
                                                toggler="header"
                                                formGroupName="OpAlertLog"
                                                >
                                                <div>
                                                  <div class="no-padding-top-bottom">
                                                    @if (opLog.id) {
                                                      <div class="no-padding">
                                                        <span class="no-padding-top-bottom" style="font-weight: 700"
                                                          >{{ "OPMONITORING.LABEL.CREATED_BY" | translate }}:</span
                                                          >
                                                          <span class="no-padding-top-bottom"
                                                            >{{ opLog.createdBy }} on
                                                            {{ opLog.createdAt | date : "MM/dd/yyyy hh:mm:ss a" }}</span
                                                            >
                                                            <span class="no-padding-top-bottom" style="font-weight: 700"
                                                              >{{ "OPMONITORING.LABEL.LAST_UPDATED" | translate }}:</span
                                                              >
                                                              <span class="no-padding-top-bottom">{{
                                                                opLog.updatedAt | date : "MM/dd/yyyy hh:mm:ss a"
                                                              }}</span>
                                                            </div>
                                                          }
                                                        </div>
                                                      </div>
                                                    </p-panel>
                                                    <br />
                                                    <p-panel
                                                      header="{{ 'OPMONITORING.LABEL.CALLER_LOCATION_INFO' | translate }}"
                                                      [showHeader]="true"
                                                      styleclass="op-log-panel"
                                                      [toggleable]="true"
                                                      toggler="header"
                                                      formGroupName="OpAlertLog"
                                                      >
                                                      <div formGroupName="OpAlertLogLocation">
                                                        <div class="no-padding">
                                                          <span class="no-padding-top-bottom"
                                                            >{{ "LOCATION.LABEL.STATE" | translate }}:</span
                                                            >
                                                            <span class="no-padding-top-bottom">
                                                              <p-autoComplete
                                                                [suggestions]="filteredStates"
                                                                (completeMethod)="stateSearch($event)"
                (onSelect)="
                  selectState(
                    $event,
                    this.form.get('OpAlertLog').get('OpAlertLogLocation')
                  )
                "
                                                                formControlName="state"
                                                                [dropdown]="true"
                                                                appendTo="body"
                                                                [virtualScroll]="true"
                                                                [virtualScrollItemSize]="34"
                                                                >
                                                              </p-autoComplete>
                                                            </span>
                                                          </div>
                                                          <div class="no-padding">
                                                            <span class="no-padding-top-bottom"
                                                              >{{ "LOCATION.LABEL.COUNTY" | translate }}:</span
                                                              >
                                                              <span class="no-padding-top-bottom">
                                                                <p-autoComplete
                                                                  [suggestions]="filteredCounties"
                (completeMethod)="
                  countySearch(
                    $event,
                    this.form.get('OpAlertLog').get('OpAlertLogLocation')
                  )
                "
                                                                  formControlName="county"
                                                                  [dropdown]="true"
                                                                  field="county"
                (onSelect)="
                  selectCounty(
                    $event,
                    this.form.get('OpAlertLog').get('OpAlertLogLocation')
                  )
                "
                                                                  appendTo="body"
                                                                  [virtualScroll]="true"
                                                                  [virtualScrollItemSize]="34"
                                                                  >
                                                                </p-autoComplete>
                                                              </span>
                                                            </div>
                                                            <div class="no-padding">
                                                              <span class="no-padding-top-bottom"
                                                                >{{ "LOCATION.LABEL.TOWNSHIP" | translate }}:</span
                                                                >
                                                                <span class="no-padding-top-bottom">
                                                                  <p-autoComplete
                                                                    [suggestions]="filteredTownships"
                (completeMethod)="
                  townshipSearch(
                    $event,
                    this.form.get('OpAlertLog').get('OpAlertLogLocation')
                  )
                "
                                                                    formControlName="township"
                                                                    [dropdown]="true"
                                                                    field="township"
                (onSelect)="
                  selectTownship(
                    $event,
                    this.form.get('OpAlertLog').get('OpAlertLogLocation')
                  )
                "
                                                                    appendTo="body"
                                                                    [virtualScroll]="true"
                                                                    [virtualScrollItemSize]="34"
                                                                    >
                                                                  </p-autoComplete>
                                                                </span>
                                                              </div>
                                                            </div>
                                                          </p-panel>
                                                          <br />
                                                          <p-panel
                                                            header="{{ 'OPMONITORING.LABEL.FACILITY_INFO' | translate }}"
                                                            styleclass="op-log-panel"
                                                            [toggleable]="true"
                                                            [showHeader]="true"
                                                            toggler="header"
                                                            formGroupName="OpAlertLog"
                                                            >
                                                            <div formGroupName="OpAlertLogFacility">
                                                              <span class="no-padding-top-bottom"
                                                                >@if (isEditting === true) {
                                                                <button
                                                                  pButton
                                                                  icon="fa fa-fw fa-eraser"
                                                                  pTooltip="Clear Facility and Location"
                                                                  (click)="resetFacility()"
                                                                  ></button
                                                                  >
                                                                }</span>
                                                                <span class="no-padding-top-bottom"
                                                                  >{{ "LOCATION.LABEL.FACILITY_TYPE" | translate }}:</span
                                                                  >
                                                                  <span class="no-padding-top-bottom">
                                                                    @if (!facilitiesLoading) {
                                                                      <p-autoComplete
                                                                        [suggestions]="filteredFacilityTypes"
                                                                        (completeMethod)="facilityTypeSearch($event)"
                                                                        formControlName="facilityType"
                                                                        [dropdown]="true"
                                                                        [forceSelection]="true"
                                                                        appendTo="body"
                                                                        [virtualScroll]="true"
                                                                        [virtualScrollItemSize]="34"
                                                                        >
                                                                      </p-autoComplete>
                                                                    }
                                                                    @if (facilitiesLoading) {
                                                                      <p-progressSpinner
                                                                        [style]="{ width: '50px', height: '50px' }"
                                                                      ></p-progressSpinner>
                                                                    }
                                                                  </span>
                                                                  <div class="no-padding">
                                                                    <span class="no-padding-top-bottom"
                                                                      >{{ "LOCATION.LABEL.FACILITY_NAME" | translate }}:</span
                                                                      >
                                                                      <span class="no-padding-top-bottom">
                                                                        @if (!facilitiesLoading) {
                                                                          <p-autoComplete
                                                                            [suggestions]="filteredFacilityNames"
                                                                            (completeMethod)="facilityNameSearch($event)"
                                                                            formControlName="facilityName"
                                                                            [dropdown]="true"
                                                                            field="facilityName"
                                                                            (onSelect)="facilityNameSelected($event)"
                                                                            [forceSelection]="true"
                                                                            dropdownMode="current"
                                                                            appendTo="body"
                                                                            [virtualScroll]="true"
                                                                            [virtualScrollItemSize]="34"
                                                                            >
                                                                          </p-autoComplete>
                                                                        }
                                                                        @if (facilitiesLoading) {
                                                                          <p-progressSpinner
                                                                            [style]="{ width: '50px', height: '50px' }"
                                                                          ></p-progressSpinner>
                                                                        }
                                                                      </span>
                                                                    </div>
                                                                    <div class="no-padding">
                                                                      <span class="no-padding-top-bottom"
                                                                        >{{ "LOCATION.LABEL.FACILITY_UID" | translate }}:</span
                                                                        >
                                                                        <span class="no-padding-top-bottom">
                                                                          @if (!facilitiesLoading) {
                                                                            <p-autoComplete
                                                                              [suggestions]="filteredFacilityIds"
                                                                              (completeMethod)="facilityIdSearch($event)"
                                                                              formControlName="facilityUid"
                                                                              [dropdown]="true"
                                                                              field="facilityUid"
                                                                              (onBlur)="facilityIdSelected($event)"
                                                                              [forceSelection]="true"
                                                                              dropdownMode="current"
                                                                              appendTo="body"
                                                                              [virtualScroll]="true"
                                                                              [virtualScrollItemSize]="34"
                                                                              >
                                                                            </p-autoComplete>
                                                                          }
                                                                          @if (facilitiesLoading) {
                                                                            <p-progressSpinner
                                                                              [style]="{ width: '50px', height: '50px' }"
                                                                            ></p-progressSpinner>
                                                                          }
                                                                        </span>
                                                                      </div>
                                                                      <div class="-no-padding">
                                                                        <span class="no-padding-top-bottom"
                                                                          >{{ "LOCATION.LABEL.AOR" | translate }}:</span
                                                                          >
                                                                          <span class="no-padding-top-bottom">
                                                                            <input pInputText formControlName="aor" />
                                                                          </span>
                                                                        </div>
                                                                        <div class="no-padding">
                                                                          <span class="no-padding-top-bottom"
                                                                            ><span style="color: red">*</span> Business Unit:</span
                                                                            >
                                                                            <span class="no-padding-top-bottom">
                                                                              <p-dropdown formControlName="businessUnit"></p-dropdown>
                                                                            </span>
                                                                          </div>
                                                                        </div>
                                                                      </p-panel>
                                                                      <br />
                                                                      <p-panel
                                                                        header="{{ 'OPMONITORING.LABEL.ENTRIES' | translate }}"
                                                                        [toggleable]="true"
                                                                        toggler="header"
                                                                        >
                                                                        <div>
                                                                          @for (entry of opLog.OpAlertLogEntries; track entry) {
                                                                            <div
                                                                              style="word-wrap: break-word"
                                                                              >
                                                                              <app-op-logs-entry
                                                                                [entry]="entry"
                                                                                [writeAccess]="false"
                                                                                (editEvent)="editEntry($event, true)"
                                                                                (deleteEvent)="deleteEntry($event, true)"
                                                                              ></app-op-logs-entry>
                                                                            </div>
                                                                          }
                                                                        </div>
                                                                      </p-panel>
                                                                    </div>
                                                                  }
                                                                </form>
                                                              }

                                                              <p-dialog
                                                                header="Add Entry to Field Notification"
                                                                [(visible)]="showEntryDialog"
                                                                showEffect="fade"
                                                                [modal]="true"
                                                                [transitionOptions]="'0ms'"
                                                                (onHide)="cancelEntryForm()"
                                                                [style]="{ width: '1000px' }"
                                                                >
                                                                <form [formGroup]="addEntryForm" class="form">
                                                                  <div id="add-location-create-form">
                                                                    <div class="panel">
                                                                      <div class="form-row">
                                                                        <div class="row-item">
                                                                          <span class="label">{{
                                                                            "OPMONITORING.LABEL.ACTIVITY_DATE" | translate
                                                                          }}</span>
                                                                          <span class="content">
                                                                            <p-calendar
                                                                              [showTime]="true"
                                                                              formControlName="entryTime"
                                                                              appendTo="body"
                                                                              [showSeconds]="true"
                                                                              >
                                                                            </p-calendar>
                                                                          </span>
                                                                          <span class="label">{{
                                                                            "OPMONITORING.LABEL.CURRENT_TIME_DEFAULT" | translate
                                                                          }}</span>
                                                                        </div>
                                                                      </div>
                                                                      <div class="no-padding-top-bottom form-body">
                                                                        <div class="no-padding-top-bottom">
                                                                          <span class="label"
                                                                            >{{
                                                                            "OPMONITORING.LABEL.PLEASE_INSERT_STATUS" | translate
                                                                            }}:</span
                                                                            >
                                                                          </div>

                                                                          @if(showEntryDialog) {
                                                                            <p-editor
                                                                              formControlName="statusUpdate"
                                                                              [style]="{ height: '300px' }"
                                                                              (onTextChange)="validateForm()"
                                                                              >
                                                                              <!-- <p-header></p-header>   [modules]="quillModules"-->
                                                                            </p-editor>
                                                                          }
                                                                        </div>

                                                                        <div class="no-padding-top-bottom label">
                                                                          {{ "OPMONITORING.LABEL.ENTRY_LABEL" | translate }}
                                                                        </div>

                                                                        <div class="modal-buttons">
                                                                          <div class="offset-6">
                                                                            <button
                                                                              pButton
                                                                              label="{{ 'COMMON.LABEL.BUTTONS.OK' | translate }}"
                                                                              icon="fa fa-fw fa-check"
                                                                              (click)="addEntryToOperatorLog()"
                                                                              [disabled]="!this.addEntryForm.valid"
                                                                            ></button>
                                                                          </div>
                                                                          <div style="margin-left: 8px">
                                                                            <button
                                                                              pButton
                                                                              label="{{ 'COMMON.LABEL.BUTTONS.CANCEL' | translate }}"
                                                                              icon="fa fa-fw fa-ban"
                                                                              (click)="cancelEntryForm()"
                                                                            ></button>
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                  </form>
                                                                </p-dialog>
                                                              </div>

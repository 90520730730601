<div id="station-logs-container">
  <p-blockUI [blocked]="loading === true"></p-blockUI>
  @if (ifDeviceMatches([DeviceSize.LG, DeviceSize.XL])) {
  <div id="contact-action-buttons">
    <div id="station-logs-container-header-items">
      <div class="button-wrapper">
        @if (this.canCreate) {
        <button pButton class="add-button width-eighty" icon="fa fa-fw fa-plus"
          label="{{'COMMON.LABEL.BUTTONS.ADD' | translate}}" (click)="newStationLog()"></button>
        }
        <button pButton class="width-eighty" label="{{'COMMON.LABEL.BUTTONS.CLEAR' | translate}}"
          icon="fa fa-fw fa-eraser" (click)="clearScreen()"></button>
        <button pButton class="refresh-button width-twenty" icon="fa fa-fw fa-refresh" (click)="refresh()"></button>
      </div>
    </div>
  </div>
  }

  <app-progress-spinner [enabled]="!elements"></app-progress-spinner>
  @if (!isMobileDevice()) {
  <div [style.display]="!elements ? 'none' : ''">
    <app-general-grid [values]="elements" [columns]="columns" [loading]="loading" [deviceSize]="getDeviceSize()"
      [rows]="rows" [totalRecords]="totalRecords" [filterable]="true" [screenName]="screenName" [lazy]="true"
      (lazyLoad)="lazyLoad($event)" [includeExportCSV]="true" (changeViewEvent)="changeViewEvent($event)"
      scrollHeight="calc(100vh - 256px)" [usePaginator]="true" [rowsPerPageOptions]="[50, 100, 200]" #grid>
    </app-general-grid>
  </div>
  }

  @if (isMobileDevice()) {
  <div [style.display]="!elements ? 'none' : ''">
    <app-general-grid [values]="elements" [columns]="columns" [loading]="loading" [deviceSize]="getDeviceSize()"
      [rows]="rows" [totalRecords]="totalRecords" [filterable]="true" [screenName]="screenName" [lazy]="true"
      (lazyLoad)="lazyLoad($event)" [includeExportCSV]="false" (changeViewEvent)="changeViewEvent($event)"
      scrollHeight="calc(100vh - 175px)" [usePaginator]="true" [rowsPerPageOptions]="[50, 100, 200]" #grid>
    </app-general-grid>
  </div>
  }
</div>
<app-station-log-form
  [isVisible]="formVisible"
  (formHidden)="onFormHide()"
  [formType]="formType"
  [canCreate]="canCreate"
  [canEdit]="canEdit"
  (formSubmitted)="onFormSubmit($event)"
  (ready)="onFormReady()"
  (stationLogDeleted)="onStationLogDelete()"
  [isLoading]="formLoading"
  [selectedStationLog]="selectedStationLog"
  #stationLogForm
></app-station-log-form>

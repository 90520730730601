import gql from 'graphql-tag';

export const getStationLogHistory = gql`
  query getStationLogHistory($id: String) {
    getStationLogHistory(id: $id) {
      updater
      updatedAt
    }
  }
`;

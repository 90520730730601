
import gql from 'graphql-tag';

export const queryForPlcAlarmCodes = gql`
  query queryForPlcAlarmCodes(
    $limit: Int
    $page: Int
    $query: PlcAlarmCodeSortOrQuery
    $sort: PlcAlarmCodeSortOrQuery
  ) {
    queryForPlcAlarmCodes(limit: $limit, page: $page, query: $query, sort: $sort) {
      items {
        id
        alarmCode
        alarmComment
        alarmExplanation
        alarmFix
        createdBy
        createdAt
        updater
        updatedAt
        deletedAt
      }
      totalRecords
    }
  }
`;

import { ChangeDetectorRef, Component, OnDestroy, OnInit, viewChild, inject } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';
import { ConfirmationService, MenuItem, SelectItem } from 'primeng/api';
import { Editor } from 'primeng/editor';
import { forkJoin, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { DELETE_LOG, PRINT } from 'src/app/constants/action-constants';
import {
  ALL_OPLOG_SEARCH_CONTAINER,
  OPALERTLOG_PRINT_CONTAINER,
} from 'src/app/constants/common.constants';
import { DetailsContainer } from 'src/app/core/containers/details-container';
import {
  dynamicSort,
  filterDropdownResults,
  getDirtyValues,
  onlyUnique,
} from 'src/app/core/functions/common-functions';
import { TabService } from 'src/app/core/services/tab.service';
import { BreadCrumb } from 'src/app/model/common/bread-crumb';
import { DirtyStatus } from 'src/app/model/common/dirty-status';
import { FilterCriteria } from 'src/app/model/common/filterCriteria';
import { Facility } from 'src/app/model/locations/facility';
import { AuthService } from 'src/app/services/auth.service';
import { BreadCrumbBuilderService } from 'src/app/services/breadcrumb-builder.service';
import { ContactApiService } from 'src/app/services/contact-api.service';
import { ContactTableService } from 'src/app/services/contact-table.service';
import { DeviceService } from 'src/app/services/device.service';
import { FacilityApiService } from 'src/app/services/facility-api.service';
import { FacilityBuilderService } from 'src/app/services/facility-builder.service';
import { FacilityTableService } from 'src/app/services/facility-table.service';
import { LoadingService } from 'src/app/services/loading.service';
import { LocationApiService } from 'src/app/services/location-api.service';
import { LogAndMessageService } from 'src/app/services/log-and-message.service';
import { AuthApiService } from 'src/app/services/auth-api.service';
import { isNullOrUndefined } from 'util';
import { OpAlertLog } from 'src/app/model/opAlertLogs/op-alert-log';
import { OpAlertLogEntryInput } from 'src/app/model/opAlertLogs/op-alert-log-entry-input';
import { OpAlertLogTableService } from 'src/app/services/op-alert-log-table.service';
import { OpAlertLogsBuilderService } from 'src/app/services/op-alert-logs-builder.service';
import { OperationalMonitoringApiService } from 'src/app/services/operational-monitoring-api.service';
import { OpAlertLogInput } from 'src/app/model/opAlertLogs/op-alert-log-input';
import { CLAIMS } from 'src/app/constants/auth-constants';

@Component({
    selector: 'app-oplogs-detail-container',
    templateUrl: './oplogs-detail-container.component.html',
    styleUrls: ['./oplogs-detail-container.component.scss'],
    standalone: false
})
export class OplogsDetailContainerComponent
  extends DetailsContainer
  implements OnInit, OnDestroy
{
  protected _translateService: TranslateService;
  private _breadCrumbService = inject(BreadCrumbBuilderService);
  protected _deviceService: DeviceService;
  private _fb = inject(UntypedFormBuilder);
  private _cdRef = inject(ChangeDetectorRef);
  protected _logAndMessage = inject(LogAndMessageService);
  protected _confirmationService: ConfirmationService;
  private _opMonitoringApi = inject(OperationalMonitoringApiService);
  private _authApi = inject(AuthApiService);
  private _opAlertLogBuilder = inject(OpAlertLogsBuilderService);
  protected _locationApi: LocationApiService;
  protected _contactApi: ContactApiService;
  protected _contactTable = inject(ContactTableService);
  protected _facilityApi: FacilityApiService;
  private _activeRoute = inject(ActivatedRoute);
  protected _facilityBuilder = inject(FacilityBuilderService);
  protected _facilityTableService: FacilityTableService;
  private _auth = inject(AuthService);
  private _opLogTableService = inject(OpAlertLogTableService);
  private _loader = inject(LoadingService);

  readonly editor = viewChild(Editor);

  tabs: any[];
  opLog: OpAlertLog;
  opLogBreadCrumb: BreadCrumb;
  types: SelectItem[];
  showSeverity = false;
  showNotam = false;
  isEditting = false;
  states: any[];
  filteredStates: any[];
  counties: any[];
  filteredCounties: any[];
  townships: any[];
  filteredTownships: any[];

  facilities: Facility[];
  facilityTypes: any[];
  filteredFacilityTypes: any[];
  facilityNames: any[];
  filteredFacilityNames: any[];
  facilityIds: any[];
  filteredFacilityIds: any[];
  filteredBusinessUnits: any[];
  facilitiesLoading: boolean = true;

  contacts: any[];
  filteredContacts: any[];
  showEntryDialog = false;

  addEntryForm: UntypedFormGroup;
  addingAction = false;
  saving = false;
  forwardOptions: MenuItem[];
  primaryContact: any;
  primaryContacts: MenuItem[];

  actions: SelectItem[] = [];

  quillModules: any;
  sub: Subscription;

  validationMessages: any;
  validationParams: any;

  canEdit = false;
  canEditOwnEntry = false;
  canEditDirectReportEntries = false;

  canDelete = false;
  canDeleteOwnEntry = false;
  canDeleteDirectReportEntries = false;

  canAddEntry = false;
  canAddOwnEntry = false;
  canAddDirectReportEntries = false;
  allowedToModifyIds: string[];

  deleteMsg: string;
  deleteEntryMsg: string;
  deleteEntryHeader: string;
  okBtnLabel: string;
  cancelBtnLabel: string;
  deleteHeader: string;
  isAlarm = false;
  showAcknowledged = false;

  displayEmailDialog = false;
  emailDialogHeader: string;
  selectedAction: any = null;
  valueSub: Subscription;
  showGasReleasePopupMessage = false;
  showCalloutPopupMessage = false;
  tabIndex = 0;
  breadCrumbSub: Subscription;
  activeTabChangedSub: Subscription;

  constructor() {
    const _translateService = inject(TranslateService);
    const _deviceService = inject(DeviceService);
    const _confirmationService = inject(ConfirmationService);
    const _locationApi = inject(LocationApiService);
    const _contactApi = inject(ContactApiService);
    const _facilityApi = inject(FacilityApiService);
    const _facilityTableService = inject(FacilityTableService);

    super(
      _translateService,
      _deviceService,
      _confirmationService,
      _locationApi,
      _contactApi,
      _facilityTableService,
      _facilityApi
    );
    this._translateService = _translateService;
    this._deviceService = _deviceService;
    this._confirmationService = _confirmationService;
    this._locationApi = _locationApi;
    this._contactApi = _contactApi;
    this._facilityApi = _facilityApi;
    this._facilityTableService = _facilityTableService;

  }

  ngOnInit() {
    this.setupTabClosingSubscription();
    this.setupBreadCrumbSub();
    this.autoSelect = true;
    this.addEntryForm = this._fb.group({
      statusUpdate: [null, Validators.required],
      id: null,
      entryTime: null,
    });

    if (this._loader.isLoaded()) {
      this.initializeOpLog();
    } else {
      this._loader.loadingFinishedEvent.pipe(take(1)).subscribe(() => {
        this.initializeOpLog();
      });
    }
    this.loadFacilities();
  }

  refresh() {
    this.loading = true;
    this.ngOnInit(); //reloading page
  }

  setupBreadCrumbSub(): void {
    this.breadCrumbSub =
      this._breadCrumbService.breadCrumbSelectedEvent.subscribe(
        (breadCrumb) => {
          this._breadCrumbService.removeBreadCrumb(this.opLogBreadCrumb);
          const tab = TabService.getInstance().buildNewTab(
            ALL_OPLOG_SEARCH_CONTAINER,
            true
          );
          TabService.getInstance().setMobileTab(tab);
        }
      );
  }

  initializeOpLog() {
    const id = this.id ? this.id : this._activeRoute.snapshot.params['id'];
    const deleteMsg$ = this._translateService.get(
      'OPMONITORING.MESSAGES.CONFIRMATION.DELETE_OPLOG'
    );
    const deleteEntryMsg$ = this._translateService.get(
      'COMMON.MESSAGES.CONFIRMATION.DELETE_ENTRY'
    );
    const ok$ = this._translateService.get('COMMON.LABEL.BUTTONS.YES');
    const cancel$ = this._translateService.get('COMMON.LABEL.BUTTONS.NO');
    const header$ = this._translateService.get(
      'OPMONITORING.MESSAGES.HEADERS.DELETE_OP_ALERTLOG'
    );
    const deleteEntryHeader$ = this._translateService.get(
      'COMMON.MESSAGES.HEADERS.DELETE_ENTRY'
    );
    forkJoin([
      deleteMsg$,
      ok$,
      cancel$,
      header$,
      deleteEntryMsg$,
      deleteEntryHeader$,
    ]).subscribe((messages) => {
      this.deleteMsg = messages[0];
      this.okBtnLabel = messages[1];
      this.cancelBtnLabel = messages[2];
      this.deleteHeader = messages[3];
      this.deleteEntryMsg = messages[4];
      this.deleteEntryHeader = messages[5];
    });

    const validations =
      this._opAlertLogBuilder.buildOpAlertLogValidationMessages();
    this.validationMessages = validations.messages;
    this.validationParams = validations.params;

    // We should always have an id here, but handle just in case
    if (!id) {
      this._logAndMessage.errorLogOnly(
        'Could not find op alert log with ID: ' + id
      );

      if (this._deviceService.isMobile()) {
        const tab = TabService.getInstance().buildNewTab(
          ALL_OPLOG_SEARCH_CONTAINER,
          true
        );
        TabService.getInstance().setMobileTab(tab);
      } else {
        const index = TabService.getInstance().getActiveIndex();
        TabService.getInstance().closeTab(index);
      }
    } else {
      // Otherwise, grab the log from the db via the id
      this._opMonitoringApi
        .getOpAlertLog(id)
        .pipe(take(1))
        .subscribe(
          ({ data }) => {
            const clone = Object.assign({}, data);
            if (clone.getOpAlertLog) {
              this.opLog = this._opAlertLogBuilder.buildOpAlertLog(
                clone.getOpAlertLog
              );
              this.opLog.Facility = this._facilityBuilder.buildFacility(
                this.opLog.Facility,
                null
              );
              this.initialize();
            } else {
              // nothing found, go back to search.
              this._logAndMessage.errorLogOnly(
                'Could not find op alert log with ID: ' + id
              );

              if (this._deviceService.isMobile()) {
                const tab = TabService.getInstance().buildNewTab(
                  ALL_OPLOG_SEARCH_CONTAINER,
                  true
                );
                TabService.getInstance().setMobileTab(tab);
              } else {
                const index = TabService.getInstance().getActiveIndex();
                TabService.getInstance().closeTab(index);
              }
            }
          },
          (error) => {
            console.log(error);
            // nothing found, go back to search or previous tab
            this._logAndMessage.errorLogOnly(
              'Error finding op alert log with ID: ' + id
            );
            if (this._opLogTableService.getLastScreen() === 'all') {
              if (this._deviceService.isMobile()) {
                const tab = TabService.getInstance().buildNewTab(
                  ALL_OPLOG_SEARCH_CONTAINER,
                  true
                );
                TabService.getInstance().setMobileTab(tab);
              } else {
                const index = TabService.getInstance().getActiveIndex();
                TabService.getInstance().closeTab(index);
              }
            } else {
              const index = TabService.getInstance().getActiveIndex();
              TabService.getInstance().closeTab(index);
            }
          },
          () => {
            this.doCheckComplete = false;
          }
        );
    }
  }

  initialize() {
    if (
      isNullOrUndefined(this.opLog) ||
      (!isNullOrUndefined(this.opLog) && this.opLog.id === null)
    ) {
      this.isEditting = true;
    }

    if (!this._deviceService.isMobile()) {
      this.screenName = this.opLog.opLogId + ' (Field Notification)';
      TabService.getInstance().updateActiveTabLabel(
        this.opLog.opLogId + ' (Field Notification)'
      );
    }

    this.buildForm();
    this.updateForm();
    this.canEdit = this._authApi.doesUserHaveAllClaimsFromList([
      CLAIMS.FIELD_OPERATIONS.OPERATIONAL_ALERTS.EDIT_OPERATIONAL_ALERT,
    ]);
    this.canEditOwnEntry = this._authApi.doesUserHaveAllClaimsFromList([
      CLAIMS.FIELD_OPERATIONS.OPERATIONAL_ALERTS.ENTRIES.EDIT_OWN_OPERATIONAL_ALERT_ENTRY,
    ]);
    this.canEditDirectReportEntries =
      this._authApi.doesUserHaveAllClaimsFromList([
        CLAIMS.FIELD_OPERATIONS.OPERATIONAL_ALERTS.ENTRIES.EDIT_DIRECT_REPORT_OPERATIONAL_ALERT_ENTRY,
      ]);

    this.canDelete = this._authApi.doesUserHaveAllClaimsFromList([
      CLAIMS.FIELD_OPERATIONS.OPERATIONAL_ALERTS.DELETE_OPERATIONAL_ALERT,
    ]);
    this.canDeleteOwnEntry = this._authApi.doesUserHaveAllClaimsFromList([
      CLAIMS.FIELD_OPERATIONS.OPERATIONAL_ALERTS.ENTRIES.DELETE_OWN_OPERATIONAL_ALERT_ENTRY,
    ]);
    this.canDeleteDirectReportEntries =
      this._authApi.doesUserHaveAllClaimsFromList([
        CLAIMS.FIELD_OPERATIONS.OPERATIONAL_ALERTS.ENTRIES.DELETE_DIRECT_REPORT_OPERATIONAL_ALERT_ENTRY,
      ]);

    this.canAddEntry = this._authApi.doesUserHaveAllClaimsFromList([
      CLAIMS.FIELD_OPERATIONS.OPERATIONAL_ALERTS.ENTRIES.ADD_OPERATIONAL_ALERT_ENTRY,
    ]);
    this.canAddOwnEntry = this._authApi.doesUserHaveAllClaimsFromList([
      CLAIMS.FIELD_OPERATIONS.OPERATIONAL_ALERTS.ENTRIES.ADD_OWN_OPERATIONAL_ALERT_ENTRY,
    ]);
    this.canAddDirectReportEntries =
      this._authApi.doesUserHaveAllClaimsFromList([
        CLAIMS.FIELD_OPERATIONS.OPERATIONAL_ALERTS.ENTRIES.ADD_DIRECT_REPORT_OPERATIONAL_ALERT_ENTRY,
      ]);

    if (this.opLog.Contact) {
      this._contactApi
        .queryForWhoReportsToMe(this._auth.getEmail())
        .pipe(take(1))
        .subscribe(({ data }) => {
          const clone = Object.assign({}, data);
          this.allowedToModifyIds = clone.queryWhoReportsToMe.map((c) => c.id);
          if (
            this.allowedToModifyIds.some((i) => i === this.opLog.Contact.id)
          ) {
            if (this.canEditDirectReportEntries) {
              this.canEdit = true;
            }

            if (this.canAddDirectReportEntries) {
              this.canAddEntry = true;
            }

            if (this.canDeleteDirectReportEntries) {
              this.canDelete = true;
            }
          }

          this._cdRef.markForCheck();
        });
    }

    if (this.opLog) {
      this.showAcknowledged = this.opLog.Contact != null;
    }

    this.loadDropDownData();

    if (
      this._deviceService.isMobile() &&
      (!this._opLogTableService.getLastScreen() ||
        this._opLogTableService.getLastScreen() === 'all')
    ) {
      this._translateService
        .get('OPMONITORING.SCREEN.ALL_OPERATIONAL_LOGS')
        .subscribe((label) => {
          this._breadCrumbService.resetAndAddBreadCrumb(
            new BreadCrumb(label, null, true)
          );
          if (this.opLog) {
            this.opLogBreadCrumb = new BreadCrumb(
              this.opLog.opLogId,
              null,
              false
            );
          }
          this._breadCrumbService.addBreadCrumb(this.opLogBreadCrumb);
        });
    }

    this.updateActionsList();
    this.loading = false;
  }

  loadFacilities() {
    this.facilitiesLoading = true;
    this._facilityApi.getFacilities().subscribe((data) => {
      const facilitiesClone = [...data];
      this.facilities = facilitiesClone.map((f: any) =>
        this._facilityBuilder.buildFacility(f, f.Locations)
      );
      this.facilitiesLoading = false;
    });
  }

  facilityTypeSearch($event) {
    if (this.form) {
      const detailGroup = this.form
        .get('OpAlertLog')
        .get('OpAlertLogLocation') as UntypedFormGroup;
      const state = detailGroup.controls['state'].value;
      const countyContainer = detailGroup.controls['county'].value;
      const townshipContainer = detailGroup.controls['township'].value;
      const businessUnit = this.form.controls['businessUnit'].value;

      const queryArray: FilterCriteria[] = [];
      if (state) {
        queryArray.push(new FilterCriteria('state', state, 'include'));
      }
      if (countyContainer && countyContainer.county) {
        queryArray.push(
          new FilterCriteria('county', countyContainer.county, 'include')
        );
      }
      if (townshipContainer && townshipContainer.township) {
        queryArray.push(
          new FilterCriteria('township', townshipContainer.township, 'include')
        );
      }
      if (businessUnit) {
        queryArray.push(
          new FilterCriteria(
            'businessUnit.id',
            businessUnit.id,
            'array-includes'
          )
        );
      }
      if ($event.query !== '') {
        queryArray.push(
          new FilterCriteria('facilityType', $event.query, 'startsWith')
        );
      }

      if (this.facilities) {
        this.filteredFacilityTypes = filterDropdownResults(
          this.facilities,
          queryArray
        )
          .map((ft: any) => ft.facilityType)
          .filter(onlyUnique);
        this.filteredFacilityTypes.sort();
      }
    }
  }

  facilityNameSearch($event) {
    const detailGroup = this.form
      .get('OpAlertLog')
      .get('OpAlertLogLocation') as UntypedFormGroup;
    const facilityGroup = this.form
      .get('OpAlertLog')
      .get('OpAlertLogFacility') as UntypedFormGroup;
    const state = detailGroup.controls['state'].value;
    const countyContainer = detailGroup.controls['county'].value;
    const townshipContainer = detailGroup.controls['township'].value;
    const facilityType = facilityGroup.controls['facilityType'].value;
    const businessUnit = this.form.controls['businessUnit'].value;

    const queryArray: FilterCriteria[] = [];
    if (state) {
      queryArray.push(new FilterCriteria('state', state, 'include'));
    }
    if (countyContainer && countyContainer.county) {
      queryArray.push(
        new FilterCriteria('county', countyContainer.county, 'include')
      );
    }
    if (townshipContainer && townshipContainer.township) {
      queryArray.push(
        new FilterCriteria('township', townshipContainer.township, 'include')
      );
    }
    if (facilityType) {
      queryArray.push(
        new FilterCriteria('facilityType', facilityType, 'startsWith')
      );
    }
    if (businessUnit) {
      queryArray.push(
        new FilterCriteria('businessUnit.id', businessUnit.id, 'array-includes')
      );
    }
    if ($event.query !== '') {
      queryArray.push(
        new FilterCriteria('facilityName', $event.query, 'startsWith')
      );
    }

    let facilityNames = filterDropdownResults(this.facilities, queryArray);
    facilityNames.sort(dynamicSort('facilityName', 1));
    this.filteredFacilityNames = facilityNames;
    if (this.filteredFacilityNames.length === 1) {
      facilityGroup.patchValue({ facilityName: this.filteredFacilityNames[0] });
      this.facilityNameSelected(this.filteredFacilityNames[0]);
    }
  }

  facilityNameSelected($event) {
    this._facilityApi
      .getFacility($event.id)
      .pipe(take(1))
      .subscribe(
        ({ data }) => {
          let thisFacility = data.getFacility;
          if (thisFacility.Locations.length > 0) {
            const detailGroup = this.form
              .get('OpAlertLog')
              .get('OpAlertLogLocation') as UntypedFormGroup;

            let location = null;
            if (
              !isNullOrUndefined(detailGroup) &&
              !isNullOrUndefined(detailGroup.controls['township']) &&
              !isNullOrUndefined(detailGroup.controls['township'].value) &&
              !isNullOrUndefined(
                detailGroup.controls['township'].value.township
              )
            ) {
              const existingLoc = detailGroup.controls['township'].value;
              location = thisFacility.Locations.find(
                (loc) => loc.township === existingLoc.township
              );
            } else {
              location = thisFacility.Locations[0];
            }

            detailGroup.patchValue({
              locationId: location.id,
              state: location.state,
              county: { county: location.county, state: location.state },
              township: { township: location.township },
            });
            detailGroup.controls['state'].markAsDirty();
            detailGroup.controls['county'].markAsDirty();
            detailGroup.controls['township'].markAsDirty();
          }
          const group = this.form
            .get('OpAlertLog')
            .get('OpAlertLogFacility') as UntypedFormGroup;
          group.patchValue({
            facilityUid: $event,
            facilityType: thisFacility.FacilityType.name,
            aor: thisFacility.Aor.name,
            facilityId: thisFacility.id,
          });
          group.controls['facilityUid'].markAsDirty();
          group.controls['facilityType'].markAsDirty();
          group.controls['aor'].markAsDirty();
          group.controls['facilityId'].markAsDirty();

          // get the first business unit and set
          const businessUnit = thisFacility.businessUnit;
          if (businessUnit != null) {
            this.form.patchValue({
              businessUnit: businessUnit,
            });
            this.form.controls['businessUnit'].markAsDirty();
          }
        },
        (error) => {
          console.error(
            'Facility that was selected was not found in database!'
          );
          console.error(error);
          this._logAndMessage.errorMessageOnly(
            'Facility not found',
            'Selected Facility was not found in the database. Re-open the dropdown and try again.'
          );
          const group = this.form
            .get('OpAlertLog')
            .get('OpAlertLogFacility') as UntypedFormGroup;
          group.patchValue({
            facilityName: null,
          });
        }
      );
  }

  facilityIdSearch($event) {
    const detailGroup = this.form
      .get('OpAlertLog')
      .get('OpAlertLogLocation') as UntypedFormGroup;
    const facilityGroup = this.form
      .get('OpAlertLog')
      .get('OpAlertLogFacility') as UntypedFormGroup;
    const state = detailGroup.controls['state'].value;
    const countyContainer = detailGroup.controls['county'].value;
    const townshipContainer = detailGroup.controls['township'].value;
    const facilityType = facilityGroup.controls['facilityType'].value;
    const facilityNameContainer = facilityGroup.controls['facilityName'].value;
    const businessUnit = this.form.controls['businessUnit'].value;

    const queryArray: FilterCriteria[] = [];
    if (state) {
      queryArray.push(new FilterCriteria('state', state, 'include'));
    }
    if (countyContainer && countyContainer.county) {
      queryArray.push(
        new FilterCriteria('county', countyContainer.county, 'include')
      );
    }

    if (townshipContainer && townshipContainer.township) {
      queryArray.push(
        new FilterCriteria('township', townshipContainer.township, 'include')
      );
    }

    if (facilityType) {
      queryArray.push(
        new FilterCriteria('facilityType', facilityType, 'startsWith')
      );
    }

    if (facilityNameContainer && facilityNameContainer.facilityName) {
      queryArray.push(
        new FilterCriteria(
          'facilityName',
          facilityNameContainer.facilityName,
          'startsWith'
        )
      );
    }

    if (businessUnit) {
      queryArray.push(
        new FilterCriteria('businessUnit.id', businessUnit.id, 'array-includes')
      );
    }

    if ($event.query !== '') {
      queryArray.push(
        new FilterCriteria('facilityUid', $event.query, 'startsWith')
      );
    }

    this.filteredFacilityIds = filterDropdownResults(
      this.facilities,
      queryArray
    );
    this.filteredFacilityIds.sort();
    if (this.filteredFacilityIds.length === 1) {
      facilityGroup.patchValue({ facilityUid: this.filteredFacilityIds[0] });
      this.facilityIdSelected(this.filteredFacilityIds[0]);
    }
  }

  facilityIdSelected($event) {
    this._facilityApi
      .getFacility($event.id)
      .pipe(take(1))
      .subscribe(
        ({ data }) => {
          let thisFacility = data.getFacility;
          if (thisFacility.Locations.length > 0) {
            const detailGroup = this.form
              .get('OpAlertLog')
              .get('OpAlertLogLocation') as UntypedFormGroup;

            let location = null;
            if (
              !isNullOrUndefined(detailGroup) &&
              !isNullOrUndefined(detailGroup.controls['township']) &&
              !isNullOrUndefined(detailGroup.controls['township'].value) &&
              !isNullOrUndefined(
                detailGroup.controls['township'].value.township
              )
            ) {
              const existingLoc = detailGroup.controls['township'].value;
              location = thisFacility.Locations.find(
                (loc) => loc.township === existingLoc.township
              );
            } else {
              location = thisFacility.Locations[0];
            }

            detailGroup.patchValue({
              locationId: location.id,
              state: location.state,
              county: { county: location.county, state: location.state },
              township: { township: location.township },
            });
            detailGroup.controls['state'].markAsDirty();
            detailGroup.controls['county'].markAsDirty();
            detailGroup.controls['township'].markAsDirty();
          }
          const group = this.form
            .get('OpAlertLog')
            .get('OpAlertLogFacility') as UntypedFormGroup;
          group.patchValue({
            facilityName: $event,
            facilityType: thisFacility.FacilityType.name,
            aor: thisFacility.Aor.name,
            facilityId: thisFacility.id,
          });

          group.controls['facilityName'].markAsDirty();
          group.controls['facilityType'].markAsDirty();
          group.controls['aor'].markAsDirty();
          group.controls['facilityId'].markAsDirty();

          // get the first business unit and set
          const businessUnit = thisFacility.businessUnit;
          if (businessUnit != null) {
            this.form.patchValue({
              businessUnit: businessUnit,
            });
            this.form.controls['businessUnit'].markAsDirty();
          }
        },
        (error) => {
          console.error(
            'Facility that was selected was not found in database!'
          );
          console.error(error);
          this._logAndMessage.errorMessageOnly(
            'Facility not found',
            'Selected Facility was not found in the database. Re-open the dropdown and try again.'
          );
          const group = this.form
            .get('OpAlertLog')
            .get('OpAlertLogFacility') as UntypedFormGroup;
          group.patchValue({
            facilityUid: null,
          });
        }
      );
  }

  businessUnitSearch($event) {
    const detailGroup = this.form
      .get('OpAlertLog')
      .get('OpAlertLogLocation') as UntypedFormGroup;
    const facilityGroup = this.form
      .get('OpAlertLog')
      .get('OpAlertLogFacility') as UntypedFormGroup;
    const state = detailGroup.controls['state'].value;
    const countyContainer = detailGroup.controls['county'].value;
    const townshipContainer = detailGroup.controls['township'].value;
    const facilityType = facilityGroup.controls['facilityType'].value;
    const facilityNameContainer = facilityGroup.controls['facilityName'].value;

    const queryArray: FilterCriteria[] = [];
    if (state) {
      queryArray.push(new FilterCriteria('state', state, 'include'));
    }
    if (countyContainer && countyContainer.county) {
      queryArray.push(
        new FilterCriteria('county', countyContainer.county, 'include')
      );
    }
    if (townshipContainer && townshipContainer.township) {
      queryArray.push(
        new FilterCriteria('township', townshipContainer.township, 'include')
      );
    }

    if (facilityType) {
      queryArray.push(
        new FilterCriteria('facilityType', facilityType, 'startsWith')
      );
    }

    if (facilityNameContainer && facilityNameContainer.facilityName) {
      queryArray.push(
        new FilterCriteria(
          'facilityName',
          facilityNameContainer.facilityName,
          'startsWith'
        )
      );
    }

    if ($event.query !== '') {
      queryArray.push(
        new FilterCriteria('businessUnits', $event.query, 'startsWith')
      );
    }

    var filteredFacilities = filterDropdownResults(this.facilities, queryArray);
    this.filteredBusinessUnits = filteredFacilities
      .map((f) => f.businessUnits)
      .reduce((acc, val) => acc.concat(val), [])
      .filter(
        (val, index, self) => self.map((e) => e.id).indexOf(val.id) == index
      );

    // Ensure that we only show business units that the user has access to
    // even if one of their facilities has a BU that they don't have
    const userBusinessUnitNames = this._authApi
      .getUserBusinessUnits()
      .map((userBu) => userBu.name);
    this.filteredBusinessUnits = this.filteredBusinessUnits.filter((filterBu) =>
      userBusinessUnitNames.includes(filterBu.name)
    );

    this.filteredBusinessUnits.sort();
    if (this.filteredBusinessUnits.length == 1) {
      this.form.patchValue({ businessUnit: this.filteredBusinessUnits[0] });
    }
  }

  clearScreen() {
    this.buildForm();
  }

  saveOperatorLog(markLogAsDeleted) {
    this.saving = true;
    this._cdRef.detectChanges();
    this.isEditting = false;
    const dv = getDirtyValues(this.form);

    const updateStatement = this._opAlertLogBuilder.buildUpdateStatement(
      this.opLog,
      dv,
      markLogAsDeleted
    );
    updateStatement.updatedBy = this._auth.getUserName();

    if (this.opLog.id) {
      this.updateOperatorLog(updateStatement);
    } else {
      this.opLog.opLogId = null;
      this._logAndMessage.error(
        'Cannot create new Field Notification',
        'No Field Notification ID found.'
      );
    }
  }

  saveOperatorLogEntry(markLogAsDeleted) {
    this.saving = true;
    this._cdRef.detectChanges();
    this.isEditting = false;
    const dv = getDirtyValues(this.form);

    const updateStatement = this._opAlertLogBuilder.buildUpdateStatement(
      this.opLog,
      dv,
      markLogAsDeleted
    );
    updateStatement.updatedBy = this._auth.getUserName();

    this.updateOperatorLogEntry(updateStatement['OpAlertLogEntry']);
  }

  updateOperatorLog(updateStatement) {
    if (!this.canEdit) {
      this._logAndMessage.error(
        'Cannot update Field Notification!',
        'Logged in user does not have appropriate claim.'
      );
      return;
    }

    var inputObject: OpAlertLogInput = {
      id: updateStatement.id,
      opLogId: updateStatement.opLogId,
      opLogStatus: updateStatement.opLogStatus,
      facilityId: updateStatement.facilityId,
      state: updateStatement.state,
      county: updateStatement.county,
      township: updateStatement.township,
      businessUnitId: updateStatement.businessUnitId,
      createdAt: Math.round(
        (typeof updateStatement.createdAt !== 'string'
          ? updateStatement.createdAt.getTime()
          : new Date(updateStatement.createdAt).getTime()) / 1000
      ),
      createdBy: updateStatement.createdBy,
      updatedAt: Math.round(
        (typeof updateStatement.updatedAt !== 'string'
          ? updateStatement.updatedAt.getTime()
          : new Date(updateStatement.updatedAt).getTime()) / 1000
      ),
      updatedBy: updateStatement.updatedBy,
      markAsDeleted: updateStatement.markAsDeleted,
    };

    this._opMonitoringApi
      .editOpAlertLog(this.opLog, inputObject)
      .pipe(take(1))
      .subscribe(
        ({ data }) => {
          this.handleLogSave();
        },
        (error) => {
          console.log(error);
          this.saving = false;
        },
        () => {
          this.saving = false;
        }
      );
  }

  updateOperatorLogEntry(updateStatement) {
    if (!this.canEdit) {
      if (
        (!this.canEdit && !this.canEditOwnEntry) ||
        (this.canEditOwnEntry && !this.isOwnLog())
      ) {
        this._logAndMessage.error(
          'Cannot update Field Notification entry!',
          'Logged in user does not have appropriate claim.'
        );
        return;
      }
    }

    var inputObject: OpAlertLogEntryInput = {
      id: updateStatement.id,
      opAlertLogId: updateStatement.opAlertLogId,
      time: Math.round(
        (typeof updateStatement.time !== 'string'
          ? updateStatement.time.getTime()
          : new Date(updateStatement.time).getTime()) / 1000
      ),
      user: updateStatement.user,
      entry: updateStatement.entry,
      createdAt: Math.round(
        (typeof updateStatement.createdAt !== 'string'
          ? updateStatement.createdAt.getTime()
          : new Date(updateStatement.createdAt).getTime()) / 1000
      ),
      createdBy: updateStatement.createdBy,
      updatedAt: Math.round(
        (typeof updateStatement.updatedAt !== 'string'
          ? updateStatement.updatedAt.getTime()
          : new Date(updateStatement.updatedAt).getTime()) / 1000
      ),
      markAsDeleted: updateStatement.markAsDeleted,
    };

    if (updateStatement.dirtyStatus == DirtyStatus.NEW) {
      this._opMonitoringApi
        .createOpAlertLogEntry(inputObject)
        .pipe(take(1))
        .subscribe(
          ({ data }) => {
            this.handleLogSave();
          },
          (error) => {
            console.log(error);
            this.saving = false;
          },
          () => {
            this.saving = false;
          }
        );
    } else {
      this._opMonitoringApi
        .editOpAlertLogEntry(this.opLog, inputObject)
        .pipe(take(1))
        .subscribe(
          ({ data }) => {
            this.handleLogSave();
          },
          (error) => {
            console.log(error);
            this.saving = false;
          }
        );
    }
  }

  handleLogSave() {
    this._logAndMessage.translateToSuccessMessage({
      bodyKey: 'COMMON.MESSAGES.SUCCESS.SAVED_SUCCESSFULLY',
      headerKey: 'COMMON.MESSAGES.HEADERS.SUBMIT',
    });

    this._opMonitoringApi
      .getOpAlertLog(this.opLog.id)
      .pipe(take(1))
      .subscribe(({ data }) => {
        const clone = Object.assign({}, data);
        if (clone.getOpAlertLog) {
          this.opLog = this._opAlertLogBuilder.buildOpAlertLog(
            clone.getOpAlertLog
          );
          this.opLog.Facility = this._facilityBuilder.buildFacility(
            this.opLog.Facility,
            null
          );
          this.initialize();
        } else {
          // nothing found, go back to search.
          this._logAndMessage.errorLogOnly(
            'Could not find op alert log with ID: ' + this.opLog.id
          );

          if (this._deviceService.isMobile()) {
            const tab = TabService.getInstance().buildNewTab(
              ALL_OPLOG_SEARCH_CONTAINER,
              true
            );
            TabService.getInstance().setMobileTab(tab);
          } else {
            const index = TabService.getInstance().getActiveIndex();
            TabService.getInstance().closeTab(index);
          }
        }
        this.saving = false;
      });

    this.form.disable();
    this.form.markAsPristine();
    this.updateActionsList();

    this._cdRef.markForCheck();

    this.changesEvent.emit({ isDirty: false, index: this.index(), id: this.id });

    this._cdRef.markForCheck();

    setTimeout(() => {
      this.updateForm();
    }, 2000);
  }

  buildForm() {
    this.form = this._fb.group({
      businessUnit: [null, Validators.required],
      opLogStatus: null,
      OpAlertLog: this._fb.group({
        OpAlertLogLocation: this._fb.group({
          state: null,
          county: null,
          township: null,
        }),
        OpAlertLogFacility: this._fb.group({
          facilityName: null,
          facilityType: null,
          facilityUid: null,
          aor: [{ value: null, disabled: true }],
          pipeline: null,
          facilityId: null,
          businessUnit: null,
        }),
      }),
    });

    this.valueSub = this.form.valueChanges.subscribe((change) => {
      if (this.form.dirty) {
        this.changesEvent.emit({
          isDirty: true,
          index: this.index(),
          id: this.id,
        });
      }
    });
  }

  updateForm() {
    this.form.patchValue({
      opLogStatus: this.opLog.opLogStatus,
    });

    if (this.opLog) {
      if (this.opLog.Facility) {
        this.form
          .get('OpAlertLog')
          .get('OpAlertLogFacility')
          .patchValue({
            // facilityName and facilityUid are objects and not straight fields.  This is because of the selection/ auto update cases
            // these drop downs use.
            facilityName: { facilityName: this.opLog.Facility.facilityName },
            facilityUid: { facilityUid: this.opLog.Facility.facilityUid },
            facilityType: this.opLog.Facility.FacilityType.name,
            aor: this.opLog.Facility.aor,
            businessUnit: this.opLog.BusinessUnit,
          });
      }

      if (this.opLog) {
        this.form
          .get('OpAlertLog')
          .get('OpAlertLogLocation')
          .patchValue({
            state: this.opLog.state,
            // county and township are objects and not straight fields.  This is because of the selection/ auto update cases
            // these drop downs use.
            county: { county: this.opLog.county, state: this.opLog.state },
            township: { township: this.opLog.township },
          });
      }

      if (this.opLog.id === null) {
        const callGroup = this.form.get('OpAlertLog') as UntypedFormGroup;
        this.markFormGroupDirty(callGroup);
      }

      if (this.opLog.OpAlertLogAlarms.length > 0) {
        this.isAlarm = true;
      }
    }
    if (!this.isEditting) {
      this.form.disable();
    }
  }

  private markFormGroupDirty(formGroup: UntypedFormGroup) {
    (<any>Object).values(formGroup.controls).forEach((control) => {
      control.markAsDirty();

      if (control.controls) {
        this.markFormGroupDirty(control);
      }
    });
  }

  ngOnDestroy(): void {
    if (this.breadCrumbSub) {
      this.breadCrumbSub.unsubscribe();
    }

    if (this.sub) {
      this.sub.unsubscribe();
    }

    if (this.valueSub) {
      this.valueSub.unsubscribe();
    }

    if (this.tabClosingSub) {
      this.tabClosingSub.unsubscribe();
    }

    if (this.activeTabChangedSub) {
      this.activeTabChangedSub.unsubscribe();
    }
  }

  showEntryForm() {
    this.showEntryDialog = true;
  }

  cancelEntryForm() {
    this.addEntryForm.reset();
    this.showEntryDialog = false;
    this.addingAction = false;
  }

  private processOpAlertLogEntry(raw: any) {
    if (!raw.id) {
      this.opLog.OpAlertLogEntries = [
        ...this.opLog.OpAlertLogEntries,
        this._opAlertLogBuilder.buildNewOpAlertLogEntry(raw, this.opLog),
      ];
    } else {
      // need to re-open log.
      const actions = this.opLog.OpAlertLogEntries.filter(
        (a) => a.id === raw.id
      );
      const action = actions.length > 0 ? actions[0] : null;

      if (action) {
        (action.opAlertLogId = this.opLog.id),
          (action.entry = raw.statusUpdate);
        action.time = raw.entryTime ? raw.entryTime : new Date();
        action.dirtyStatus = DirtyStatus.UPDATED;
        action.updatedAt = new Date();
        action.markAsDeleted = false;
        action.createdBy = this._auth.getUserName();
        action.createdAt = raw.entryTime ? raw.entryTime : new Date();
        action.user = this._auth.getUserName();
      }
    }
  }

  addEntryToOperatorLog() {
    if (!this.canAddEntry) {
      if (!this.canAddOwnEntry || (this.canAddOwnEntry && !this.isOwnLog())) {
        this._logAndMessage.error(
          'Cannot add Field Notification entry!',
          'Logged in user does not have appropriate claim.'
        );
        return;
      }
    }

    const raw = this.addEntryForm.getRawValue();
    this.processOpAlertLogEntry(raw);

    this.saveOperatorLogEntry(false);
    this.addEntryForm.reset();
    this.showEntryDialog = false;
  }

  editEntry($event, isAction = false) {
    if (!this.canEdit) {
      if (!this.canEditOwnEntry || (this.canEditOwnEntry && !this.isOwnLog())) {
        this._logAndMessage.error(
          'Cannot edit Field Notification entry!',
          'Logged in user does not have appropriate claim.'
        );
        return;
      }
    }
    this.addEntryForm.patchValue({
      id: $event.id,
      statusUpdate: $event.entry,
      entryTime: $event.time,
    });
    this.addingAction = isAction;
    this.showEntryDialog = true;
  }

  deleteEntry($event, isAction = false) {
    if (!this.canDelete) {
      if (
        !this.canDeleteOwnEntry ||
        (this.canDeleteOwnEntry && !this.isOwnLog())
      ) {
        this._logAndMessage.error(
          'Cannot delete Field Notification entry!',
          'Logged in user does not have appropriate claim.'
        );
        return;
      }
    }

    this._confirmationService.confirm({
      header: this.deleteEntryHeader,
      icon: 'fa fa-question-circle',
      acceptVisible: true,
      rejectVisible: true,
      acceptLabel: this.okBtnLabel,
      rejectLabel: this.cancelBtnLabel,
      message: this.deleteEntryMsg,
      accept: () => {
        if (isAction) {
          this.opLog.OpAlertLogEntries.filter(
            (a) => a.id === $event.id
          ).forEach((a) => {
            a.deletedAt = new Date();
            a.dirtyStatus = DirtyStatus.DELETED;
            a.markAsDeleted = true;
            a.opAlertLogId = this.opLog.id;
          });
        } else {
          this.opLog.OpAlertLogEntries.filter(
            (a) => a.id === $event.id
          ).forEach((a) => {
            a.deletedAt = new Date();
            a.dirtyStatus = DirtyStatus.DELETED;
            a.markAsDeleted = true;
            a.opAlertLogId = this.opLog.id;
          });
        }
        this.saveOperatorLogEntry(true);
      },
      reject: () => {
        this.saving = false;
      },
    });
  }

  /**
   * Need to manually remove the ___-___-____ mask from the input mask so
   * the validation works.
   * @param field
   */
  fixMask(field) {
    const detailGroup = this.form
      .get('OpAlertLog')
      .get('OpAlertLogLocation') as UntypedFormGroup;
    const value: string = detailGroup.controls[field]
      ? detailGroup.controls[field].value
      : null;
    if (value && value.indexOf('_') > -1) {
      this.form.controls[field].setValue('');
      this.form.updateValueAndValidity();
    }
  }

  updateActionsList() {
    const selectAction$ = this._translateService.get(
      'OPMONITORING.LABEL.SELECT_ACTION'
    );
    const deleteLog$ = this._translateService.get(
      'OPMONITORING.LABEL.DELETE_LOG'
    );
    const print$ = this._translateService.get('COMMON.LABEL.PRINT');

    let select,
      del,
      print = null;
    forkJoin([selectAction$, deleteLog$, print$])
      .pipe(take(1))
      .subscribe((messages) => {
        select = messages[0];
        del = messages[1];
        print = messages[2];

        this.actions = [
          { label: select, value: null },
          { label: print, value: PRINT },
        ];

        if (this.canDelete) {
          this.actions.push({ label: del, value: DELETE_LOG });
        }

        // default actions here: Send to Day Crew, etc.
        this.actions = [...this.actions];
      });
  }

  actionEvent($event, object?: any) {
    if ($event.value) {
      this.selectedAction = null;
      if ($event.value) {
        if ($event.value === DELETE_LOG) {
          //#######################################################################
          this.deleteOpAlertLog();
          this.ngOnInit(); //reloading page
          //#######################################################################
        } else if ($event.value === PRINT) {
          //#######################################################################
          const tab = TabService.getInstance().buildNewTab(
            OPALERTLOG_PRINT_CONTAINER,
            true,
            null,
            this.opLog.id
          );
          if (this._deviceService.isMobile()) {
            TabService.getInstance().setMobileTab(tab);
          } else {
            TabService.getInstance().openTab(tab);
          }
        }
        //#######################################################################
      }

      if (!isNullOrUndefined(object)) {
        object.clear(null);
      }

      if ($event.value === DELETE_LOG) {
        //#######################################################################
        this.deleteOpAlertLog();
        this.ngOnInit(); //reloading page
        //#######################################################################
      } else if ($event.value === PRINT) {
        //#######################################################################
        const tab = TabService.getInstance().buildNewTab(
          OPALERTLOG_PRINT_CONTAINER,
          true,
          null,
          this.opLog.id
        );
        if (this._deviceService.isMobile()) {
          TabService.getInstance().setMobileTab(tab);
        } else {
          TabService.getInstance().openTab(tab);
        }
      }
      //#######################################################################
    }
    this._cdRef.markForCheck();
  }

  deleteOpAlertLog(): void {
    if (!this.canDelete) {
      this._logAndMessage.error(
        'Cannot delete Field Notification!',
        'Logged in user does not have appropriate claim.'
      );
      return;
    }

    this._confirmationService.confirm({
      header: this.deleteHeader,
      icon: 'fa fa-question-circle',
      acceptVisible: true,
      rejectVisible: true,
      acceptLabel: this.okBtnLabel,
      rejectLabel: this.cancelBtnLabel,
      message: this.deleteMsg,
      accept: () => {
        this.saving = true;
        this._cdRef.detectChanges();
        this.saveOperatorLog(true);

        if (this._deviceService.isMobile()) {
          const tab = TabService.getInstance().buildNewTab(
            ALL_OPLOG_SEARCH_CONTAINER,
            true
          );
          TabService.getInstance().setMobileTab(tab);
        } else {
          const index = TabService.getInstance().getActiveIndex();
          TabService.getInstance().closeTab(index);
        }
      },
      reject: () => {
        this.saving = false;
      },
    });
  }

  isOwnLog(): boolean {
    if (this.opLog != null) {
      return this._auth.getUserName() == this.opLog.createdBy;
    }

    return false;
  }

  resetFacility() {
    const detailGroup = this.form
      .get('OpAlertLog')
      .get('OpAlertLogLocation') as UntypedFormGroup;
    detailGroup.patchValue({
      locationId: null,
      state: null,
      county: null,
      township: null,
    });
    detailGroup.controls['state'].markAsDirty();
    detailGroup.controls['county'].markAsDirty();
    detailGroup.controls['township'].markAsDirty();

    const group = this.form
      .get('OpAlertLog')
      .get('OpAlertLogFacility') as UntypedFormGroup;
    group.patchValue({
      facilityName: null,
      facilityType: null,
      aor: null,
      facilityId: null,
      facilityUid: null,
    });

    group.controls['facilityName'].markAsDirty();
    group.controls['facilityType'].markAsDirty();
    group.controls['aor'].markAsDirty();
    group.controls['facilityId'].markAsDirty();
  }

  validateForm() {
    this.addEntryForm.updateValueAndValidity();
  }

  indexChanged($event): void {
    if (!isNullOrUndefined($event)) {
      this.tabIndex = $event.index;
    }
  }
}

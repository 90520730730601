<p-dialog
  [modal]="true"
  [(visible)]="isVisible"
  (onHide)="onDialogHide()"
  [dismissableMask]="true"
  [header]="formType + ' Station Log'"
  [styleClass]="!isLoading ? 'main-dialog' : ''"
  [focusOnShow]="false"
>
  <app-progress-spinner [enabled]="saving"></app-progress-spinner>
  @if (!isLoading) {
  <div class="container">
    <div class="center">
      <form [formGroup]="stationLogForm">
        <div formGroupName="stationLog">
          @if (this.formType === 'Edit') {
          <div class="actions-dropdown">
            <span class="label">{{ "COMMON.LABEL.ACTIONS" | translate }}:</span>
            <span class="align-left">
              <p-dropdown
                [options]="actions"
                (onChange)="actionEvent($event, dd)"
                [(ngModel)]="selectedAction"
                [ngModelOptions]="{ standalone: true }"
                styleClass="action-drop-down"
                #dd
              ></p-dropdown>
            </span>
          </div>
          }
          <div class="card-header">
            <h2>{{ "OPMONITORING.LABEL.STATION_LOG_DETAILS" | translate }}</h2>
          </div>
          <div class="station-log-details">
            <div class="row">
              <div id="createdAt" class="text-center" class="row-item">
                <label for="createdAt"
                  >{{ "OPMONITORING.LABEL.DATE" | translate }}:
                  <span class="required">*</span>
                </label>
                <p-calendar
                  class="input"
                  formControlName="createdAt"
                  appendTo="body"
                  #calender
                >
                </p-calendar>
              </div>

              <div class="row-item">
                <label for="reason"
                  >{{ "OPMONITORING.LABEL.REASON" | translate }}:
                  <span class="required">*</span></label
                >
                <p-dropdown
                  class="input"
                  formControlName="reason"
                  [options]="stationLogReasons"
                  optionLabel="reason"
                  placeholder="Select a Reason"
                  appendTo="body"
                  scrollHeight="calc(100vh-35vh)"
                ></p-dropdown>
              </div>

              <div class="row-item">
                <label for="unitHours"
                  >{{ "OPMONITORING.LABEL.UNIT_HOURS" | translate }}:
                </label>
                <p-inputNumber
                  mode="decimal"
                  formControlName="unitHours"
                  class="p-inputNumber"
                  appendTo="body"
                />
              </div>
            </div>
            <div class="text-row">
              <div class="text-item">
                <label for="message"
                  >{{ "OPMONITORING.LABEL.ALARM_OR_SHUTDOWN" | translate }}:
                </label>
                <textarea
                  pInputTextarea
                  class="input"
                  [rows]="3"
                  [cols]="100"
                  formControlName="message"
                  appendTo="body"
                ></textarea>
              </div>

              <div class="text-item">
                <label for="fixSolution"
                  >{{ "OPMONITORING.LABEL.FIX_SOLUTION" | translate }}:
                </label>
                <textarea
                  pInputTextarea
                  class="input"
                  [rows]="3"
                  [cols]="100"
                  formControlName="fixSolution"
                  appendTo="body"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
        <p-divider></p-divider>
        <div formGroupName="contactLocInfo" class="info-form">
          <div class="card-header">
            <h2>
              {{ "OPMONITORING.LABEL.FACILITY_STATION_INFO" | translate }}
            </h2>
          </div>
          <div class="row">
            <div class="facility-name row-item facility-name-row-item">
              <label for="facility"
                >{{ "OPMONITORING.LABEL.FACILITY" | translate }}:
                <span class="required">*</span></label
              >
              <p-dropdown
                class="input"
                formControlName="facility"
                placeholder="Select a Facility"
                [options]="facilities"
                optionLabel="facilityName"
                [filter]="true"
                filterBy="facilityName"
                (onChange)="onFacilityChange($event)"
                appendTo="body"
                [virtualScroll]="true"
                [virtualScrollItemSize]="30"
              >
              </p-dropdown>
            </div>
            <div class="facility-id row-item">
              <label for="facilityId"
                >{{ "OPMONITORING.LABEL.FACILITY_ID" | translate }}:
                <span class="required">*</span></label
              >
              <p-dropdown
                class="facility-id-input"
                formControlName="facilityId"
                placeholder="Select a Facility Id"
                [options]="facilityIds"
                optionLabel="facilityUid"
                [filter]="true"
                filterBy="facilityUid"
                (onChange)="onFacilityIdChange($event)"
                appendTo="body"
                [virtualScroll]="true"
                [virtualScrollItemSize]="30"
              >
              </p-dropdown>
            </div>
          </div>
        </div>
        @if (this.showFormBasedOnPermissions()) {
        <button
          pButton
          pRipple
          type="button"
          label="Save"
          class="p-button-raised p-button-success save-btn"
          [disabled]="!this.stationLogForm.valid || !this.stationLogForm.dirty"
          (click)="onSubmit()"
        ></button>
        }
      </form>
    </div>
  </div>
  }
</p-dialog>
<p-toast></p-toast>

<!--
    For some reason I had to put this in the if to stop it from showing as a panel after closing the dialog once
    I don't know why it was happening like that but this resolves it
-->
@if (displayAuditDialog) {
<app-audit-dialog-container
  [displayDialog]="displayAuditDialog"
  [auditHistory]="history"
  (dialogHiddenEvent)="auditDialogHidden()"
  [showAsDialog]="true"
  [loading]="auditLoading"
></app-audit-dialog-container>
}

<p-dialog
  header="Notification Limit Information"
  [modal]="true"
  [(visible)]="isVisible"
  (onHide)="onDialogHide($event)"
  [dismissableMask]="true"
  [contentStyle]="{ overflow: 'visible' }"
  >
  <app-progress-spinner [enabled]="sending || loading"></app-progress-spinner>
  @if (!sending && !loading) {
    <div class="container">
      <form [formGroup]="alarmMonitoring" #requestAM>
        <div id="alarm-monitoring-request">
          <div>
            <label class="label"
              >{{ "COMMON.LABEL.BUSINESS_UNITS" | translate }}:</label
              >
              <div>
                <p-dropdown
                  [options]="businessUnits"
                  optionLabel="name"
                  formControlName="businessUnit"
                  [style]="{ width: '100%' }"
                  placeholder="Select a Business Unit"
                  [virtualScroll]="true"
                  [filter]="true"
                  [virtualScrollItemSize]="30"
                  (onChange)="onBusinessUnitChanged($event)"
                ></p-dropdown>
              </div>
            </div>
            <div>
              <label class="label"
                >{{ "LOCATION.LABEL.FACILITY_UID" | translate }}:</label
                >
                <div>
                  <p-dropdown
                    [options]="facilityUIds"
                    optionalLabel="label"
                    formControlName="facilityUId"
                    [style]="{ width: '100%' }"
                    placeholder="Select a Facility UID"
                    [virtualScroll]="true"
                    [filter]="true"
                    [virtualScrollItemSize]="30"
                    (onChange)="onFacilityUIdChanged($event)"
                  ></p-dropdown>
                </div>
              </div>
              <div>
                <label class="label"
                  >{{ "OPMONITORING.LABEL.MONITORED_ITEM" | translate }}:</label
                  >
                  <div>
                    <input
                      class="request-input"
                      type="text"
                      pInputText
                      formControlName="monitoredItem"
                      />
                    </div>
                  </div>
                  <div>
                    <label class="label"
                      >{{ "OPMONITORING.LABEL.ADDITIONAL_INFO" | translate }}:</label
                      >
                      <div class="no-padding-top-bottom additional-info input-field">
                        <textarea
                          pInputTextarea
                          autoResize="autoResize"
                          formControlName="additionalInfo"
                          rows="4"
                          cols="30"
                        ></textarea>
                      </div>
                    </div>
                    <div class="button">
                      <button
                        pButton
                        pRipple
                        label="Send"
                        icon="fa fa-fw fa-envelope"
                        class="p-button-raised p-button-success right-align"
                        (click)="onSubmit()"
                        [disabled]="!alarmMonitoring.valid"
                      ></button>
                    </div>
                  </div>
                </form>
              </div>
            }
          </p-dialog>

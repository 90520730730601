import {
  Component,
  Input,
  input,
  OnInit,
  output,
  inject,
  ChangeDetectorRef,
} from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { take } from 'rxjs/operators';
import { AuthApiService } from 'src/app/services/auth-api.service';
import { FacilityApiService } from 'src/app/services/facility-api.service';
import { isNullOrUndefined, sortByProperty } from 'src/app/utils/utils';
import { Facility } from 'src/app/model/locations/facility';
import { FacilityBuilderService } from 'src/app/services/facility-builder.service';
import { StationLogApiService } from 'src/app/services/station-log-api.service';
import { StationLogReason } from 'src/app/model/station-logs/station-log-reason';
import {
  CreateOrEditStationLog,
  StationLog,
} from 'src/app/model/station-logs/station-log';
import { LogAndMessageService } from 'src/app/services/log-and-message.service';
import { StationLogBuilderService } from 'src/app/services/station-log-builder.service';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import {
  DELETE_STATION_LOG,
  VIEW_AUDIT,
} from 'src/app/constants/action-constants';
import { forkJoin } from 'rxjs';
import { Dropdown } from 'primeng/dropdown';
import { Audit } from 'src/app/model/common/audit';
import { CLAIMS } from 'src/app/constants/auth-constants';

@Component({
  selector: 'app-station-log-form',
  templateUrl: './station-log-form.component.html',
  styleUrls: ['./station-log-form.component.scss'],
  standalone: false,
})
export class StationLogFormComponent implements OnInit {
  @Input() isVisible: boolean = false;
  @Input() formType: string = 'Create';
  @Input() logId: string = '';
  @Input() isLoading: boolean = false;
  @Input() canCreate: boolean = false;
  @Input() canEdit: boolean = false;

  private _authApi = inject(AuthApiService);
  private _translateService = inject(TranslateService);
  private _confirmationService = inject(ConfirmationService);
  private _cdRef = inject(ChangeDetectorRef);

  readonly selectedStationLog = input<StationLog>(undefined);
  readonly formHidden = output();
  readonly formSubmitted = output<StationLog>();
  readonly stationLogDeleted = output();
  readonly ready = output();

  entryFormVisible: boolean = false;
  facilities: Facility[];
  facilityIds: any[];
  totalFacilities: Facility[];
  facilitiesLoading: boolean = true;
  selectedFacility: Facility;
  stationLogReasons: StationLogReason[];
  stationLogReasonsLoading: boolean = true;
  initialValues: StationLog;
  hasChanges: boolean = false;
  showButton = true;
  actions: SelectItem[] = [];
  selectedAction: any = null;
  canDelete: boolean = false;
  saving: boolean = false;
  auditLoading: boolean = false;
  displayAuditDialog: boolean = false;
  history: Audit[];

  deleteMsg: string;
  okBtnLabel: string;
  cancelBtnLabel: string;
  deleteHeader: string;

  stationLogForm = new UntypedFormGroup({
    stationLog: new UntypedFormGroup({
      logId: new UntypedFormControl(''),
      reason: new UntypedFormControl('', Validators.required),
      createdAt: new UntypedFormControl(new Date(), Validators.required),
      message: new UntypedFormControl(''),
      fixSolution: new UntypedFormControl(''),
      unitHours: new UntypedFormControl(''),
    }),
    contactLocInfo: new UntypedFormGroup({
      facility: new UntypedFormControl(null, Validators.required),
      facilityId: new UntypedFormControl(null, Validators.required),
    }),
  });

  constructor(
    private _facilityAPI: FacilityApiService,
    private _stationLogApi: StationLogApiService,
    private _stationLogBuilder: StationLogBuilderService,
    protected _facilityBuilder: FacilityBuilderService,
    protected _logAndMessage: LogAndMessageService
  ) {
    this.facilities = [];
    this.totalFacilities = [];
    this.isLoading = true;
    this.prepareForm();
  }

  ngOnInit(): void {
    this.canDelete = this._authApi.doesUserHaveAllClaimsFromList([
      CLAIMS.FIELD_OPERATIONS.STATION_LOGS.DELETE_STATION_LOGS,
    ]);
    this.loadStationLogReasons();
    this.loadFacilities();
    this.updateActionsList();
  }

  loadFacilities() {
    this._facilityAPI.getFacilities().subscribe(
      (data) => {
        this.facilitiesLoading = true;
        const facilitiesClone = [...data];

        this.facilities = facilitiesClone.map((f: any) =>
          this._facilityBuilder.buildFacility(f, f.Locations)
        );
        this.facilities.sort((a, b) => sortByProperty(a, b, 'facilityName'));

        this.facilityIds = [...this.facilities];
        this.facilityIds = this.facilityIds.sort((a, b) =>
          sortByProperty(a, b, 'facilityUid')
        );

        this.totalFacilities = this.facilities;
        this.facilitiesLoading = false;
      },
      (error) => {
        this.facilities = [];
        this.facilitiesLoading = false;
        this._logAndMessage.errorLogOnly(error);
        this._logAndMessage.translateToErrorMessage({
          bodyKey: 'COMMON.MESSAGES.ERROR.LOAD_FACILITIES',
          headerKey: 'COMMON.MESSAGES.HEADERS.ERROR',
        });
      }
    );
  }

  loadStationLogReasons() {
    this._stationLogApi.getStationLogReasons().subscribe(
      (reasons: StationLogReason[]): any => {
        this.stationLogReasons = reasons.sort((a, b) =>
          sortByProperty(a, b, 'reason')
        );
        this.stationLogReasonsLoading = false;
      },
      (error) => {
        this.stationLogReasons = [];
        this.stationLogReasonsLoading = false;
        this._logAndMessage.errorLogOnly(error);
        this._logAndMessage.translateToErrorMessage({
          bodyKey: 'OPMONITORING.MESSAGES.ERROR.LOAD_REASONS',
          headerKey: 'COMMON.MESSAGES.HEADERS.ERROR',
        });
      }
    );
  }

  resetEffectiveDate() {
    this.stationLogForm.patchValue({
      createdAt: new Date(),
    });
  }

  enableForm() {
    this.stationLogForm.enable();
  }

  disableForm() {
    this.stationLogForm.disable();
  }

  onDialogHide() {
    this.formHidden.emit();
    this.hasChanges = false;
    this.stationLogForm.reset();

    setTimeout(() => {
      this.stationLogForm.enable();
      this.stationLogForm.markAsPristine();
      this.stationLogForm.patchValue({
        stationLog: {
          logId: null,
          createdAt: new Date(),
          reason: null,
          message: null,
          fixSolution: null,
          unitHours: null,
        },
      });
    }, 50);
  }

  onSubmit() {
    if (!this.stationLogForm.valid) {
      // failsafe to prevent inspect element from making the submit button enabled
      console.log('Form was somehow submitted without being valid!');
      return;
    }

    let logToSubmit = this._stationLogBuilder.buildCreateOrEditStationLog(
      this.stationLogForm,
      this.selectedFacility
    );
    if (this.formType === 'Create') {
      this.addStationLog(logToSubmit);
    }

    if (this.formType === 'Edit') {
      this.editStationLog(logToSubmit);
    }
  }

  addStationLog(logToSubmit: CreateOrEditStationLog) {
    this.saving = true;
    this._stationLogApi.createStationLog(logToSubmit).subscribe({
      next: (newStationLog) => {
        this.formSubmitted.emit(newStationLog);
        this.isVisible = false;
        this.saving = false;
      },
      error: (error) => {
        console.error(error);
        this.saving = false;
        this._logAndMessage.errorLogOnly(error);
        this._logAndMessage.translateToErrorMessage({
          bodyKey: 'OPMONITORING.MESSAGES.ERROR.CREATE_STATION_LOG',
          headerKey: 'COMMON.MESSAGES.HEADERS.ERROR',
        });
      },
    });
  }

  editStationLog(logToSubmit: CreateOrEditStationLog) {
    this.saving = true;
    this._stationLogApi.editStationLog(logToSubmit).subscribe({
      next: (editedStationLog) => {
        this.formSubmitted.emit(editedStationLog);
        this.isVisible = false;
        this.saving = false;
      },
      error: (error) => {
        console.error(error);
        this.saving = false;
        this._logAndMessage.errorLogOnly(error);
        this._logAndMessage.translateToErrorMessage({
          bodyKey: 'OPMONITORING.MESSAGES.ERROR.EDIT_STATION_LOG',
          headerKey: 'COMMON.MESSAGES.HEADERS.ERROR',
        });
      },
    });
  }

  updateFields() {
    const selectedStationLog = this.selectedStationLog();

    this.stationLogForm.patchValue({
      stationLog: {
        logId: selectedStationLog.id,
        createdAt: new Date(selectedStationLog.date),
        reason: selectedStationLog.reason,
        message: selectedStationLog.message,
        fixSolution: selectedStationLog.fixSolution,
        unitHours: selectedStationLog.unitHours,
      },
      contactLocInfo: {
        facility: this.facilities.find(
          (facility) =>
            facility.facilityName === selectedStationLog.facility.facilityName
        ),
        facilityId: this.facilities.find(
          (facility) =>
            facility.facilityUid === selectedStationLog.facility.facilityUid
        ),
      },
    });
  }

  haveWeLoadedEverything() {
    return !this.stationLogReasonsLoading && !this.facilitiesLoading;
  }

  showFormBasedOnPermissions() {
    if (this.formType === 'Create' && this.canCreate) {
      return true;
    }

    if (this.formType === 'Edit' && this.canEdit) {
      return true;
    }

    this.disableForm();
    return false;
  }

  prepareForm() {
    if (this.haveWeLoadedEverything()) {
      this.ready.emit();
    }
  }

  onFacilityChange($event: any) {
    this.selectedFacility = $event.value;

    this.stationLogForm.patchValue({
      contactLocInfo: {
        facilityId: this.selectedFacility,
      },
    });
  }

  onFacilityIdChange($event: any) {
    this.selectedFacility = $event.value;

    this.stationLogForm.patchValue({
      contactLocInfo: {
        facility: this.selectedFacility,
      },
    });
  }

  updateActionsList() {
    const selectAction$ = this._translateService.get(
      'COMMON.LABEL.SELECT_ACTION'
    );
    const delete$ = this._translateService.get('COMMON.LABEL.BUTTONS.DELETE');
    const viewAudit$ = this._translateService.get(
      'COMMON.LABEL.VIEW_AUDIT_INFO'
    );

    let select,
      del,
      audit = null;
    forkJoin([selectAction$, delete$, viewAudit$])
      .pipe(take(1))
      .subscribe((messages) => {
        select = messages[0];
        del = messages[1];
        audit = messages[2];

        this.actions = [
          { label: select, value: null },
          { label: audit, value: VIEW_AUDIT },
        ];

        if (this.canDelete) {
          this.actions.push({ label: del, value: DELETE_STATION_LOG });
        }

        // default actions here
        this.actions = [...this.actions];
      });
  }

  buildConfirmationMessages() {
    const deleteMsg$ = this._translateService.get(
      'OPMONITORING.MESSAGES.CONFIRMATION.DELETE_STATION_LOG'
    );
    const ok$ = this._translateService.get('COMMON.LABEL.BUTTONS.YES');
    const cancel$ = this._translateService.get('COMMON.LABEL.BUTTONS.NO');
    const deleteHeader$ = this._translateService.get(
      'OPMONITORING.MESSAGES.HEADERS.DELETE_STATION_LOG'
    );
    forkJoin([deleteMsg$, ok$, cancel$, deleteHeader$]).subscribe(
      (messages) => {
        this.deleteMsg = messages[0];
        this.okBtnLabel = messages[1];
        this.cancelBtnLabel = messages[2];
        this.deleteHeader = messages[3];
      }
    );
  }

  deleteStationLog() {
    if (!this.canDelete) {
      return;
    }

    this.buildConfirmationMessages();
    this._confirmationService.confirm({
      header: this.deleteHeader,
      icon: 'fa fa-question-circle',
      acceptVisible: true,
      rejectVisible: true,
      acceptLabel: this.okBtnLabel,
      rejectLabel: this.cancelBtnLabel,
      message: this.deleteMsg,
      accept: () => {
        this.saving = true;
        this.disableForm();
        this._cdRef.detectChanges();
        this._stationLogApi
          .deleteStationLog(this.selectedStationLog().id)
          .pipe(take(1))
          .subscribe({
            next: (deleteStationLog) => {
              this.saving = false;
              this.isVisible = false;
              this.stationLogDeleted.emit();
              this._logAndMessage.translateToSuccessMessage(
                {
                  headerKey: 'COMMON.MESSAGES.HEADERS.DELETE',
                  bodyKey: 'COMMON.MESSAGES.SUCCESS.DELETE_SUCCESS_MSG',
                },
                true
              );
            },
            error: (error) => {
              this.saving = false;
              this.isVisible = false;
              console.error(error);
              this._logAndMessage.errorLogOnly(error);
              this._logAndMessage.translateToErrorMessage({
                headerKey: 'COMMON.MESSAGES.HEADERS.DELETE_ERROR',
                bodyKey: 'COMMON.MESSAGES.ERROR.DELETE_ERROR_MSG',
              });
            },
          });
      },
      reject: () => {
        this.saving = false;
      },
    });
  }

  viewAudit() {
    this.auditLoading = true;
    this.displayAuditDialog = true;
    this._stationLogApi
      .getStationLogHistory(this.selectedStationLog().id)
      .subscribe({
        next: (data) => {
          this.history = data;
        },
        error: (error) => {
          this.auditLoading = false;
        },
        complete: () => {
          this.auditLoading = false;
        },
      });
  }

  auditDialogHidden(): void {
    this.displayAuditDialog = false;
  }

  actionEvent($event, object?: Dropdown) {
    if ($event.value) {
      this.selectedAction = null;
      if ($event.value === 'delete-station-log') {
        this.deleteStationLog();
      }
      if ($event.value === 'view-audit') {
        this.viewAudit();
      }
      if (!isNullOrUndefined(object)) {
        object.clear(null);
      }
    }

    this._cdRef.markForCheck();
  }
}

import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService } from 'primeng/api';
import { Subscription, forkJoin, of } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { TabService } from 'src/app/core/services/tab.service';
import { AlarmLimit } from 'src/app/model/operational-monitoring/alarm-limit';
import { PiConfigurableElement } from 'src/app/model/operational-monitoring/pi-configurable-element';
import { AuthService } from 'src/app/services/auth.service';
import { DeviceService } from 'src/app/services/device.service';
import { LoadingService } from 'src/app/services/loading.service';
import { LogAndMessageService } from 'src/app/services/log-and-message.service';
import { OperationalMonitoringApiService } from 'src/app/services/operational-monitoring-api.service';
import { isNullOrUndefined } from 'util';

@Component({
    selector: 'app-sync-alarm-limits-container',
    templateUrl: './sync-alarm-limits-container.component.html',
    styleUrls: ['./sync-alarm-limits-container.component.scss'],
    standalone: false
})
export class SyncAlarmLimitsContainerComponent implements OnInit, OnDestroy {
  protected _deviceService = inject(DeviceService);
  protected _auth = inject(AuthService);
  protected _loader = inject(LoadingService);
  private _translateService = inject(TranslateService);
  protected _opMonitoringApi = inject(OperationalMonitoringApiService);
  protected _logAndMessage = inject(LogAndMessageService);
  private _confirmationService = inject(ConfirmationService);

  dialogHeader: string;
  screenName: string;
  refreshSubscription: Subscription;

  afItemsArray: PiConfigurableElement[];
  afAlarmLimit: PiConfigurableElement;
  crlincItemsArray: AlarmLimit[];
  attributeNames: string = '';
  loading: boolean = false;
  saving: boolean = false;
  syncComplete: boolean = false;
  missingAlarmLimits: PiConfigurableElement[] = [];

  ngOnInit() {
    const screenName$ = this._translateService.get(
      'OPMONITORING.SCREEN.SYNC_ALARM_LIMITS'
    );
    const dialogHeader$ = this._translateService.get(
      'OPMONITORING.SCREEN.SYNC_ALARM_LIMITS'
    );
    forkJoin([screenName$, dialogHeader$]).subscribe((label) => {
      TabService.getInstance().updateActiveTabLabel(label[0]);
      this.screenName = label[0];
      this.dialogHeader = label[1];
    });
  }

  syncElements() {
    this.loadSyncData();
  }

  loadSyncData() {
    this.loading = true;
    this.afItemsArray = [];
    this.crlincItemsArray = [];
    let observables = [];
    // Handle individual errors for each request and allows forkJoin to complete other load request.
    observables.push(
      this._opMonitoringApi.getPIConfigurableElements().pipe(
        catchError((error) => {
          console.error(error);
          this._logAndMessage.translateToErrorMessage({
            bodyKey: 'OPMONITORING.MESSAGES.ERROR.GET_AF_ALARM_LIMITS',
            headerKey: 'COMMON.MESSAGES.HEADERS.ERROR',
          });
          return of(undefined);
        })
      ),
      this._opMonitoringApi.getAlarmLimits().pipe(
        catchError((error) => {
          console.error(error);
          this._logAndMessage.translateToErrorMessage({
            bodyKey: 'OPMONITORING.MESSAGES.ERROR.GET_CRLINC_ALARM_LIMITS',
            headerKey: 'COMMON.MESSAGES.HEADERS.ERROR',
          });
          return of(undefined);
        })
      )
    );

    forkJoin(observables)
      .pipe(take(1))
      .subscribe((responses: any) => {
        const afAlarmLimitClone = [
          ...responses[0].data.getPIConfigurableElements,
        ];
        this.crlincItemsArray = [...responses[1].data.getAlarmLimits];

        this.crlincItemsArray.forEach((item) => {
          item.synced = false;
        });

        afAlarmLimitClone.forEach((item) => {
          item.configurableAttributes.forEach((an) => {
            this.afAlarmLimit = {
              ...item,
              alarmAttributeName: an.name,
              datatype: an.datatype,
              children: an.children,
              synced: false,
            };
            this.afItemsArray.push(this.afAlarmLimit);

            if (this.afAlarmLimit.children) {
              this.afAlarmLimit.children.forEach((childLimit) => {
                this.afAlarmLimit = {
                  ...item,
                  alarmAttributeName: childLimit.name,
                  datatype: childLimit.datatype,
                  synced: false,
                };

                this.afItemsArray.push(this.afAlarmLimit);
              });
            }
          });
        });

        this.checkMissingLimits();
      });
  }

  checkMissingLimits() {
    //Check if any AF Alarm Limits are missing from CRLINC
    this.missingAlarmLimits = [];
    this.afItemsArray.forEach((af) => {
      let alarmLimit = this.crlincItemsArray.find((crlincItem) => {
        return (
          crlincItem.Facility.facilityUid === af.facilityUid &&
          crlincItem.alarmAttributeName === af.alarmAttributeName &&
          crlincItem.assetIdentifier === af.assetIdentifier &&
          crlincItem.datatype === af.datatype
        );
      });

      if (!isNullOrUndefined(alarmLimit)) {
        af.synced = true;
      } else {
        this.missingAlarmLimits.push(af);
      }

      if (this.afItemsArray.length > 0 && this.missingAlarmLimits.length > 0) {
        this.syncComplete = true;
      }
    });

    //Check if any CR LINC Alarm Limits are missing from AF
    this.crlincItemsArray.forEach((crlinc) => {
      let alarmLimit = this.afItemsArray.find((afItem) => {
        return (
          afItem.facilityUid === crlinc.Facility.facilityUid &&
          afItem.alarmAttributeName === crlinc.alarmAttributeName &&
          afItem.assetIdentifier === crlinc.assetIdentifier &&
          afItem.datatype === crlinc.datatype
        );
      });

      if (!isNullOrUndefined(alarmLimit)) {
        crlinc.synced = true;
      }
    });

    this.loading = false;
  }

  migrateAlarmLimits() {
    let observables = [];
    this._confirmationService.confirm({
      message:
        'Are you sure you want to migrate the AF Notification Limits to CR LINC?',
      accept: () => {
        this.saving = true;
        this.missingAlarmLimits.forEach((syncLimit) => {
          let newAlarmLimit = {
            facilityId: syncLimit.facilityUid,
            assetIdentifier: syncLimit.assetIdentifier,
            alarmAttributeName: syncLimit.alarmAttributeName,
            value: 'NULL',
            effectiveDateTime: new Date(),
            updatedAt: new Date(),
            updateReason: 'Migrated From AF',
            datatype: syncLimit.datatype,
          };

          observables.push(
            this._opMonitoringApi.addAlarmLimit(newAlarmLimit).pipe(
              catchError((error) => {
                console.error(error);
                return of(undefined);
              })
            )
          );
        });

        forkJoin(observables)
          .pipe(take(1))
          .subscribe((responses: any) => {
            let errors = 0;
            responses.forEach((response) => {
              if (isNullOrUndefined(response)) {
                ++errors;
              }
            });

            if (errors > 0) {
              this._logAndMessage.translateToErrorMessage({
                bodyKey: 'OPMONITORING.MESSAGES.ERROR.MIGRATE_ALARM_LIMITS',
                headerKey: 'COMMON.MESSAGES.HEADERS.ERROR',
                bodyParams: {
                  errors: errors,
                },
              });
            } else {
              this._logAndMessage.translateToSuccessMessage({
                bodyKey: 'OPMONITORING.MESSAGES.SUCCESS.MIGRATE_ALARM_LIMITS',
                headerKey: 'COMMON.MESSAGES.HEADERS.SUCCESS',
              });
            }
            this.saving = false;
            this.syncComplete = false;
            this.loadSyncData();
          });
      },
    });
  }

  ngOnDestroy(): void {}
}

import { gql } from '@apollo/client/core';

export const editPart = gql`
  mutation editPart($id: String, $input: CreateOrEditPart) {
    editPart(id: $id, input: $input) {
      id
      sapNumber
      pciNumber
      description
      vendorPartNumber
      manufacturer
      storeroom
      binLocation
      createdBy
      createdAt
      updater
      updatedAt
      deletedAt
    }
  }
`;

<div id="sync-alarm-limits-container">

  <p-blockUI [blocked]="saving || loading"></p-blockUI>
  <app-progress-spinner [enabled]="saving || loading"></app-progress-spinner>

  <div class="content">
    <div class="button-wrapper content-header">
      @if (syncComplete) {
        <button pButton class="width-eighty" label="{{'OPMONITORING.LABEL.BUTTONS.MIGRATE' | translate}}"
        (click)="migrateAlarmLimits()" icon="fa fa-fw fa-clone"></button>
      }
      <button pButton class="width-eighty" label="{{'OPMONITORING.LABEL.BUTTONS.SYNC' | translate}}"
      (click)="syncElements()" icon="fa fa-fw fa-arrows-h"></button>
    </div>

    <div class="row center-align">
      <div class="col crlinc-table">
        <label class="table-label">{{'OPMONITORING.LABEL.CRLINC' | translate
        }}</label>
        <div class="row label-row">
          <div class="col">
            <label class="column-label">{{'LOCATION.LABEL.FACILITY_UID' | translate }}</label>
          </div>
          <div class="col">
            <label class="column-label">{{'OPMONITORING.LABEL.ASSET_IDENTIFIER' | translate }}</label>
          </div>
          <div class="col">
            <label class="column-label">{{'OPMONITORING.LABEL.LIMIT_ATTRIBUTE_NAME' | translate}}</label>
          </div>
          <div class="col">
            <label class="column-label">{{'OPMONITORING.LABEL.DATATYPE' | translate}}</label>
          </div>
        </div>
        <div class="flex-auto">
          <p-scrollPanel [style]="{height: 'calc(100vh - 325px)', width:'auto'}">
            @for (crlincItem of crlincItemsArray; track crlincItem; let j = $index) {
              <div class="row">
                <div class="col" [ngClass]="!crlincItem.synced ? 'warn' : ''">
                  {{crlincItem.Facility.facilityUid}}
                </div>
                <div class="col" [ngClass]="!crlincItem.synced ? 'warn' : ''">{{crlincItem.assetIdentifier}}
                </div>
                <div class="col" [ngClass]="!crlincItem.synced ? 'warn' : ''">
                  {{crlincItem.alarmAttributeName}}
                </div>
                <div class="col" [ngClass]="!crlincItem.synced ? 'warn' : ''">{{crlincItem.datatype}}
                </div>
              </div>
            }
          </p-scrollPanel>
        </div>
      </div>
      <div class="col af-table">
        <label class="table-label">{{'OPMONITORING.LABEL.AF' | translate
        }}</label>
        <div class="row label-row">
          <div class="col">
            <label class="column-label">{{'LOCATION.LABEL.FACILITY_UID' | translate }}</label>
          </div>
          <div class="col">
            <label class="column-label">{{'OPMONITORING.LABEL.ASSET_IDENTIFIER' | translate }}</label>
          </div>
          <div class="col">
            <label class="column-label">{{'OPMONITORING.LABEL.LIMIT_ATTRIBUTE_NAME' | translate}}</label>
          </div>
          <div class="col">
            <label class="column-label">{{'OPMONITORING.LABEL.DATATYPE' | translate}}</label>
          </div>
        </div>
        <div class="flex-auto">
          <p-scrollPanel [style]="{height: 'calc(100vh - 325px)', width:'auto'}">
            @for (afItem of afItemsArray; track afItem; let j = $index) {
              <div class="row">
                <div class="col" [ngClass]="!afItem.synced ? 'warn': ''">{{afItem.facilityUid}}</div>
                <div class="col" [ngClass]="!afItem.synced ? 'warn': ''">{{afItem.assetIdentifier}}</div>
                <div class="col" [ngClass]="!afItem.synced ? 'warn': ''">{{afItem.alarmAttributeName}}</div>
                <div class="col" [ngClass]="!afItem.synced ? 'warn': ''">{{afItem.datatype}}</div>
              </div>
            }
          </p-scrollPanel>
        </div>
      </div>
    </div>

  </div>

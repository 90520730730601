import { Component, inject, signal, WritableSignal } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ok } from 'assert';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { Dropdown, DropdownChangeEvent } from 'primeng/dropdown';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { firstValueFrom, forkJoin } from 'rxjs';
import { CLAIMS } from 'src/app/constants/auth-constants';
import { CoreModule } from 'src/app/core/core.module';
import { PlcAlarmCode } from 'src/app/model/station-logs/plc-alarm-code';
import { AuthApiService } from 'src/app/services/auth-api.service';
import { LogAndMessageService } from 'src/app/services/log-and-message.service';
import { StationLogApiService } from 'src/app/services/station-log-api.service';
import { StationLogBuilderService } from 'src/app/services/station-log-builder.service';

@Component({
  selector: 'app-troubleshooting-dialog',
  imports: [ReactiveFormsModule, CoreModule, TranslateModule],
  providers: [DialogService],
  templateUrl: './troubleshooting-dialog.component.html',
  styleUrl: './troubleshooting-dialog.component.scss'
})
export class TroubleshootingDialogComponent {
  config = inject(DynamicDialogConfig);
  ref = inject(DynamicDialogRef);
  fb = inject(FormBuilder);
  authApi = inject(AuthApiService);
  stationLogBuilder = inject(StationLogBuilderService);
  stationLogApi = inject(StationLogApiService);
  logAndMessage = inject(LogAndMessageService);
  confirmationService = inject(ConfirmationService);
  translateService = inject(TranslateService);

  alarmCode: WritableSignal<PlcAlarmCode> = signal(null);
  canCreate = signal(false);
  canEdit = signal(false);
  formType: 'Create' | 'Edit'
  form: FormGroup;
  formWorking = signal(false);
  actions: SelectItem[] = [];

  ngOnInit(): void {
    this.form = this.fb.group({
      alarmCode: [null, Validators.required],
      alarmComment: [null],
      alarmExplanation: [null],
      alarmFix: [null]
    });

    this.canCreate.set(this.authApi.doesUserHaveClaim(CLAIMS.FIELD_OPERATIONS.PLC_ALARM_CODES.CREATE_PLC_ALARM_CODES));
    this.canEdit.set(this.authApi.doesUserHaveClaim(CLAIMS.FIELD_OPERATIONS.PLC_ALARM_CODES.EDIT_PLC_ALARM_CODES));
    if (this.authApi.doesUserHaveClaim(CLAIMS.FIELD_OPERATIONS.PLC_ALARM_CODES.DELETE_PLC_ALARM_CODES)) {
      this.actions.push({ label: 'Delete', value: 'delete' });
    }

    this.alarmCode.set(this.config?.data?.alarmCode);
    this.formType = this.config.data.formType;

    this.updateForm();
  }

  updateForm(): void {
    const currentAlarmCode = this.alarmCode();
    this.form.patchValue({
      alarmCode: currentAlarmCode?.alarmCode,
      alarmComment: currentAlarmCode?.alarmComment,
      alarmExplanation: currentAlarmCode?.alarmExplanation,
      alarmFix: currentAlarmCode?.alarmFix,
    });
    this.form.markAsPristine();

    if (!this.canEdit()) {
      this.form.disable();
    }
  }

  save() {
    if (this.formType === 'Create') {
      this.createAlarmCode();
    } else if (this.formType === 'Edit') {
      this.editAlarmCode();
    } else {
      this.logAndMessage.translateToErrorAlert({
        headerKey: 'COMMON.MESSAGES.HEADERS.ERROR',
        bodyKey: 'OPMONITORING.MESSAGES.ERROR.INVALID_SAVE_ACTION',
      })
    }
  }

  createAlarmCode(): void {
    this.formWorking.set(true);
    const createInput = this.stationLogBuilder.buildCreateOrEditPlcAlarmCode(
      this.form.value
    );
    this.stationLogApi.createPlcAlarmCode(createInput).subscribe({
      next: (newAlarmCode) => {
        this.formWorking.set(false);
        this.logAndMessage.translateToSuccessMessage({
          headerKey: 'COMMON.MESSAGES.HEADERS.SUCCESS',
          bodyKey: 'OPMONITORING.MESSAGES.SUCCESS.CREATE_PLC_ALARM_CODE',
        });
        this.ref.close(newAlarmCode);
      },
      error: (error) => {
        this.formWorking.set(false);
        this.logAndMessage.errorLogOnly(error);
        this.logAndMessage.translateToErrorAlert({
          headerKey: 'COMMON.MESSAGES.HEADERS.ERROR',
          bodyKey: 'OPMONITORING.MESSAGES.ERROR.CREATE_PLC_ALARM',
        });
      },
    });
  }

  editAlarmCode() {
    this.formWorking.set(true);
    const editInput = this.stationLogBuilder.buildCreateOrEditPlcAlarmCode(
      this.form.value
    );
    this.stationLogApi.editPlcAlarmCode(this.alarmCode().id, editInput).subscribe({
      next: (editedAlarmCode) => {
        this.formWorking.set(false);
        this.logAndMessage.translateToSuccessMessage({
          headerKey: 'COMMON.MESSAGES.HEADERS.SUCCESS',
          bodyKey: 'OPMONITORING.MESSAGES.SUCCESS.CREATE_PLC_ALARM_CODE',
        });
        this.ref.close(editedAlarmCode);
      },
      error: (error) => {
        this.formWorking.set(false);
        this.logAndMessage.errorLogOnly(error);
        this.logAndMessage.translateToErrorAlert({
          headerKey: 'COMMON.MESSAGES.HEADERS.ERROR',
          bodyKey: 'OPMONITORING.MESSAGES.ERROR.EDIT_PLC_ALARM_CODE',
        });
      },
    });
  }

  async deleteAlarmCode() {
    const deleteHeader$ = this.translateService.get(
      'OPMONITORING.MESSAGES.HEADERS.DELETE_PLC_ALARM_CODE'
    );
    const ok$ = this.translateService.get('COMMON.LABEL.BUTTONS.YES');
    const cancel$ = this.translateService.get('COMMON.LABEL.BUTTONS.NO');
    const deleteMsg$ = this.translateService.get(
      'OPMONITORING.MESSAGES.CONFIRMATION.DELETE_PLC_ALARM_CODE'
    );
    const labels = await firstValueFrom(
      forkJoin([deleteHeader$, ok$, cancel$, deleteMsg$])
    );
    this.confirmationService.confirm({
      header: labels[0],
      icon: 'fa fa-question-circle',
      acceptVisible: true,
      rejectVisible: true,
      acceptLabel: labels[1],
      rejectLabel: labels[2],
      message: labels[3],
      accept: () => {
        this.formWorking.set(true);
        this.stationLogApi.deletePlcAlarmCode(this.alarmCode().id).subscribe({
          next: () => {
            this.formWorking.set(false);
            this.logAndMessage.translateToSuccessMessage({
              headerKey: 'COMMON.MESSAGES.HEADERS.SUCCESS',
              bodyKey: 'OPMONITORING.MESSAGES.SUCCESS.DELETE_PLC_ALARM_CODE'
            });
            // Need to send back some data with the close so the table refreshes
            this.ref.close(this.alarmCode());
          },
          error: (error) => {
            this.formWorking.set(false);
            this.logAndMessage.errorLogOnly(error);
            this.logAndMessage.translateToErrorAlert({
              headerKey: 'COMMON.MESSAGES.HEADERS.ERROR',
              bodyKey: 'OPMONITORING.MESSAGES.ERROR.DELETE_PLC_ALARM_CODE'
            });
          },
        });
      },
    });
  }

  actionEvent($event: DropdownChangeEvent, dd: Dropdown) {
    if ($event.value) {
      const selectedAction = $event.value;
      if (selectedAction === 'delete') {
        this.deleteAlarmCode();
      }

      dd.clear()
    }
  }
}

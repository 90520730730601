<p-dialog
  header="Notification Limit Information"
  [modal]="true"
  [(visible)]="isVisible"
  (onHide)="onDialogHide($event)"
  [dismissableMask]="true"
  >
  <app-progress-spinner [enabled]="saving || loading"></app-progress-spinner>
  @if (!saving && !loading) {
    <div class="container">
      <form [formGroup]="alarmLimitForm">
        <div id="alarm-limit-form">
          @if (formType() === 'Edit') {
            <div class="actions-dropdown">
              <label class="label" [style]="{ 'text-align': 'center' }"
                >{{ "COMMON.LABEL.ACTIONS" | translate }}:</label
                >
                <span class="no-padding-top-bottom">
                  <p-dropdown
                    [options]="availableActions"
                    (onChange)="onActionSelected($event)"
                    [(ngModel)]="selectedAction"
                    [ngModelOptions]="{ standalone: true }"
                    appendTo="body"
                    scrollHeight="calc(100vh-35vh)"
                    styleClass="action-drop-down"
                  ></p-dropdown>
                </span>
              </div>
            }
            <div>
              <label>{{ "LOCATION.LABEL.FACILITY_NAME" | translate }}:</label>
              <div>
                <p-dropdown
                  [options]="facilities"
                  formControlName="facility"
                  styleClass="w-100"
                  optionLabel="facilityName"
                  placeholder="Select a Facility"
                  [virtualScroll]="true"
                  [filter]="true"
                  [virtualScrollItemSize]="30"
                  (onChange)="onFacilityChanged($event)"
                ></p-dropdown>
              </div>
            </div>
            <div>
              <label
                >{{ "OPMONITORING.LABEL.ASSET_IDENTIFIER" | translate }}:</label
                >
                <div>
                  <input
                    class="dialog-input"
                    type="text"
                    pInputText
                    formControlName="assetIdentifier"
                    />
                  </div>
                </div>
                <div>
                  <label
                    >{{ "OPMONITORING.LABEL.LIMIT_ATTRIBUTE_NAME" | translate }}:</label
                    >
                    <div>
                      <input
                        class="dialog-input"
                        type="text"
                        pInputText
                        formControlName="alarmAttributeName"
                        />
                      </div>
                    </div>
                    <div>
                      <label>{{ "OPMONITORING.LABEL.DATATYPE" | translate }}:</label>
                      <div>
                        <p-dropdown
                          [options]="datatypes"
                          formControlName="datatype"
                          placeholder="Select a Data Type"
                          styleClass="w-100"
                        ></p-dropdown>
                      </div>
                    </div>
                    <div>
                      <label>{{ "OPMONITORING.LABEL.LIMIT_VALUE" | translate }}:</label>
                      <div>
                        <input
                          class="dialog-input"
                          type="text"
                          pInputText
                          formControlName="limitValue"
                          />
                        </div>
                      </div>
                      <div>
                        <label
                          >{{ "OPMONITORING.LABEL.EFFECTIVE_DATE_TIME" | translate }}:</label
                          >
                          <p-calendar
                            formControlName="effectiveDateTime"
                            [showTime]="true"
                            hourFormat="24"
                            [minDate]="minDateTime"
                          ></p-calendar>
                        </div>
                        <div>
                          <label>{{ "COMMON.LABEL.UPDATER" | translate }}:</label>
                          <div>
                            <input
                              class="dialog-input"
                              type="text"
                              pInputText
                              formControlName="updater"
                              />
                            </div>
                          </div>
                          <div>
                            <label>{{ "OPMONITORING.LABEL.UPDATED_AT" | translate }}:</label>
                            <div>
                              <input
                                class="dialog-input"
                                type="text"
                                pInputText
                                formControlName="updatedAt"
                                />
                              </div>
                            </div>
                            <div>
                              <label>{{ "OPMONITORING.LABEL.UPDATE_REASON" | translate }}:</label>
                              <div>
                                <input
                                  class="dialog-input"
                                  type="text"
                                  pInputText
                                  formControlName="updateReason"
                                  />
                                </div>
                              </div>
                              <div class="button">
                                <button
                                  pButton
                                  pRipple
                                  label="Save"
                                  class="p-button-raised p-button-success right-align"
                                  [disabled]="!alarmLimitForm.valid"
                                  (click)="onSubmit($event)"
                                  type="button"
                                ></button>
                              </div>
                            </div>
                          </form>
                        </div>
                      }
                    </p-dialog>

                    <app-alarm-limit-audit-trail
                      [isVisible]="auditTrailVisible"
                      (dialogHidden)="onAuditTrailHidden()"
                      [selectedAlarmLimit]="selectedAlarmLimit()"
                      #alarmLimitAuditTrail
                    ></app-alarm-limit-audit-trail>

@if (!formWorking()){
<form [formGroup]="form">
  @if (actions.length > 0) {
  <p-dropdown
    #dd
    [options]="actions"
    (onChange)="actionEvent($event, dd)"
    styleclass="action-drop-down"
    placeholder="Actions"
  ></p-dropdown>
  }

  <div class="form-item">
    <label for="sapNumber"
      >{{ "OPMONITORING.LABEL.SAP_NUMBER" | translate }}:
    </label>
    <input type="text" pInputText formControlName="sapNumber" />
  </div>

  <div class="form-item">
    <label for="sapNumber"
      >{{ "OPMONITORING.LABEL.PCI_NUMBER" | translate }}:
    </label>
    <input type="text" pInputText formControlName="pciNumber" />
  </div>

  <div class="form-item">
    <label for="sapNumber"
      >{{ "OPMONITORING.LABEL.DESCRIPTION" | translate }}:
    </label>
    <input type="text" pInputText formControlName="description" />
  </div>

  <div class="form-item">
    <label for="sapNumber"
      >{{ "OPMONITORING.LABEL.VENDOR_PART_NUMBER" | translate }}:
    </label>
    <input type="text" pInputText formControlName="vendorPartNumber" />
  </div>

  <div class="form-item">
    <label for="sapNumber"
      >{{ "OPMONITORING.LABEL.MANUFACTURER" | translate }}:
    </label>
    <input type="text" pInputText formControlName="manufacturer" />
  </div>

  <div class="form-item">
    <label for="sapNumber"
      >{{ "OPMONITORING.LABEL.STOREROOM" | translate }}:
    </label>
    <input type="text" pInputText formControlName="storeroom" />
  </div>

  <div class="form-item">
    <label for="sapNumber"
      >{{ "OPMONITORING.LABEL.BIN_LOCATION" | translate }}:
    </label>
    <input type="text" pInputText formControlName="binLocation" />
  </div>

  @if ((canCreate() && formType === 'Create') || (canEdit() && formType ===
  'Edit')) {
  <button
    pButton
    label="{{ 'COMMON.LABEL.BUTTONS.SAVE' | translate }}"
    icon="fa fa-fw fa-save"
    (click)="save()"
    [disabled]="!form.valid || !form.dirty"
  ></button>
  }
</form>
}
<app-progress-spinner [enabled]="formWorking()"></app-progress-spinner>
